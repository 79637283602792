import React from "react";
import { setProduct } from "artisn/shopping-cart";
import { CartProduct } from "artisn/types";

import { AddToCartNotificationStyled } from "./AddToCartNotification.styles";
import { AddToCartNotificationModifiable } from "./AddToCartNotification.styles";
import { AddToCartNotificationProps as Props } from "./AddToCartNotification.types";
import Button from "components/global/Button/Button";
import { defaultFunction } from "utils/common.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";
import ShoppingCartProduct from "components/Cart/ShoppingCartProduct/ShoppingCartProduct";
import useAuth from "contexts/auth/auth.context.hooks";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import CartPayButton from "components/Cart/CartPayButton/CartPayButton";
import Divider from "components/global/Divider/Divider";
import CONSTANTS from "config/constants";
import useStores from "contexts/stores/stores.context.hooks";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;

const AddToCartNotification: React.FC<Props> = props => {
  const { className, product, showDrawer = defaultFunction } = props;
  const { amount = 1, modifiable } = props;
  const { isAnonymous = false, uid } = useAuth();
  const { shoppingCart } = useShoppingCart();
  const { selectedStore } = useStores();
  const { name } = product ?? {};
  const { name: shoppingCartName } = shoppingCart ?? {};
  const showDrawerHandler = () => {
    showDrawer();
    dismissAddToCartNotification();
  };

  if (modifiable) {
    return (
      <AddToCartNotificationModifiable
        className={`AddToCartNotification ${className}`}
        showCloseIcon={true}
        onClose={dismissAddToCartNotification}
      >
        <p className="AddToCartNotification__description">Has agregado</p>
        {product ? (
          <ShoppingCartProduct
            product={product as CartProduct}
            onChangeQuantity={(value: number) =>
              setProduct(product, {
                amount: value,
                shoppingCartName,
                anonymous: isAnonymous,
                accountId: ACCOUNT_ID,
                customerId: uid!,
                store: selectedStore!
              })
            }
            initialQuantity={amount}
            showCounter={false}
          />
        ) : null}
        <Divider className="AddToCartNotification__divider" />
        <div className="AddToCartNotification__buttons">
          <Button
            className="AddToCartNotification__button"
            type="FILLED"
            onClick={showDrawerHandler}
          >
            Ver carrito
          </Button>
          <CartPayButton shoppingCart={shoppingCart} />
        </div>
      </AddToCartNotificationModifiable>
    );
  }

  return (
    <AddToCartNotificationStyled
      className={`AddToCartNotification ${className}`}
      showCloseIcon={true}
      onClose={dismissAddToCartNotification}
    >
      <p className="AddToCartNotification__description">Has agregado</p>
      {product ? (
        <ShoppingCartProduct
          product={product as CartProduct}
          onChangeQuantity={(value: number) =>
            setProduct(product, {
              amount: value,
              shoppingCartName,
              anonymous: isAnonymous,
              accountId: ACCOUNT_ID,
              customerId: uid!,
              store: selectedStore!
            })
          }
          initialQuantity={amount}
        />
      ) : null}
      <p className="AddToCartNotification__description">Has agregado: {name}</p>
      <Button
        className="AddToCartNotification__button"
        type="LINK"
        onClick={showDrawerHandler}
      >
        Ver carrito
      </Button>
    </AddToCartNotificationStyled>
  );
};

AddToCartNotification.defaultProps = {};

export default AddToCartNotification;
