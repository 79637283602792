import { useMutation, useQueryClient } from "react-query";

import { postTalkShop } from "./talkShop.service";
import useAuth from "contexts/auth/auth.context.hooks";
import { TalkShop, TalkShopPayload } from "./talkShop.service.types";

/** Hook to add message information.
 *
 * @returns Returns a use mutation result to add message information
 */
export const usePostTalkShop = () => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();

  return useMutation<TalkShop, Error, TalkShopPayload>(postTalkShop, {
    // When mutate is called:
    onMutate: async () => {
      await queryClient.cancelQueries([uid, "talkShop"]);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([uid, "user"]);
    }
  });
};
