import React, { useMemo } from "react";
import { passwordStrength } from "check-password-strength";

import Styles from "./PasswordStrengthLines.styles";
import { PasswordStrengthLinesProps as Props } from "./PasswordStrengthLines.types";
import { Strength } from "../PasswordStrength.types";
import { mapStrengthToClassName } from "./PasswordStrengthLines.helpers";
import { allowedSymbols, defaultOptions } from "../PasswordStrength.helpers";

const PasswordStrengthLines: React.FC<Props> = props => {
  const { className, password } = props;
  const strengthClassName = useMemo(() => {
    if (typeof password === "undefined" || password?.trim() === "") return;
    const { value } = passwordStrength<Strength>(
      password,
      defaultOptions,
      allowedSymbols
    );
    return mapStrengthToClassName(value);
  }, [password]);

  const noWeak = strengthClassName !== "weak";
  const noMedium = strengthClassName !== "medium";
  const strong = noWeak && noMedium;
  const section1Class = `PasswordStrengthLines--${strengthClassName}`;
  const section2Class = noWeak
    ? `PasswordStrengthLines--${strengthClassName}`
    : "";
  const section3Class = strong
    ? `PasswordStrengthLines--${strengthClassName}`
    : "";

  if (!password) return null;

  return (
    <Styles className={`PasswordStrengthLines ${className}`}>
      <div className={`PasswordStrengthLines__check ${section1Class}`} />
      <div className={`PasswordStrengthLines__check ${section2Class}`} />
      <div className={`PasswordStrengthLines__check ${section3Class}`} />
    </Styles>
  );
};

PasswordStrengthLines.defaultProps = {
  className: ""
};

export default PasswordStrengthLines;
