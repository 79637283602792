import styled from "styled-components";

import { ProductBasePriceInfoStyledProps as Props } from "./ProductBasePriceInfo.types";

const ProductBasePriceInfoStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  line-height: 2.8rem;
  font-size: 2.4rem;

  .ProductBasePriceInfo {
    &--normal {
      color: var(--palette-primary);
    }

    &--points {
      margin-left: 0.8rem;
      color: var(--palette-black-s0-l20);
    }
  }
`;

export default ProductBasePriceInfoStyled;
