// useShoppingCart helper functions
import { ShoppingCart, Store } from "artisn/types";

export const cleanShoppingCartAlerts = (cart: ShoppingCart): ShoppingCart => {
  if (!cart) return cart;
  const purgedCart = structuredClone(cart);
  const stores = purgedCart.stores;
  for (const storeKey in stores) {
    const store = stores[storeKey];
    store.alerts = [];
    const products = store.products;
    for (const productKey in products) {
      const product = products[productKey];
      product.alerts = [];
    }
    store.products = products;
  }
  purgedCart.stores = stores;
  return purgedCart;
};

export const getShoppingCartProductByHash = (
  shoppingCart: ShoppingCart | undefined,
  hash: string | undefined,
  storeId: Store["storeId"]
) => {
  if (!hash) return;
  const storeProducts = shoppingCart?.stores[storeId]?.products;
  if (!storeProducts) return;
  return storeProducts[hash];
};
