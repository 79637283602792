import { useMemo } from "react";
import { useQuery, useInfiniteQuery, useQueryClient } from "react-query";
import { ProductDetails } from "artisn/types";
import { BaseProduct, CategoryWithProducts } from "artisn/types";

import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { NewPaginatedResponse } from "types/pagination.types";
import { UseFetchProductsPayload } from "./products.service.types";
import { FetchProductDetailsPayload } from "./products.service.types";
import { fetchProductDetails } from "services/products/products.service";
import { fetchProducts, fetchRecommendedProducts } from "./products.service";
import { fetchCategoriesWithProducts } from "services/products/products.service";
import { UseFetchCategoriesWithProductsPayload } from "./products.service.types";
import { notify } from "utils/common.utils";

const { CATEGORIES_PER_REQUEST, PRODUCTS_PER_CATEGORY_REQUEST } = CONSTANTS.API;

export const useFetchProducts = (
  config: UseFetchProductsPayload,
  enabled?: boolean,
  keepPreviousData = true
) => {
  const { page, ...keys } = config;

  return useInfiniteQuery(
    ["products", keys],
    ({ pageParam }) => {
      return fetchProducts({
        ...config,
        page: pageParam ?? 1
      });
    },
    {
      enabled: !!config.storeId && (enabled ?? true),
      getNextPageParam: (params: NewPaginatedResponse<BaseProduct>) => {
        const { page, hasMorePages } = params;
        const next = page + 1;
        return !hasMorePages ? undefined : next;
      },
      keepPreviousData,
      onError: (e: Error) => {
        notify(e, "Get products request");
      }
    }
  );
};

export const useFetchProductDetails = (
  productId: string | undefined,
  config: Omit<FetchProductDetailsPayload, "productId">
) => {
  const { vendorId, catalogueId, storeId, categoryId } = config;
  const queryClient = useQueryClient();

  const placeholderData = useMemo(() => {
    const productsInCategories: Record<string, any> | undefined =
      queryClient.getQueryData([
        "products",
        {
          vendorId: vendorId,
          catalogueId: catalogueId,
          storeId: storeId,
          categoryId: categoryId,
          productsByGroup: PRODUCTS_PER_CATEGORY_REQUEST,
          size: CATEGORIES_PER_REQUEST,
          groupBy: "category"
        }
      ]);

    const sortedProducts: Record<string, any> | undefined =
      queryClient.getQueryData(
        [
          "products",
          {
            vendorId: vendorId,
            catalogueId: catalogueId,
            storeId: storeId,
            categoryId: categoryId,
            method: "desc",
            type: "price"
          }
        ],
        {}
      );

    const allPages = [...(productsInCategories?.pages ?? [])];

    const allCategories: CategoryWithProducts[] | undefined = allPages.flatMap(
      (page: any) => page.data ?? []
    );

    const allCategoryProducts: BaseProduct[] | undefined =
      allCategories?.flatMap(category => category.products);

    const productFromPages = (sortedProducts?.pages ?? []).flatMap(
      (page: any) => page.data
    );

    const allProducts: BaseProduct[] | undefined = [
      ...allCategoryProducts,
      ...productFromPages
    ];

    const baseProduct = allProducts?.find(
      product => +product.productId === +(productId ?? "")
    );

    return baseProduct ? (baseProduct as ProductDetails) : undefined;
  }, [catalogueId, categoryId, productId, queryClient, storeId, vendorId]);

  return useQuery(
    ["products", productId, config],
    () => fetchProductDetails({ ...config, productId: productId! }),
    {
      enabled: typeof storeId !== "undefined" && !!productId,
      placeholderData,
      onError: (e: Error) => {
        notify(e, "Get product detail request");
      }
    }
  );
};

export const useFetchRecommendedProducts = () => {
  const { uid } = useAuth();

  return useQuery(
    ["products", "recommended"],
    () => fetchRecommendedProducts(),
    {
      enabled: !!uid,
      onError: (e: Error) => {
        notify(e, "Get recommended products request");
      }
    }
  );
};

export const useFetchCategoriesWithProducts = (
  config: UseFetchCategoriesWithProductsPayload,
  enabled?: boolean,
  keepPreviousData = true
) => {
  const { page, ...keys } = config;

  return useInfiniteQuery<NewPaginatedResponse<CategoryWithProducts>, Error>(
    ["categories", "products", keys],
    infiniteQueryParams => {
      const { pageParam } = infiniteQueryParams;

      return fetchCategoriesWithProducts({
        ...config,
        page: pageParam ?? 1
      });
    },
    {
      enabled: !!config.storeId && (enabled ?? true),
      getNextPageParam: (
        params: NewPaginatedResponse<CategoryWithProducts>
      ) => {
        const { page, hasMorePages } = params;
        const next = page + 1;
        return !hasMorePages ? undefined : next;
      },
      keepPreviousData,
      onError: (e: Error) => {
        notify(e, "Get categories with products request");
      }
    }
  );
};
