// StoreDetailsPickup helper functions and data

export const chooseMapProvider = (
  provider: "google" | "waze",
  latitude: number | undefined,
  longitude: number | undefined
) => {
  if (typeof latitude === "undefined" || typeof longitude === "undefined") {
    return;
  }
  if (latitude === 0 && longitude === 0) return;

  const googleMaps = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  const waze = `https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;

  switch (provider) {
    case "google":
      return googleMaps;
    case "waze":
      return waze;
    default:
      return googleMaps;
  }
};
