// ProductBasePriceInfo helper functions and data

import { Country } from "types/country.types";

export const getFormatPointsOption = (country: Country) => {
  const { currency, locale } = country;
  return {
    currency,
    locale,
    symbol: "pts",
    pointsSymbol: "pts"
  };
};
