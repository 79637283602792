import styled from "styled-components";
import { Modal } from "artisn-ui-react";

import { ModalStyledProps as Props } from "./Modal.types";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

const ModalStyled = styled(Modal)<Props>`
  width: min(${getMaxWidth()}, 60rem);
  height: min(${getMaxHeight()}, 100rem);

  @media all and (hover: none) {
    width: ${getMaxWidth()};
    height: ${getMaxHeight()};
  }

  .Modal {
  }
`;

export default ModalStyled;
