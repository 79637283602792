import React, { createContext, useMemo, useState } from "react";
import { useCallback } from "react";
import { ContextDevTool } from "react-context-devtool";
import { Catalogue } from "artisn/types";

import CONSTANTS from "config/constants";
import { CataloguesProviderValue } from "./catalogues.context.types";
import { CataloguesProviderProps as Props } from "./catalogues.context.types";

// @ts-ignore
export const CataloguesContext = createContext<CataloguesProviderValue>({});

const { DEFAULT_CATALOGUE, CATALOGUES } = CONSTANTS.ARTISN;

const CataloguesProvider: React.FC<Props> = props => {
  const [selectedCatalogue, setSelectedCatalogue] =
    useState<Catalogue>(DEFAULT_CATALOGUE);
  const [selectedCatalogueId, setSelectedCatalogueId] = useState(
    selectedCatalogue.catalogueId
  );
  const [changedCatalogue, setChangedCatalogue] = useState(false);

  const resetCataloguesContext = useCallback(() => {
    setSelectedCatalogue(DEFAULT_CATALOGUE);
    setSelectedCatalogueId(DEFAULT_CATALOGUE.catalogueId);
  }, []);

  const setCatalogueIdHandler = (catalogueId: Catalogue["catalogueId"]) => {
    const catalogue = CATALOGUES.find(item => item.catalogueId === catalogueId);

    if (!catalogue) {
      throw new Error(
        `"${catalogueId}" does not correspond to a valid catalogue ID`
      );
    }

    setSelectedCatalogue(catalogue);
    setSelectedCatalogueId(catalogueId);
  };

  const setSelectedCatalogueHandler = (catalogue: Catalogue) => {
    setSelectedCatalogue(catalogue);
    setSelectedCatalogueId(catalogue.catalogueId);
  };

  const value: CataloguesProviderValue = useMemo(() => {
    return {
      selectedCatalogueId,
      setSelectedCatalogueId: setCatalogueIdHandler,
      selectedCatalogue,
      setSelectedCatalogue: setSelectedCatalogueHandler,
      changedCatalogue,
      setChangedCatalogue,
      resetCataloguesContext
    };
  }, [
    selectedCatalogueId,
    selectedCatalogue,
    changedCatalogue,
    resetCataloguesContext
  ]);

  return (
    <CataloguesContext.Provider value={value}>
      <ContextDevTool
        context={CataloguesContext}
        id="catalogues"
        displayName="Catalogues"
      />
      {props.children}
    </CataloguesContext.Provider>
  );
};

export default CataloguesProvider;
