import styled from "styled-components";

import CONSTANTS from "../../../../config/constants";
import { SignInFormStyledProps as Props } from "./SignInForm.types";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const SignInFormStyled = styled.div<Props>`
  margin-bottom: 3.2rem;
  padding-top: 1.6rem;
  border-top: 0.1rem solid var(--palette-gray-s0-l85);

  @media (max-width: ${tablet}px) {
    padding: 0;
    padding-top: 2.4rem;
  }

  .SignInForm {
    &__email-input {
      padding-bottom: 1.4rem;
    }

    &__password-input {
      background-color: transparent;

      .PasswordInput {
        &__container {
          border-radius: 10rem;
          border: none;
        }

        &__input {
        }

        &__icon {
          z-index: 2;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.4rem;

      .Button__wrapper {
        font-size: 1.6rem;
      }

      @media (max-width: ${mobile - 1}px) {
        flex-direction: column;
        margin-top: 2.4rem;
      }
    }

    &__forgot {
      padding: 0rem;
      font-weight: 600;

      @media (max-width: ${mobile - 1}px) {
        padding-left: 2.4rem;
        margin-bottom: 1.6rem;
      }
    }

    &__submit {
      width: 16.4rem;
      height: 4.8rem;
      font-weight: 600;
      border-radius: 10rem;
      background-color: var(--palette-primary);
    }

    &__password-strength {
      padding-top: 2.4rem;
    }
  }

  .Pocket__content {
    padding-top: 2.2rem;
  }

  .Pocket__header__title {
    font-weight: 600;
    font-size: 1.6rem;
  }
`;

export default SignInFormStyled;
