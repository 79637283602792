import styled from "styled-components";

import { ChooseStoreStyledProps as Props } from "./ChooseStore.types";

const ChooseStoreStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ChooseStore {
    &__options {
      background-color: var(--palette-white);
      padding: 1.6rem 2.4rem;
    }

    &__current-position {
      &.InfoCard {
        padding: 0.8rem 0;
      }

      .InfoCard {
        &__icon {
          svg path {
            fill: var(--palette-primary);
          }
        }

        &__content {
          display: flex;
          flex-direction: column-reverse;
          cursor: pointer;

          &__value {
            font-weight: 800;
            padding: 0;
            color: var(--palette-primary);
          }
        }
      }
    }

    &__map-search {
      padding: 1.6rem 2.4rem;
      background-color: var(--palette-secondary-s35-l95);
      border-radius: 12rem;
      margin-top: 1.2rem;

      svg path {
        fill: var(--palette-primary);
      }

      span {
        font-size: 1.6rem;
        font-weight: 800;
      }
    }

    &__reverse-geocode-error {
      color: var(--palette-red-s60-l60);
      font-size: 1.2rem;
      padding-top: 0.8rem;
    }
  }
`;

export default ChooseStoreStyled;
