import React, { useCallback, useEffect, useMemo } from "react";
import { useRef } from "react";
import { useRouter } from "next/router";
import { removeBenefit, removeProduct } from "artisn/shopping-cart";
import { setProduct, getShoppingCartProducts } from "artisn/shopping-cart";
import { CartProduct } from "artisn/types";
import { events } from "@artisan-commerce/analytics-capacitor";

import Styles from "./CartProducts.styles";
import { CartProductsProps as Props } from "./CartProducts.types";
import ShoppingCartProductPlaceholder from "../ShoppingCartProduct/ShoppingCartProduct.placeholder";
import EmptyCart from "../EmptyCart/EmptyCart";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import { notify, sortByProductDate } from "utils/common.utils";
import ShoppingCartProduct from "../ShoppingCartProduct/ShoppingCartProduct";
import useStores from "contexts/stores/stores.context.hooks";
import useProducts from "contexts/products/products.context.hooks";
import { goToProductDetail } from "utils/seo.utils";
import CONSTANTS from "config/constants";
import useShippingCost from "hooks/useShippingCost";
import { createErrorNotification } from "utils/notifications.utils";
import useAuth from "contexts/auth/auth.context.hooks";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import { getCartMessage } from "../CartPayButton/CartPayButton.helpers";
import { usePostTalkShop } from "services/talkShop/talkShop/talkShop.service.hooks";
import useCountries from "contexts/countries/countries.hooks";

const { logRemoveProductFromCart } = events.shoppingCart;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = CONSTANTS.ARTISN;
const { WITH_PRODUCT_MODAL, WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;
const { PHONE_NUMBER } = CONSTANTS.INTEGRATIONS.WHATSAPP;
const { API_URL } = CONSTANTS.API;

const CartProducts: React.FC<Props> = props => {
  const { disabled, className } = props;
  const { isAnonymous = false, uid } = useAuth();
  const { shoppingCart, showModifiers, setTemporalBenefit } = useShoppingCart();
  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const router = useRouter();
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const empty = (products && products.length === 0) || !shoppingCart;
  const { name: shoppingCartName, id: shoppingCartId } = shoppingCart ?? {};
  const { selectedStore } = useStores();
  const shippingCost = useShippingCost();
  const { talkShopIdentifier, talkShopProvider } = useTalkShop();
  const { mutate } = usePostTalkShop();
  const { selectedCountry } = useCountries();
  const notInitialRender = useRef(false);
  const talkShop = !!talkShopProvider && !!talkShopIdentifier && WITH_TALK_SHOP;
  const { setSelectedProduct, setOpenProductModal } = useProducts();

  const sortedProducts = useMemo(
    () => products?.sort(sortByProductDate),
    [products]
  );

  const deleteProductHandler = async (product: CartProduct) => {
    if (!uid || !selectedStore) return;

    removeProduct(product, {
      shoppingCartName,
      anonymous: isAnonymous,
      accountId: ACCOUNT_ID,
      customerId: uid,
      store: selectedStore
    });
    notInitialRender.current = true;

    if (!shoppingCartId || !selectedStore) return;

    // If there is a benefit applied to the cart
    if (selectedBenefit) {
      const { benefitId, type } = selectedBenefit;

      /* If selected benefit is of type PRODUCT, reset context because remove
        product function already deletes benefits from cart */
      if (product.benefitId === benefitId) setTemporalBenefit(undefined);

      /* If selected benefit is of type ALTER_DELIVERY and there is just one
        product on the cart, remove the benefit and reset context */
      if (type === "ALTER_DELIVERY" && products?.length === 1) {
        try {
          const { benefitId } = selectedBenefit;

          await removeBenefit({
            benefitId,
            shippingCost,
            shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
            anonymous: isAnonymous,
            apiURL: API_URL,
            accountId: ACCOUNT_ID,
            customerId: uid
          });
          setTemporalBenefit(undefined);
        } catch (e) {
          notify(e, "Delete product");
          createErrorNotification(e.message, "Cupones");
        }
      }
    }

    logRemoveProductFromCart({
      cartId: shoppingCartId,
      product,
      store: selectedStore
    });
  };

  const onClickModal = (productId: string) => {
    setOpenProductModal(productId);
  };

  const isPageProductDetail = () => {
    const pathname = router.pathname.split("/").slice(1);
    const isCorrectPage =
      pathname.length === 3 && pathname[2] === "[productName]";

    return isCorrectPage;
  };

  const onClickProduct = (product: CartProduct) => {
    setSelectedProduct(product);
    if (WITH_PRODUCT_MODAL && !isPageProductDetail()) {
      onClickModal(product.productId);
    } else {
      goToProductDetail(product);
    }
  };

  const onChangeQuantity = (amount: number, product: CartProduct) => {
    if (!uid || !selectedStore) return;
    setProduct(product, {
      amount,
      shoppingCartName,
      anonymous: isAnonymous,
      accountId: ACCOUNT_ID,
      customerId: uid,
      store: selectedStore
    });
    notInitialRender.current = true;
  };

  const sendMessage = useCallback(() => {
    const talkShopData = {
      to: `whatsapp:+${talkShopIdentifier.trim()}`,
      from: `whatsapp:+${PHONE_NUMBER}`,
      body: `${getCartMessage(
        products,
        showModifiers,
        shoppingCart,
        selectedCountry
      )}`
    };

    if (talkShop) {
      mutate(talkShopData);
      notInitialRender.current = false;
    }
  }, [
    products,
    selectedCountry,
    shoppingCart,
    showModifiers,
    talkShopIdentifier,
    mutate,
    talkShop
  ]);

  useEffect(() => {
    if (!shoppingCart || !notInitialRender.current || empty) return;
    sendMessage();
  }, [shoppingCart, sendMessage, empty]);

  return (
    <Styles className={`CartProducts ${className}`}>
      {!products && shoppingCart
        ? Array(3)
            .fill("")
            .map((_, i) => <ShoppingCartProductPlaceholder key={i} />)
        : null}
      {empty ? (
        <EmptyCart />
      ) : (
        sortedProducts?.map((product, index) => {
          const { amount, productId, benefitId } = product;
          const isBenefitProduct =
            selectedBenefit && benefitId === selectedBenefit.benefitId;
          const modifiers = showModifiers(product);
          return (
            <ShoppingCartProduct
              product={product}
              initialQuantity={amount}
              onChangeQuantity={(amount: number) =>
                onChangeQuantity(amount, product)
              }
              onDelete={() => deleteProductHandler(product)}
              onClick={() => onClickProduct(product)}
              key={`${productId}-${index}`}
              disabled={disabled || isBenefitProduct}
              modifiers={modifiers}
            />
          );
        })
      )}
    </Styles>
  );
};

CartProducts.defaultProps = {
  className: ""
};

export default CartProducts;
