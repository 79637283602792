import { useContext } from "react";

import { ProductsContext } from "contexts/products/products.context";
import { ProductsProviderValue } from "contexts/products/products.context.types";

const useProducts = () => {
  const context = useContext<ProductsProviderValue>(ProductsContext);
  if (typeof context === "undefined") {
    throw new Error("useProducts must be used within a ProductsProvider");
  }
  return context;
};

export default useProducts;
