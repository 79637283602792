import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { ShoppingCartNotificationsProviderProps as Props } from "./shoppingCartNotifications.context.types";
import { ShoppingCartNotificationsProviderValue } from "./shoppingCartNotifications.context.types";

export const ShoppingCartNotificationsContext =
  // @ts-ignore
  createContext<ShoppingCartNotificationsProviderValue>({});

const ShoppingCartNotificationsProvider: React.FC<Props> = props => {
  const [showMapCoordinates, setShowMapCoordinates] = useState<boolean>(false);
  const [showNoCoverage, setShowNoCoverage] = useState<boolean>(false);
  const [showClosedStore, setClosedStore] = useState<boolean>(false);
  const value: ShoppingCartNotificationsProviderValue = useMemo(() => {
    return {
      showMapCoordinates,
      setShowMapCoordinates,
      showNoCoverage,
      setShowNoCoverage,
      showClosedStore,
      setClosedStore
    };
  }, [showMapCoordinates, showNoCoverage, showClosedStore]);

  return (
    <ShoppingCartNotificationsContext.Provider value={value}>
      <ContextDevTool
        context={ShoppingCartNotificationsContext}
        id="shoppingCartNotifications"
        displayName="ShoppingCartNotifications"
      />
      {props.children}
    </ShoppingCartNotificationsContext.Provider>
  );
};

export default ShoppingCartNotificationsProvider;
