import { useContext } from "react";

import { BillingDataContext } from "contexts/billingData/billingData.context";
import { BillingDataProviderValue } from "./billingData.context.types";

const useBillingData = () => {
  const context = useContext<BillingDataProviderValue>(BillingDataContext);
  if (typeof context === "undefined") {
    throw new Error("useBillingData must be used within a BillingProvider");
  }
  return context;
};

export default useBillingData;
