import React from "react";

import Styles from "./AddNewRecordButton.styles";
import { AddNewRecordButtonProps as Props } from "./AddNewRecordButton.types";

import BillingDataSVG from "../../../../public/assets/images/billing-data.svg";

const AddNewRecordButton: React.FC<Props> = props => {
  const { className, value, icon, ...rest } = props;

  return (
    <Styles className={`AddNewRecordButton ${className}`} {...rest}>
      {icon ? icon : <BillingDataSVG />}
      <p className="AddNewRecordButton__value">{value}</p>
    </Styles>
  );
};

AddNewRecordButton.defaultProps = {
  className: ""
};

export default AddNewRecordButton;
