import styled from "styled-components";

import { ModalHeaderStyledProps } from "./ProductModal.types";
import { ModalIconStyledProps } from "./ProductModal.types";
import { ProductModalStyledProps as Props } from "./ProductModal.types";
import CONSTANTS from "config/constants";
import Modal from "components/global/Modal/Modal";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const ProductModalStyled = styled(Modal)<Props>`
  width: min(${getMaxWidth()}, 78.4rem);
  height: min(${getMaxHeight()}, 62.4rem);
  border-radius: 1.6rem;
  overflow: hidden;

  &&&.ProductModal {
    @media (max-width: ${tablet}px) {
      width: min(${getMaxWidth()}, 96rem);
      height: 100%;
      transform: none;
      left: 0;
      top: 0;
      margin-top: env(safe-area-inset-top);
    }
  }

  .Modal {
    &__header {
      background-color: var(--palette-white);
      border-bottom: 0.1rem solid var(--palette-lightgrey-s5-l90);
    }

    &__body {
      background-color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        .ProductError {
          &__error-state {
            &__title {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .ProductModal {
    &__main {
      width: 100%;
    }
  }

  && .ProductMain {
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "col col";
      padding: unset;

      @media (max-width: ${tablet}px) {
        grid-template-columns: 1fr;
        grid-template-areas: "col";
        overflow: hidden;
        overflow-y: auto;
      }
    }

    &__preview-image {
      padding: 1.2rem 1.6rem;

      @media (max-width: ${tablet}px) {
        padding: 0;
      }

      .Image {
        @media (max-width: ${tablet}px) {
          height: 100%;
          border-radius: 0;
        }
      }
    }

    &__form {
      max-height: 49.6rem;
      padding: 0;
      padding-top: 1.6rem;
      margin: 0;
      border-radius: 0;
      background-color: var(--palette-gray-s0-l98);
      overflow: hidden;
      overflow-y: auto;
      border-top: none;
      padding-bottom: 7.2rem;

      @media (max-width: ${tablet}px) {
        padding-bottom: 12rem;
        max-height: unset;
        padding-top: 3.2rem;
      }

      .Pocket {
        &__header {
          &__title {
            &:first-child {
              font-weight: 600;
              font-size: 1.6rem;
              padding: 0.8rem 0.4rem;
            }
          }
        }
      }

      .ProductBaseInfo {
        &__name {
          display: none;
          @media (max-width: ${tablet}px) {
          }
        }
      }
    }

    &__description {
      &__text {
        padding-left: 0.4rem;
        padding-right: 0.4rem;
      }
    }

    &__comment-box {
      padding-bottom: 1.2rem;
    }

    &__actions,
    &__share-product {
      display: flex;
      position: fixed;
      width: 50%;
      bottom: 0;
      padding: 0.8rem 1.2rem;
      left: 0;
      height: 6.4rem;
      background-color: var(--palette-white);
    }

    &__share-product {
      padding: calc(0.8rem + 1.4rem) 1.2rem;

      &--mobile {
        display: none;
      }

      @media (max-width: ${tablet}px) {
        &--desktop {
          display: none;
        }

        &--mobile {
          bottom: -3rem;
          left: unset;
          right: 1.2rem;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: auto;
          padding: 0;
          position: absolute;
          background-color: transparent;
          margin: 0;
        }
      }
    }

    &__actions {
      right: 0;
      left: auto;
      justify-content: flex-end;
      height: auto;
      border-top: 0.1rem solid var(--palette-gray-s0-l20-a20);

      @media (max-width: ${tablet}px) {
        position: absolute;
        display: flex;
        justify-content: space-around;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0.8rem 1.6rem;
        align-items: center;
        height: 7.2rem;
      }
    }

    &__addToCart {
      @media (max-width: ${tablet}px) {
        .AddToCartButton {
          &__btn {
            &__no-ok {
              width: 100%;
            }
          }
        }
      }
    }

    &__counter {
      @media (max-width: ${tablet}px) {
        margin-right: unset;
      }
    }
  }

  .ProductPlaceholder {
    padding: 2.4rem;

    @media (max-width: ${tablet}px) {
      padding: 0;
    }
  }
`;

export const ModalHeaderStyled = styled.div<ModalHeaderStyledProps>`
  min-height: 5.6rem;

  .ProductModal {
    &__header {
      padding: 0 1.6rem;
      margin: 1.6rem 0;
      font-size: 2.4rem;
      font-weight: 800;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
      line-height: 2.6rem;

      @media (max-width: ${tablet}px) {
        margin-bottom: 2.4rem;
      }

      @media (max-width: ${mobile}px) {
        padding-right: 4rem;
      }
    }
  }
`;

export const ModalIconStyled = styled.div<ModalIconStyledProps>`
  padding: 1.2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${tablet}px) {
    padding: 1.2rem 0.4rem;
  }
`;

export default ProductModalStyled;
