import { useContext } from "react";

import { AnalyticsContext } from "./analytics.context";
import { AnalyticsProviderValue } from "./analytics.context.types";

const useAnalytics = () => {
  const context = useContext<AnalyticsProviderValue>(AnalyticsContext);
  if (typeof context === "undefined") {
    throw new Error("useAnalytics must be used within a AnalyticsProvider");
  }
  return context;
};

export default useAnalytics;
