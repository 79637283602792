// Global utilities functions and data
import { DropdownProps } from "artisn-ui-react";
import { ArtisnPlatform, ShoppingCart } from "artisn/types";
import { GetServerSideProps } from "next";
import { Capacitor } from "@capacitor/core";
import { Gtag } from "artisn/analytics";
import { getProductTotals } from "artisn/products";
import { CartProduct, CurrencyCodes } from "artisn/types";
import { snakeCase } from "snake-case";

import packageJSON from "../../package.json";
import CONSTANTS from "config/constants";
import { getShoppingCartProducts } from "artisn/shopping-cart";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;

export const dropdownConfigs: Partial<DropdownProps> = {
  isFixed: true,
  target: ["focus"],
  position: "center"
};

export const checkServerExport = (
  cb: (
    context: Parameters<GetServerSideProps>[0]
  ) => ReturnType<GetServerSideProps>
) => {
  if (process.env.ARTISN_CAPACITOR) return;
  return cb;
};

export const getOS = () => {
  let OS = "Unknown";
  if (typeof window === "undefined") return;
  if (navigator.userAgent.indexOf("Win") !== -1) OS = "Windows";
  if (navigator.userAgent.indexOf("Mac") !== -1) OS = "MacOS";
  if (navigator.userAgent.indexOf("X11") !== -1) OS = "UNIX";
  if (navigator.userAgent.indexOf("Linux") !== -1) OS = "Linux";
  return OS;
};

export const getAnalitycsMeta = () => {
  return {
    accountId: ACCOUNT_ID,
    appName: packageJSON.name,
    versionApp: packageJSON.version,
    platform: Capacitor.getPlatform() as ArtisnPlatform
  };
};

export const eventParamsToSnakeCase = <T extends Record<string, unknown>>(
  params: T
) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    const newKey = snakeCase(key);
    return {
      ...acc,
      [newKey]: value
    };
  }, {} as Record<string, unknown>);
};

export const mapProductToGoogleItem = (
  product: CartProduct,
  currency?: CurrencyCodes
): Gtag.Item => {
  const totals = getProductTotals(product);
  const { productId, name, amount } = product;
  return {
    item_id: productId,
    item_name: name,
    quantity: amount,
    price: totals.netPrice,
    currency
  };
};

export const mapProductsToGoogleItems = (
  shoppingCart: ShoppingCart,
  currency?: CurrencyCodes
): Gtag.Item[] => {
  const products = getShoppingCartProducts(shoppingCart);
  return products.map(product => mapProductToGoogleItem(product, currency));
};

export const transformArrayParam = (param: unknown[]) => {
  return param.join(",");
};
