import styled from "styled-components";

import { StoreDetailsPickupStyledProps as Props } from "./StoreDetailsPickup.types";

const StoreDetailsPickupStyled = styled.div<Props>`
  .StoreDetailsPickup {
    &__store-preview {
      padding: 1.6rem 2.4rem;

      .InfoCard {
        &__content {
          padding: 0;
        }

        &__icon,
        &__icon-to-right {
          display: none;
        }
      }
    }

    &__schedule {
      background: var(--palette-white);
    }

    &__address-info {
      background: var(--palette-white);
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      padding-bottom: 2.4rem;
      padding-top: 1.2rem;

      &__title {
        margin-bottom: 0.8rem;
        font-size: 1.6rem;
        font-weight: 600;
      }

      &__description {
        margin-bottom: 2.4rem;
        font-size: 1.2rem;
        color: var(--palette-gray-s0-l40);
      }
    }

    &__button-container {
      padding: 0 2.4rem;
      padding-bottom: 1.6rem;
      background: var(--palette-white);
    }

    &__open-in-maps-button {
      width: 100%;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
`;

export default StoreDetailsPickupStyled;
