import { useContext } from "react";

import { ShippingAddressContext } from "./shippingAddress.context";
import { ShippingAddressProviderValue } from "./shippingAddress.context.types";

const useShippingAddress = () => {
  const context = useContext<ShippingAddressProviderValue>(
    ShippingAddressContext
  );
  if (typeof context === "undefined") {
    throw new Error(
      "useShippingAddress must be used within a ShippingAddressProvider"
    );
  }
  return context;
};

export default useShippingAddress;
