import styled from "styled-components";

import { ErrorBlockStyledProps as Props } from "./ErrorBlock.types";

const ErrorBlockStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ErrorBlock {
    &__message {
      font-size: 2.4rem;
      font-weight: 800;
      color: var(--palette-black-s0-l20);
      line-height: 2.4rem;
      width: 24rem;
      text-align: center;
    }

    &__icon {
    }

    &__button {
      margin-top: 3.2rem;
      padding: 1.6rem 2rem;
      font-size: 1.6rem;
      font-weight: 800;
      border-radius: 12rem;
    }
  }
`;

export default ErrorBlockStyled;
