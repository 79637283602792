import React from "react";

import Styles from "./ErrorNotification.styles";
import { ErrorNotificationProps as Props } from "./ErrorNotification.types";

import ErrorSVG from "../../../../../public/assets/images/error-notification.svg";

const ErrorNotification: React.FC<Props> = props => {
  const { className, message, title, clickable = false } = props;

  return (
    <Styles
      className={`ErrorNotification ${className}`}
      showCloseIcon={false}
      clickable={clickable}
    >
      <ErrorSVG />
      <p className="ErrorNotification__title">
        {title ? title : "Parece que algo falló"}
      </p>
      <p className="ErrorNotification__message">{message}</p>
    </Styles>
  );
};

ErrorNotification.defaultProps = {
  className: ""
};

export default ErrorNotification;
