// Setup Analytics library
import { useEffect } from "react";
import { initAnalytics } from "@artisan-commerce/analytics-capacitor";
import { updateActiveVendor } from "@artisan-commerce/analytics-capacitor";
import { checkInit } from "@artisan-commerce/analytics-capacitor";
import { Capacitor } from "@capacitor/core";
import { ArtisnPlatform } from "artisn/types";

import packageJSON from "../../../package.json";
import { facebookProvider } from "config/artisn.config";
import { googleProvider } from "config/artisn.config";
import useVendors from "contexts/vendors/vendors.hooks";
import CONSTANTS from "config/constants";

const { ACCOUNT_ID, VENDORS, DEFAULT_VENDOR } = CONSTANTS.ARTISN;
const { id: vendorId } = DEFAULT_VENDOR;

const useSetupAnalytics = () => {
  const { selectedVendorId } = useVendors();

  useEffect(() => {
    if (typeof window === "undefined" || checkInit()) {
      return;
    }

    initAnalytics({
      // @ts-ignore Issue vendor update artisn sdk
      activeVendor: `${vendorId}`,
      // @ts-ignore Issue vendor update artisn sdk
      vendors: VENDORS.map(vendor => `${vendor.id}`),
      providers: [facebookProvider, googleProvider],
      meta: {
        accountId: ACCOUNT_ID,
        appName: packageJSON.name,
        versionApp: packageJSON.version,
        platform: Capacitor.getPlatform() as ArtisnPlatform
      },
      debug: false
    });
  }, []);

  useEffect(() => {
    if (typeof window === "undefined" || !checkInit()) {
      return;
    }
    // @ts-ignore Issue vendor update artisn sdk
    updateActiveVendor(`${selectedVendorId}`);
  }, [selectedVendorId]);
};

export default useSetupAnalytics;
