import React from "react";

import Styles, { ModalHeaderStyled } from "./ProductModal.styles";
import { ModalIconStyled } from "./ProductModal.styles";
import { ProductModalProps as Props } from "./ProductModal.types";
import CONSTANTS from "config/constants";
import useVendors from "contexts/vendors/vendors.hooks";
import ProductMain from "components/product/ProductMain/ProductMain";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import useStores from "contexts/stores/stores.context.hooks";
import useProducts from "contexts/products/products.context.hooks";
import { useFetchProductDetails } from "services/products/products.service.hooks";
import useAuth from "contexts/auth/auth.context.hooks";

import CloseSVG from "../../../../public/assets/images/close.svg";

const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_PRODUCT_MODAL } = FEATURE_FLAGS;

const ProductModal: React.FC<Props> = props => {
  const { className, isOpen, onClose, productId } = props;

  const { selectedVendorId } = useVendors();
  const { selectedCatalogueId } = useCatalogues();
  const { selectedStore } = useStores();
  const { selectedProduct } = useProducts();
  const { isAnonymous } = useAuth();

  const { storeId } = selectedStore ?? {};
  const productsDetailsResponse = useFetchProductDetails(productId, {
    catalogueId: selectedCatalogueId,
    storeId: storeId ?? 0,
    vendorId: selectedVendorId,
    withCategories: true
  });
  const { data: productData, isLoading, isError } = productsDetailsResponse;
  const product = selectedProduct ?? productData;
  const { name = "" } = product ?? {};

  const modalHeaderNode = (
    <ModalHeaderStyled id="modal-header">
      <p className="ProductModal__header">{name}</p>
    </ModalHeaderStyled>
  );

  const modalIconNode = (
    <ModalIconStyled>
      <CloseSVG />
    </ModalIconStyled>
  );

  return (
    <Styles
      className={`ProductModal ${className}`}
      header={modalHeaderNode}
      closeIcon={modalIconNode}
      opened={isOpen}
      onClose={onClose}
      backdropConfig={{ className: "ProductModalBackdrop", opacity: 0.5 }}
    >
      <ProductMain
        className="ProductModal__main"
        productId={productId}
        isLoading={isLoading}
        isError={isError}
        isSignedIn={!isAnonymous}
        product={product}
        isModal={WITH_PRODUCT_MODAL}
        onCloseModal={onClose}
      />
    </Styles>
  );
};

ProductModal.defaultProps = {
  className: ""
};

export default ProductModal;
