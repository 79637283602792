import styled from "styled-components";

import { SignInModalStyledProps as Props } from "./SignInModal.types";
import Modal from "components/global/Modal/Modal";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const SignInModalStyled = styled(Modal)<Props>`
  &&&.SignInModal {
    height: auto;
    border-radius: 2rem;
    max-width: 42rem;

    @media (max-width: ${mobile}px) {
      max-width: calc(
        100vw - (100vw - 100%) - env(safe-area-inset-left) -
          env(safe-area-inset-right)
      );
    }
  }

  .SignInModal {
    &__title {
      font-size: 3.6rem;
      padding-top: 9.2rem;
      padding-left: 2.4rem;
      max-width: 36rem;
      line-height: 4rem;

      @media (max-width: ${tablet}px) {
        padding-top: 6.4rem;
      }
    }

    &__divider {
      height: 0.1rem;
      border: 0.1rem solid var(--palette-gray-s0-l80-a60);
      margin: 0 3rem;
    }
  }

  .SignInForm {
    margin: 0 4rem;
  }

  .SingleSignOn {
    padding: 4rem;

    @media (max-width: ${tablet}px) {
      padding: 2.4rem;
      padding-bottom: 0;
    }
  }

  .SignOptions {
    padding-bottom: 4rem;
  }

  && .Modal__close-icon {
    top: 4.2rem;
    right: 2.4rem;
  }
`;

export default SignInModalStyled;
