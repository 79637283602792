import styled from "styled-components";

import { InfoCardStyledProps as Props } from "./InfoCard.types";

const InfoCardStyled = styled.div<Props>`
  display: flex;
  align-items: center;

  .InfoCard {
    &__icon,
    &__icon-to-right {
      width: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__icon-to-right {
      margin-left: auto;
    }

    &__content {
      padding-left: 1.2rem;
      width: 84%;

      &__title {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: var(--palette-gray-s0-l50);
      }

      &__value {
        padding-top: 0.6rem;
        font-family: Inter;
        line-height: 2rem;
        color: var(--palette-gray-s0-l20);
        font-size: 1.6rem;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 100%;
      }
    }
  }
`;

export default InfoCardStyled;
