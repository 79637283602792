import CONSTANTS from "config/constants";
import { shouldMock } from "utils/common.utils";
import { auth } from "./artisn.config";
import { initServices } from "services/initServices/initServices";
import { queryClient } from "./reactQuery.config";

const { GENERAL, API, ARTISN } = CONSTANTS;
const { PLATFORM } = GENERAL;
const { ACCOUNT_ID } = ARTISN;
const { MOCK_WITH_INITIAL_USER_DATA } = API;

initServices({
  platform: PLATFORM,
  accountId: ACCOUNT_ID,
  shouldMock,
  queryClient,
  mockWithInitialUserData: MOCK_WITH_INITIAL_USER_DATA,
  auth: auth
});
