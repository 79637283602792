import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordInput, EmailInput, Accordion } from "artisn-ui-react";

import Styles from "./SignInForm.styles";
import { signInSchema } from "./SignInForm.helpers";
import Button from "components/global/Button/Button";
import { SignInFormValues, SignInFormProps as Props } from "./SignInForm.types";
import PasswordStrength from "components/global/PasswordStrength/PasswordStrength";
import { getFirebaseAuthErrorMessage, notify } from "utils/common.utils";
import { trimFields } from "utils/form.utils";

const { Section } = Accordion;

const SignInForm: React.FC<Props> = props => {
  const { initialValues, currentForm = "signIn", isFetched = false } = props;
  const { buttonTitle = "Iniciar sesión", onSubmitSuccess } = props;
  const { push, asPath } = useRouter();
  const { handleSubmit, register, watch, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(signInSchema(currentForm)),
    defaultValues: initialValues
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { email, password } = formState.errors ?? {};

  const submitHandler = async (form: SignInFormValues) => {
    const newForm = trimFields(form);
    const { email, password } = newForm;
    setErrorMessage(getFirebaseAuthErrorMessage());
    setLoading(true);
    try {
      await onSubmitSuccess(email, password);
    } catch (e) {
      notify(e, "Signin");
      setErrorMessage(getFirebaseAuthErrorMessage(e.code));
      setLoading(false);
      console.error(e);
    }
  };

  const focusHandler = () => {
    setErrorMessage(getFirebaseAuthErrorMessage());
  };

  const recoverPassword = () => {
    setDisabled(true);
    push("/recover");
  };

  useEffect(() => {
    if (isFetched) setLoading(false);
  }, [isFetched]);

  return (
    <Styles className="SignInForm">
      <Accordion
        className="SignInForm__accordion"
        defaultOpenedIds={
          currentForm === "signIn" || asPath === "/signup" ? ["0"] : undefined
        }
      >
        <Section
          className="SignInForm__section"
          title={
            currentForm === "signUp"
              ? "O utiliza tu correo"
              : "Inicia sesión con tu correo"
          }
          id="0"
        >
          <form onSubmit={handleSubmit(submitHandler)}>
            <EmailInput
              {...register("email")}
              className="field SignInForm__email-input"
              label="Correo electrónico"
              placeholder="hola@email.com"
              onFocus={focusHandler}
              errorMessage={email?.message}
              maxLength={50}
            />
            <PasswordInput
              {...register("password")}
              label="Contraseña"
              className="field SignInForm__password-input"
              errorMessage={password?.message || errorMessage}
              onFocus={focusHandler}
            />
            {currentForm === "signUp" ? (
              <PasswordStrength
                className="SignInForm__password-strength"
                password={watch("password")}
              />
            ) : null}
            <div className="SignInForm__buttons">
              {currentForm === "signIn" ? (
                <Button
                  type="LINK"
                  className="SignInForm__forgot"
                  onClick={recoverPassword}
                  htmlType="button"
                >
                  Olvidé mi contraseña
                </Button>
              ) : null}
              <Button
                type="FILLED"
                color="primary"
                htmlType="submit"
                disabled={disabled}
                className="SignInForm__submit"
                isLoading={loading}
              >
                {buttonTitle}
              </Button>
            </div>
          </form>
        </Section>
      </Accordion>
    </Styles>
  );
};

SignInForm.defaultProps = {};

export default SignInForm;
