import styled from "styled-components";

import { ChooseAddressStyledProps as Props } from "./ChooseAddress.types";
import { DeleteAddressModalStyledProps as ModalProps } from "./ChooseAddress.types";
import InfoActionModal from "components/global/InfoActionModal/InfoActionModal";

const ChooseAddressStyled = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  pointer-events: ${props => (props.disabled ? "none" : "initial")};

  .ChooseAddress {
    &__radio-button-container {
      margin-right: 2rem;
      padding: 0;
    }

    &__radio-button {
      width: 2.4rem;
      height: 2.4rem;
      border: 0.1rem solid var(--palette-gray-s0-l80);
      border-radius: 10rem;

      &--active {
        border: 0.7rem solid var(--palette-primary);
      }
    }
  }
`;

export const DeleteAddressModal = styled(InfoActionModal)<ModalProps>`
  path {
    fill: var(--palette-black);
  }

  .DeleteAddressModal {
  }
`;

export default ChooseAddressStyled;
