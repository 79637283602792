import { Providers } from "@artisan-commerce/analytics-capacitor";
import { Capacitor } from "@capacitor/core";
import { initializeApp } from "firebase/app";
import { getAuth as firebaseGetAuth, initializeAuth } from "firebase/auth";
import { indexedDBLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, isSupported } from "firebase/analytics";

import CONSTANTS from "config/constants";
import { FACEBOOK_PIXEL_WEB_ID } from "config/constants";

const { ARTISN, GENERAL, API, APP } = CONSTANTS;
const { PLATFORM } = GENERAL;
const { ACCOUNT_ID, DEFAULT_VENDOR } = ARTISN;
const { API_URL } = API;
const { WEB_URL } = APP;

const isIOS = Capacitor.getPlatform() === "ios";
const isAndroid = Capacitor.getPlatform() === "android";
const isMobile = isIOS || isAndroid;

export const getENVs = (() => {
  const env = process.env.NEXT_PUBLIC_ENV ?? "develop";

  return {
    develop: {
      apiKey: "AIzaSyB0ERGESuBIs5touXKrDkuGfr8JmeVl14M",
      authDomain: "hornero-production.firebaseapp.com",
      databaseURL: "https://hornero-production.firebaseio.com",
      projectId: "hornero-production",
      storageBucket: "hornero-production.appspot.com",
      messagingSenderId: "573168528162",
      appId: "1:573168528162:web:d07eaa8920632891ab937b",
      measurementId: "G-5CJED8XQBV",
      mapsApiKey: "AIzaSyDxH9HqBKILdoOUdJb8LKGo4n9oFV8I1fs",
      facebookDataSourceId: "519720026965541",
      facebookAppId: "1142745186400792",
      facebookAppToken:
        "EAAN1WNTxDdYBO2BsrOSAQZBkV9vgLk20YEY7U0Pe7lZBWQ1vBzJc9oBbyrKKxAVAhCy28Jvuq486ZAaZCq9tPUZBc2fM9sQqa0oRWZAC4PQAsnXpJZB4m19dcI1l9FeL1IJQyJZANZC0e3LtL2OZC31czOZBkWQdt3BHI4ICTtjFyVCoby850JehKUIyQZBQjCidZAAZDZD",
      facebookWebId: FACEBOOK_PIXEL_WEB_ID,
      facebookWebToken:
        "EAAN1WNTxDdYBOZBtxgDhcem9OkPYQqbZBFwvwg2WZCZAbKc9mLuemkYSrON7g7S9NH4442ASJyXuxw3lCcVzGrAQfy5XmO2wKL02F0FUFZAHCLt9WDuOcJpKlAEGeZBCSWClasrCQnUjhv1wq55GpmZC5lwKrPErgDIMyE3L4cq77wqsSAymBmYCHYWGSsJOAZDZD",
      domain: WEB_URL
    },
    production: {
      apiKey: "AIzaSyB0ERGESuBIs5touXKrDkuGfr8JmeVl14M",
      authDomain: "hornero-production.firebaseapp.com",
      databaseURL: "https://hornero-production.firebaseio.com",
      projectId: "hornero-production",
      storageBucket: "hornero-production.appspot.com",
      messagingSenderId: "573168528162",
      appId: "1:573168528162:web:d07eaa8920632891ab937b",
      measurementId: "G-5CJED8XQBV",
      mapsApiKey: "AIzaSyDxH9HqBKILdoOUdJb8LKGo4n9oFV8I1fs",
      facebookDataSourceId: "519720026965541",
      facebookAppId: "1142745186400792",
      facebookAppToken:
        "EAAN1WNTxDdYBO2BsrOSAQZBkV9vgLk20YEY7U0Pe7lZBWQ1vBzJc9oBbyrKKxAVAhCy28Jvuq486ZAaZCq9tPUZBc2fM9sQqa0oRWZAC4PQAsnXpJZB4m19dcI1l9FeL1IJQyJZANZC0e3LtL2OZC31czOZBkWQdt3BHI4ICTtjFyVCoby850JehKUIyQZBQjCidZAAZDZD",
      facebookWebId: FACEBOOK_PIXEL_WEB_ID,
      facebookWebToken:
        "EAAN1WNTxDdYBOZBtxgDhcem9OkPYQqbZBFwvwg2WZCZAbKc9mLuemkYSrON7g7S9NH4442ASJyXuxw3lCcVzGrAQfy5XmO2wKL02F0FUFZAHCLt9WDuOcJpKlAEGeZBCSWClasrCQnUjhv1wq55GpmZC5lwKrPErgDIMyE3L4cq77wqsSAymBmYCHYWGSsJOAZDZD",
      domain: WEB_URL
    }
  }[env];
})();

const firebaseConfig = {
  projectId: getENVs?.projectId ?? "",
  apiKey: getENVs?.apiKey ?? "",
  authDomain: `${getENVs?.projectId}.firebaseapp.com`,
  databaseURL: getENVs?.databaseURL,
  appId: getENVs?.appId,
  accountId: ACCOUNT_ID,
  platform: PLATFORM
};

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);

function whichAuth() {
  let auth;
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence
    });
  } else {
    auth = firebaseGetAuth(app);
  }
  return auth;
}

export const auth = whichAuth();

export const analytics = async () => {
  const analyticsIsSupported = await isSupported();
  if (analyticsIsSupported) return getAnalytics(app);
};

export const facebookProvider = new Providers.FacebookPixel({
  name: "facebook",
  trackingId: isMobile
    ? `${getENVs?.facebookDataSourceId ?? ""}`
    : FACEBOOK_PIXEL_WEB_ID,
  vendors: [DEFAULT_VENDOR.id],
  version: "2.0",
  withConversionsAPI: true,
  isTrackingAllowed: isMobile ? true : false,
  conversionsApiConfig: {
    apiURL: API_URL,
    authToken: "",
    countryISOCode: "ec",
    eventSourceUrl: !isMobile ? `${getENVs?.domain ?? ""}` : "",
    fbAccessToken: isMobile
      ? `${getENVs?.facebookAppToken ?? ""}`
      : `${getENVs?.facebookWebToken ?? ""}`,
    fbDataSourceId: isMobile
      ? `${getENVs?.facebookDataSourceId ?? ""}`
      : `${getENVs?.facebookWebId ?? ""}`,
    advertiserTrackingEnabled: true,
    applicationTrackingEnabled: true,
    testCode: "TEST92590",
    platform: !isMobile ? "web" : isAndroid ? "android" : "ios"
  }
});

export const googleProvider = new Providers.GoogleAnalytics({
  name: "google",
  trackingId: getENVs?.measurementId ?? ""
});
