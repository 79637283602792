import styled from "styled-components";

import CONSTANTS from "../../../config/constants";
import { SignOptionsStyledProps as Props } from "./SignOptions.types";

const { mobile } = CONSTANTS.BREAKPOINTS;

const SignOptionsStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.4rem;
  padding-top: 1.4rem;

  @media (max-width: ${mobile - 1}px) {
    justify-content: center;
  }

  .SignOptions {
    &__message {
      color: var(--palette-gray-s0-l40);
      margin-bottom: 0.8rem;
    }

    &__button {
      padding: 0;
      font-weight: 600;

      .Button__wrapper {
        font-size: 1.6rem;
      }
    }
  }
`;

export default SignOptionsStyled;
