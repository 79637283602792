import React, { useState, useMemo } from "react";
import { Badge } from "artisn-ui-react";
import { redeemCoupon } from "artisn/shopping-cart";

import CONSTANTS from "config/constants";
import Styles from "./RedeemCoupon.styles";
import Button from "components/global/Button/Button";
import { getBenefitProductId, notify } from "utils/common.utils";
import { RedeemCouponProps as Props } from "./RedeemCoupon.types";
import RedeemCouponInput from "./RedeemCouponInput/RedeemCouponInput";
import { createSuccessNotification } from "utils/notifications.utils";
import ProductModal from "components/global/ProductModal/ProductModal";
import CouponsModal from "components/coupons/CouponsModal/CouponsModal";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import SignInModal from "components/global/SignInModal/SignInModal";
import useAuth from "contexts/auth/auth.context.hooks";
import { buildArtisnHeaders } from "utils/services.utils";

import Coupon from "../../../../public/assets/images/coupon.svg";

const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = CONSTANTS.ARTISN;
const { API_URL } = CONSTANTS.API;

const RedeemCoupon: React.FC<Props> = props => {
  const { className, inputOnly } = props;
  const [opened, setOpened] = useState(false);
  const [redeemCouponError, setRedeemCouponError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const { benefitsWallet, temporalBenefit } = useShoppingCart();
  const { benefits } = benefitsWallet ?? {};
  const benefitsLength = useMemo(() => benefits?.length, [benefits]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const productId = getBenefitProductId(temporalBenefit);
  const [authModal, setAuthModal] = useState("");
  const { isAnonymous = false, uid } = useAuth();

  const redeemHandler = async (code: string) => {
    if (!uid) return;
    if (isAnonymous) {
      setAuthModal("signup");
      return;
    }
    if (code.trim() === "") return;

    try {
      setRedeemCouponError("");
      const headers = await buildArtisnHeaders();
      await redeemCoupon(
        {
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          apiURL: API_URL,
          accountId: ACCOUNT_ID,
          anonymous: isAnonymous,
          customerId: uid
        },
        code,
        headers
      );
      setCouponCode("");
      createSuccessNotification("Cupón redimido con éxito");
    } catch (e) {
      notify(e, "Redeem Coupon");
      setRedeemCouponError(
        "No se ha podido redimir el cupón o ya estaba redimido"
      );
    }
  };

  const openHandler = () => {
    setRedeemCouponError("");
    setOpened(prev => !prev);
  };

  const onClickModal = () => {
    setIsOpenModal(true);
  };

  const buttonNode = (
    <Button
      className="RedeemCoupon__button-wallet"
      onClick={() => openHandler()}
    >
      <Coupon viewBox="0 0 14 10" />
    </Button>
  );

  return (
    <Styles className={`RedeemCoupon ${className}`}>
      <div className="RedeemCoupon__container">
        <RedeemCouponInput
          className="RedeemCoupon__input"
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          onRedeem={redeemHandler}
        />
        {!inputOnly ? (
          <>
            {benefitsLength ? (
              <Badge
                value={benefitsLength.toString()}
                className="RedeemCoupon__badge"
              >
                {buttonNode}
              </Badge>
            ) : (
              <div className="RedeemCoupon__badge">{buttonNode}</div>
            )}
          </>
        ) : null}
      </div>
      {redeemCouponError ? (
        <p className="RedeemCoupon__redeem-error">{redeemCouponError}</p>
      ) : null}
      <CouponsModal
        opened={opened}
        benefits={benefits}
        redeemCouponError={redeemCouponError}
        redeemHandler={redeemHandler}
        openProductModal={onClickModal}
        setRedeemCouponError={setRedeemCouponError}
        onClose={() => setOpened(prev => !prev)}
      />
      <ProductModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        productId={productId}
      />
      <SignInModal
        mode={authModal}
        opened={!!authModal}
        onClose={() => setAuthModal("")}
      />
    </Styles>
  );
};

RedeemCoupon.defaultProps = {
  className: ""
};

export default RedeemCoupon;
