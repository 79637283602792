import { useCallback, useEffect } from "react";

import useGeo from "contexts/geo/geo.hooks";
import { getCurrentPosition } from "utils/geo.utils";

const useRequestNavigatorGeo = () => {
  const { deviceCoordinates, setDeviceCoordinates } = useGeo();
  const { setDeviceCoordinatesError } = useGeo();

  const requestNavigatorGeo = useCallback(() => {
    if (deviceCoordinates) {
      setDeviceCoordinatesError("");
      return;
    }
    const errorHandler = (error: Error) => {
      const { name, message } = error;
      console.error("Capacitor coordinates error", message);
      if (name === "LocationError") {
        setDeviceCoordinatesError("Permisos de localización no concedidos");
        return;
      }
      setDeviceCoordinatesError(message);
    };
    getCurrentPosition(coord => {
      setDeviceCoordinates({
        lat: coord.coords.latitude,
        lng: coord.coords.longitude
      });
    }, errorHandler);
  }, [setDeviceCoordinates, setDeviceCoordinatesError, deviceCoordinates]);

  useEffect(() => {
    requestNavigatorGeo();
  }, [requestNavigatorGeo]);
};

export default useRequestNavigatorGeo;
