import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { ShareButton } from "artisn-ui-react";
import { Image } from "artisn-ui-react";
import { events } from "@artisan-commerce/analytics-capacitor";
import { Benefit, CartProduct } from "artisn/types";
import { applyBenefit } from "artisn/shopping-cart";
import { Capacitor } from "@capacitor/core";

import CONSTANTS from "config/constants";
import Styles from "./ProductMain.styles";
import ProductBaseInfo from "components/global/ProductBaseInfo/ProductBaseInfo";
import ModifiersForm from "components/global/modifiers/ModifiersForm/ModifiersForm";
import AddToCartButton from "components/products/AddToCartButton/AddToCartButton";
import ProductPlaceholder from "../Product/Product.placeholder";
import Counter from "components/global/Counter/Counter";
import { ProductMainProps as Props } from "./ProductMain.types";
import { useProductForm } from "hooks/useProductForm";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import { getFullPath, notify, scrollToElementModal } from "utils/common.utils";
import { getBenefitProductId } from "utils/common.utils";
import ProductErrorMessage from "../ProductErrorMessage/ProductErrorMessage";
import ProductError from "../ProductError/ProductError";
import useProducts from "contexts/products/products.context.hooks";
import { createAddToCartNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { createAddToWishlistNotification } from "utils/notifications.utils";
import { dismissAddToWishlistNotification } from "utils/notifications.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";
import { useAddToWishlist } from "components/products/AddToWishlistButton/AddToWishListButton.hooks";
import useShippingCost from "hooks/useShippingCost";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import useStores from "contexts/stores/stores.context.hooks";
import useOnUnmount from "hooks/useOnUnmount";
import useAuth from "contexts/auth/auth.context.hooks";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import useCountries from "contexts/countries/countries.hooks";
import { buildArtisnHeaders } from "utils/services.utils";
import useWindowSize from "hooks/useWindowSize";

import ShareArrowSVG from "../../../../public/assets/images/share-arrow.svg";

const { ARTISN, FEATURE_FLAGS, BREAKPOINTS, API } = CONSTANTS;
const { WITH_SHARE_PRODUCT, WITH_CART_DRAWER } = FEATURE_FLAGS;
const { WITH_PRODUCT_MODAL, WITH_TALK_SHOP } = FEATURE_FLAGS;
const { WITH_PURCHASE } = FEATURE_FLAGS;
const { SHOPPING_CART_WISHLIST_NAME, ACCOUNT_ID } = ARTISN;
const { tablet } = BREAKPOINTS;
const { logViewProductDetails } = events.product;
const { API_URL } = API;

const enhancedConfig = {
  shoppingCartName: SHOPPING_CART_WISHLIST_NAME
};

const ProductMain: React.FC<Props> = props => {
  const { productId, product, isLoading, isError } = props;
  const { onCloseModal, className } = props;
  const { isModal = false } = props;
  const { isAnonymous = false, uid } = useAuth();
  const { selectedProduct, setSelectedProduct } = useProducts();
  const { amount: selectedProductAmount } = (product as CartProduct) ?? {};
  const router = useRouter();
  const [amount, setAmount] = useState(selectedProductAmount ?? 1);
  const { analyticsInitialized } = useAnalytics();
  const viewProductDetailsLogged = useRef(false);
  const { images } = product ?? {};
  const { outOfStock } = product ?? {};
  const form = useProductForm(product);
  const selectedProductId = product?.productId;
  const ref = useRef(selectedProductId);
  const productRef = useRef(product);
  const [showNotification, setShowNotification] = useState(false);
  const { onClick: addToWishlist, inCart } = useAddToWishlist({
    product,
    config: enhancedConfig
  });
  const { selectedStore } = useStores();
  const shippingCost = useShippingCost();
  const { setOpenShoppingCartDrawer, setTemporalBenefit } = useShoppingCart();
  const { shoppingCart, temporalBenefit } = useShoppingCart();
  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const benefitProductId = getBenefitProductId(
    temporalBenefit,
    selectedBenefit
  );
  const isBenefit = benefitProductId === selectedProductId;
  const { talkShopProvider } = useTalkShop();
  const { currency } = useCountries().selectedCountry;
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;
  const { width } = useWindowSize();
  const isResponsive = width <= tablet;

  const goToCategories = () => {
    onCloseModal?.();
  };

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      addToWishlist();
      dismissAddToWishlistNotification();
    },
    [addToWishlist]
  );

  const applyBenefitHandler = async (temporalBenefit: Benefit) => {
    if (!uid) return;
    try {
      const headers = await buildArtisnHeaders();
      await applyBenefit(
        {
          benefitId: temporalBenefit.benefitId,
          product,
          shippingCost,
          anonymous: isAnonymous,
          productConfig: {
            amount: 1,
            store: selectedStore!,
            accountId: ACCOUNT_ID,
            anonymous: isAnonymous,
            customerId: uid
          },
          apiURL: API_URL,
          accountId: ACCOUNT_ID,
          customerId: uid
        },
        headers
      );
      setTemporalBenefit(undefined);
    } catch (e) {
      notify(e, "Apply benefit");
      createErrorNotification(e.message, "Cupones");
    }
  };

  const addToCartFinishHandler = async () => {
    if (talkShopProvider && WITH_TALK_SHOP) {
      onCloseModal?.();
      router.push("/categories");
      return;
    }

    if (WITH_CART_DRAWER && !!selectedProduct) {
      if (onCloseModal && WITH_PRODUCT_MODAL) onCloseModal();
      router.push("/categories");
    }

    if (onCloseModal && WITH_PRODUCT_MODAL) onCloseModal();

    if (WITH_CART_DRAWER && !selectedProduct) {
      dismissAddToCartNotification();
      if (temporalBenefit) await applyBenefitHandler(temporalBenefit);

      setTimeout(() => {
        createAddToCartNotification(
          amount,
          product,
          () => setOpenShoppingCartDrawer(true),
          true
        );
      }, 500);
      router.replace("/categories");
      return;
    }

    if (WITH_CART_DRAWER) return;

    if (temporalBenefit) {
      await applyBenefitHandler(temporalBenefit);
      router.replace("/cart");
      return;
    }

    router.push("/cart");
  };

  const errorHandler = () => {
    if (!form) {
      return;
    }
    const errorGroup = form.renderer.find(group => group.status !== "OK");
    if (!errorGroup) {
      return;
    }

    scrollToElementModal(`modifier-group-${errorGroup.id}`, isResponsive);
  };

  const shareButtonNode =
    WITH_SHARE_PRODUCT && !isMobile ? (
      <ShareButton
        className="ProductMain__share-button"
        config={{
          title: `Compartir producto`,
          text: `Comparte este producto con otra persona`,
          url: `${getFullPath(product)}`
        }}
      >
        <div className="ProductMain__share-button__content">
          <div className="ProductMain__share-button__icon-container">
            <ShareArrowSVG />
          </div>
          <p className="ProductMain__share-button__text">Compartir</p>
        </div>
      </ShareButton>
    ) : null;

  useEffect(() => {
    if (!product || viewProductDetailsLogged.current || !analyticsInitialized) {
      return;
    }
    logViewProductDetails({
      product
    });
    viewProductDetailsLogged.current = true;
  }, [analyticsInitialized, product, isMobile, uid, currency]);

  useEffect(() => {
    setAmount(selectedProductAmount ?? 1);
  }, [selectedProductAmount]);

  useEffect(() => {
    if (!productRef.current || !productId) return;
    if (productId !== productRef.current?.productId) {
      setSelectedProduct(undefined);
    }
  }, [productId, setSelectedProduct]);

  useEffect(() => {
    ref.current = selectedProductId;
  }, [selectedProductId]);

  useEffect(() => {
    if (!inCart || !showNotification) return;
    dismissAddToWishlistNotification();
    createAddToWishlistNotification(product, onDelete);
    setShowNotification(false);
  }, [inCart, product, onDelete, showNotification]);

  useOnUnmount(() => setSelectedProduct(undefined));

  return (
    <Styles className={`ProductMain ${className}`} isModal={isModal}>
      <div className="ProductMain__content">
        {!isLoading && product && form && productId && images ? (
          <>
            <section className="ProductMain__preview-image">
              <Image
                image={images[0].url}
                alt="producto"
                errorImage="https://res.cloudinary.com/dphyzfzpu/image/upload/v1657499038/Hornero-prod/placeholder_fft6iy.jpg"
              />
              {!isLoading && !isError && product ? (
                <section className="ProductMain__share-product ProductMain__share-product--mobile">
                  <>{shareButtonNode}</>
                </section>
              ) : null}
            </section>
            <section className="ProductMain__form" id="modifiers">
              <ProductBaseInfo product={product} />
              {outOfStock ? (
                <ProductErrorMessage goToCategories={goToCategories} />
              ) : null}
              {WITH_PURCHASE ? (
                <>
                  <ModifiersForm form={form} disabled={!!outOfStock} />
                  <section className="ProductMain__actions">
                    <Counter
                      className="ProductMain__counter"
                      min={1}
                      max={isBenefit ? 1 : undefined}
                      maxDisabled={!!isBenefit}
                      initialValue={selectedProductAmount ?? 1}
                      onChange={value => setAmount(value)}
                      disabled={!!outOfStock}
                    />
                    <AddToCartButton
                      className="ProductMain__addToCart"
                      form={form}
                      onError={errorHandler}
                      config={{
                        amount
                      }}
                      onFinish={addToCartFinishHandler}
                      disabled={!!outOfStock}
                    />
                  </section>
                </>
              ) : null}
            </section>
            {isModal && !isLoading && !isError && product ? (
              <section className="ProductMain__share-product ProductMain__share-product--desktop">
                <>{shareButtonNode}</>
              </section>
            ) : null}
          </>
        ) : null}
        {(isLoading && !isError) || (!productId && !isError) ? (
          <ProductPlaceholder />
        ) : null}
        {!isLoading && isError && !product ? <ProductError /> : null}
      </div>
    </Styles>
  );
};

ProductMain.defaultProps = {
  className: ""
};

export default ProductMain;
