// orders service hooks
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Catalogue, Order, Vendor } from "artisn/types";

import useAuth from "contexts/auth/auth.context.hooks";
import useVendors from "contexts/vendors/vendors.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import useCountries from "contexts/countries/countries.hooks";
import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";
import { fetchOrderDetails, fetchOrderHistory } from "./orders.service";
import { fetchLastCompletedOrder } from "./orders.service";
import { postMigrateAnonymousOrders } from "./orders.service";
import { fetchRebuildCart } from "./orders.service";
import { putCancelOrder } from "./orders.service";
import { fetchIncompleteOrders } from "./orders.service";
import { postOrder, postRetryOrder } from "./orders.service";
import { PostAnonymousOrderPayload } from "./orders.service.types";
import { PostOrderPayload } from "./orders.service.types";
import { PostRetryOrderPayload } from "./orders.service.types";
import { AuthProviderValue } from "contexts/auth/auth.context.types";
import { notify } from "utils/common.utils";

export const getUseFetchIncompleteOrdersKeys = (
  uid: AuthProviderValue["uid"],
  selectedCatalogueId: Catalogue["catalogueId"],
  selectedVendorId: Vendor["id"],
  vendorId?: Vendor["id"]
) => {
  const chosenVendorId = vendorId ?? selectedVendorId;
  return [uid, selectedCatalogueId, "orders", "incomplete", chosenVendorId];
};

export const useFetchOrder = (orderId?: Order["id"]) => {
  const { uid } = useAuth();
  return useQuery([uid, "orders", orderId], () => fetchOrderDetails(orderId!), {
    enabled: !!uid && !!orderId,
    refetchInterval: 3 * 1000 * 60,
    staleTime: 3 * 1000 * 60,
    onError: (e: Error) => {
      notify(e, "Get Order detail request");
    }
  });
};

export const useFetchOrderHistory = (
  category: "IN_PROGRESS" | "DONE",
  catalogueId?: Catalogue["catalogueId"],
  vendorId?: Vendor["id"]
) => {
  const { uid } = useAuth();
  const { selectedVendorId } = useVendors();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useQuery(
    [uid, "orders", "history", catalogueId, category, chosenVendorId],
    () => fetchOrderHistory(chosenVendorId, catalogueId, category),
    {
      enabled: !!uid,
      staleTime: 60 * 1000 * 60,
      onError: (e: Error) => {
        notify(e, "Get Order history request");
      }
    }
  );
};

export const useFetchLastCompletedOrder = (
  catalogueId?: Catalogue["catalogueId"],
  vendorId?: Vendor["id"]
) => {
  const { uid } = useAuth();
  const { selectedVendorId } = useVendors();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useQuery(
    [uid, "orders", "history", catalogueId, chosenVendorId],
    () => fetchLastCompletedOrder(chosenVendorId, catalogueId),
    {
      enabled: !!uid,
      staleTime: 60 * 1000 * 60,
      onError: (e: Error) => {
        notify(e, "Get Last Completed Order request");
      }
    }
  );
};

/**
 * Hook to create an order.
 *
 * @returns The useMutation result of the created order
 */
export const usePostOrder = (vendorId?: Vendor["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { selectedVendorId } = useVendors();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useMutation(
    (orderPayload: PostOrderPayload | PostAnonymousOrderPayload) =>
      postOrder(orderPayload),
    {
      // When mutate is called:
      onMutate: async () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          uid,
          "orders",
          "history",
          "IN_PROGRESS",
          chosenVendorId
        ]);
      },
      onSuccess: async () => {
        queryClient.invalidateQueries([
          uid,
          "orders",
          "history",
          undefined,
          "IN_PROGRESS",
          chosenVendorId
        ]);
        queryClient.invalidateQueries([
          uid,
          "orders",
          "history",
          undefined,
          "DONE",
          chosenVendorId
        ]);
      }
    }
  );
};

/**
 * Hook to fetch the pending orders before a purchase.
 *
 * @param {boolean} ready Value to know whether to trigger the fetch
 * @returns The useQuery result of the fetched list
 */
export const useFetchIncompleteOrders = (vendorId?: Vendor["id"]) => {
  const { selectedCatalogueId } = useCatalogues();
  const { uid } = useAuth();
  const { selectedVendorId } = useVendors();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useQuery(
    getUseFetchIncompleteOrdersKeys(
      uid,
      selectedCatalogueId,
      chosenVendorId,
      vendorId
    ),
    () => fetchIncompleteOrders(selectedCatalogueId, chosenVendorId),
    {
      enabled: !!uid && !!selectedCatalogueId,
      staleTime: 0,
      cacheTime: 0,
      onError: (e: Error) => {
        notify(e, "Get incomplete orders request");
      }
    }
  );
};

/**
 * Hook to retry a place order.
 *
 * @returns The useMutation result of the created order
 */
export const usePostRetryOrder = (vendorId?: Vendor["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { selectedVendorId } = useVendors();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useMutation(
    (retryOrderPayload: PostRetryOrderPayload) =>
      postRetryOrder(retryOrderPayload),
    {
      // When mutate is called:
      onMutate: async () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          uid,
          "orders",
          "history",
          "IN_PROGRESS",
          chosenVendorId
        ]);
      }
    }
  );
};

/**
 * Hook to cancel an order.
 *
 * @param {number} vendorId The vendor's unique identifier
 * @returns The useMutation result of the canceled order
 */
export const usePutCancelOrder = (vendorId?: Vendor["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { selectedVendorId } = useVendors();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useMutation((orderId: number) => putCancelOrder(orderId), {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([
        uid,
        "orders",
        "history",
        "IN_PROGRESS",
        chosenVendorId
      ]);
    }
  });
};

/**
 * Hook to rebuild the shopping cart.
 *
 * @param {number} vendorId The vendor's unique identifier
 * @returns The useMutation result of the canceled order
 */
export const useFetchRebuildCart = () => {
  const { shoppingCart } = useShoppingCart();
  const { id } = shoppingCart ?? {};

  return useMutation((orderId: Order["id"]) => fetchRebuildCart(orderId, id));
};

/**
 * Hook to migrate anonymous orders
 *
 * @param {number} vendorId
 * @returns The useMutation result of the orders migration
 */
export const usePostMigrateAnonymousOrders = (vendorId?: Vendor["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { transferAnonymousId, setTransferAnonymousId } = useCheckout();
  const { selectedVendorId } = useVendors();
  const { selectedCountryId } = useCountries();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useMutation(
    () => postMigrateAnonymousOrders(selectedCountryId, transferAnonymousId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          uid,
          "orders",
          "history",
          "IN_PROGRESS",
          chosenVendorId
        ]);
        queryClient.invalidateQueries([
          uid,
          "orders",
          "history",
          "DONE",
          chosenVendorId
        ]);
      },
      onSettled: () => {
        setTransferAnonymousId(undefined);
      }
    }
  );
};
