//ShippingAddress services
import { ShippingAddress } from "artisn/types";

import { PostShippingAddressPayload } from "./shippingAddress.service.types";
import { PutShippingAddressPayload } from "./shippingAddress.service.types";
import { buildHeaders } from "utils/services.utils";
import { getState } from "services/state";

const baseUrl = "api/v2/shipping-address";

/**
 * Fetches the stored shipping address of the signed in user.
 *
 * @returns {ShippingAddress[]} The list of a user's stored shipping addresses
 */
export const fetchShippingAddresses = async (): Promise<ShippingAddress[]> => {
  const { shouldMock, axiosDefault } = getState();
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(baseUrl, {
        headers: await buildHeaders()
      });

      return data.data;
    } else {
      const { mockAddresses } = await import("./shippingAddress.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockAddresses());
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Stores a new Shipping Address to the signed in user.
 *
 * @param {PostShippingAddressPayload} address Data needed to store a new shipping address
 * @returns {ShippingAddress} The newly created shipping address
 */
export const postShippingAddress = async (
  address: PostShippingAddressPayload
): Promise<ShippingAddress> => {
  const { shouldMock, axiosDefault } = getState();
  try {
    if (!shouldMock) {
      const newAddress = {
        ...address,
        city: {
          id: null,
          name: null
        }
      };
      const { data } = await axiosDefault.post(baseUrl, newAddress, {
        headers: await buildHeaders()
      });

      return data;
    } else {
      const { mockPostAddress } = await import(
        "./shippingAddress.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockPostAddress(address));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Updates a given shipping address of a user.
 *
 * @param {PutShippingAddressPayload} address The fields that will be updated
 * @returns {ShippingAddress} The updated shipping address
 */
export const putShippingAddress = async (
  address: PutShippingAddressPayload
): Promise<ShippingAddress> => {
  const { shouldMock, axiosDefault } = getState();
  const { id, ...rest } = address;
  const newAddress = {
    ...rest,
    city: {
      id: null,
      name: null
    }
  };
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.put(`${baseUrl}/${id}`, newAddress, {
        headers: await buildHeaders()
      });

      return data;
    } else {
      const { mockPutAddress } = await import("./shippingAddress.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockPutAddress(address));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Deletes a given stored shipping address of the signed in user
 *
 * @param {number} id The id of the address that will be deleted
 */
export const deleteShippingAddress = async (
  id: ShippingAddress["id"]
): Promise<void> => {
  const { shouldMock, axiosDefault } = getState();
  try {
    if (!shouldMock) {
      await axiosDefault.delete(`${baseUrl}/${id}`, {
        headers: await buildHeaders()
      });
    } else {
      const { mockDeleteAddress } = await import(
        "./shippingAddress.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockDeleteAddress(id));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
