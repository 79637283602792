import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import { CartProduct, ProductDetails } from "artisn/types";

import { ProductsProviderProps as Props } from "./products.context.types";
import { ProductsProviderValue } from "./products.context.types";

// @ts-ignore
export const ProductsContext = createContext<ProductsProviderValue>({});

const ProductsProvider: React.FC<Props> = props => {
  const [selectedProduct, setSelectedProduct] = useState<
    ProductDetails | CartProduct
  >();
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [openProductModal, setOpenProductModal] = useState("");
  const [openSearchBarDrawer, setOpenSearchBarDrawer] = useState(false);

  const resetProductsContext = () => {
    setSelectedProduct(undefined);
  };

  const value: ProductsProviderValue = useMemo(() => {
    return {
      selectedProduct,
      setSelectedProduct,
      resetProductsContext,
      showCategoryList,
      setShowCategoryList,
      openProductModal,
      setOpenProductModal,
      openSearchBarDrawer,
      setOpenSearchBarDrawer
    };
  }, [
    selectedProduct,
    showCategoryList,
    openProductModal,
    openSearchBarDrawer
  ]);

  return (
    <ProductsContext.Provider value={value}>
      <ContextDevTool
        context={ProductsContext}
        id="products"
        displayName="Products"
      />
      {props.children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;
