import { listenBenefitsWallet } from "artisn/shopping-cart";
import { useMemo } from "react";

import { UseCouponValues } from "./useCoupons.types";
import CONSTANTS from "config/constants";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import useVendors from "contexts/vendors/vendors.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import { buildArtisnHeaders } from "utils/services.utils";
import { useQuery } from "react-query";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;
const { API_URL } = CONSTANTS.API;

// Coupons custom hook functions
const useCoupons = ():
  | UseCouponValues
  | Record<keyof UseCouponValues, undefined> => {
  const { uid, isAnonymous = false } = useAuth();
  const { selectedVendorId } = useVendors();
  const { setBenefitsWallet } = useShoppingCart();
  const query = useQuery<Headers>(["artisn-headers"], buildArtisnHeaders, {
    enabled: !!uid
  });
  const { data: headers } = query;

  const config = useMemo(() => {
    if (!uid) return null;
    return {
      accountId: ACCOUNT_ID,
      apiURL: API_URL,
      anonymous: isAnonymous,
      customerId: uid,
      vendorId: selectedVendorId
    };
  }, [selectedVendorId, isAnonymous, uid]);

  return useMemo(() => {
    if (!config || !headers) return {};
    return {
      listenBenefitsWallet: () => {
        return listenBenefitsWallet(
          a => setBenefitsWallet(a ?? null),
          config,
          headers
        );
      }
    };
  }, [config, headers, setBenefitsWallet]);
};

export default useCoupons;
