// SignInForm helper functions and data
import * as yup from "yup";

import { RegisterForm } from "types/common.types";
import { validationRules } from "utils/form.utils";

const { requiredEmail, requiredString, minLength } = validationRules;

export const signInSchema = (currentForm?: RegisterForm) => {
  return yup.object().shape({
    email: requiredEmail,
    password: currentForm === "signIn" ? requiredString : minLength(8)
  });
};
