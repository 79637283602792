// Geo service hooks
import { useQuery } from "react-query";

import { Google } from "types/geo.types";
import { fetchGeocodeAddress } from "./geo.service";
import { fetchAutocompletePrediction } from "./geo.service";
import { fetchReverseGeocodeAddress } from "./geo.service";
import useCountries from "contexts/countries/countries.hooks";
import { notify } from "utils/common.utils";

export const useFetchGoogleAddressFromCoords = (
  coords?: Google.Coordinates
) => {
  return useQuery<Google.ReverseGeocodeAddress[], Error>(
    ["googleAddressFromCoords", coords],
    () => fetchReverseGeocodeAddress(coords!),
    {
      enabled: !!coords,
      staleTime: 60 * 1000 * 60,
      onError: e => {
        notify(e, "Get google address from coords request");
      }
    }
  );
};

export const useFetchCoordsFromGoogleAddress = (query?: string) => {
  return useQuery<Google.Geocode, Error>(
    ["coordsFromGoogleAddress", query],
    () => fetchGeocodeAddress(query!),
    {
      enabled: !!query,
      staleTime: 60 * 1000 * 60,
      onError: e => {
        notify(e, "Get coords from google address request");
      }
    }
  );
};

export const useFetchAutocompletePrediction = (
  query?: string,
  selectedCoordinates?: Google.Coordinates
) => {
  const { isoCode } = useCountries().selectedCountry;

  return useQuery<Google.Autocomplete[], Error>(
    ["autocompletePrediction", { query, selectedCoordinates, isoCode }],
    () => fetchAutocompletePrediction(query!, selectedCoordinates!, isoCode),
    {
      enabled: !!query && !!selectedCoordinates,
      staleTime: 60 * 1000 * 60,
      onError: e => {
        notify(e, "Get autocomplete prediction request");
      }
    }
  );
};
