import styled from "styled-components";

import { CheckboxModifierStyledProps as Props } from "./CheckboxModifier.types";

const CheckboxModifierStyled = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  background-color: var(--palette-gray-s0-l98);

  &.CheckboxModifier .Checkbox {
    &__label {
      padding: 0;
    }
  }

  .CheckboxModifier {
    display: flex;

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 800;
      color: var(--palette-black-s0-l20);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .ModifierPrices {
    margin: 0;
    display: flex;
    flex-direction: row;

    &__unitPrice {
      display: none;
    }
  }
`;

export default CheckboxModifierStyled;
