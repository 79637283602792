import React from "react";

import Styles from "./AddressList.styles";
import { AddressListProps as Props } from "./AddressList.types";
import { useFetchShippingAddresses } from "services/shippingAddress/shippingAddress.service.hooks";
import CommonPlaceholder from "components/global/CommonPlaceholder/CommonPlaceholder";
import ChooseAddress from "../ChooseAddress/ChooseAddress";
import ErrorBlock from "components/global/ErrorBlock/ErrorBlock";
import useAuth from "contexts/auth/auth.context.hooks";
import { getMatchingAddresses } from "./AddressList.helpers";
import Button from "components/global/Button/Button";
import { defaultFunction } from "utils/common.utils";

import AddressEmptySVG from "../../../../public/assets/images/address-empty.svg";

const AddressList: React.FC<Props> = props => {
  const { actions, className, onEdit, onSelect, searchResult } = props;
  const { dropdownFixed, EmptyStateCTA = defaultFunction } = props;
  const { data: addressList, isLoading, isError } = useFetchShippingAddresses();
  const empty = addressList && addressList.length === 0;
  const { uid } = useAuth();

  const placeholdersNode = (
    <>
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
    </>
  );

  const emptyNode = (
    <div className="AddressList__empty">
      <div className="AddressList__empty__icon">
        <AddressEmptySVG
          className="AddressList__address-empty-icon"
          viewBox="0 0 118 118"
        />
      </div>
      <p className="AddressList__empty__title">
        Aun no tienes direcciones guardadas
      </p>
      <Button className="AddressList__empty__button" onClick={EmptyStateCTA}>
        Agregar dirección
      </Button>
    </div>
  );

  const addressListNode = () => {
    if (!searchResult?.length) {
      return addressList?.map(address => (
        <div key={address.id} className="AddressList__item">
          <ChooseAddress
            shippingAddress={address}
            actions={actions}
            onEdit={onEdit}
            onClick={onSelect}
            dropdownFixed={dropdownFixed}
          />
        </div>
      ));
    }
    return addressList
      ?.filter(address => getMatchingAddresses(address, searchResult))
      .map(address => (
        <div key={address.id} className="AddressList__item">
          <ChooseAddress
            shippingAddress={address}
            actions={actions}
            onClick={onSelect ?? onEdit}
            dropdownFixed={dropdownFixed}
          />
        </div>
      ));
  };

  return (
    <Styles className={`AddressList ${className ?? ""}`}>
      {isLoading ? placeholdersNode : null}
      {empty && !searchResult ? emptyNode : null}
      {isError ? <ErrorBlock queryKey={[uid, "addresses"]} /> : null}
      {!isLoading && !empty ? addressListNode() : null}
    </Styles>
  );
};

AddressList.defaultProps = {};

export default AddressList;
