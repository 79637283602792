import styled from "styled-components";

import { ChooseStoreInMapStyledProps as Props } from "./ChooseStoreInMap.types";

const ChooseStoreInMapStyled = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: inherit;

  .ChooseStoreInMap {
    &__search-bar {
      padding: 0.8rem 1.2rem;
    }

    &__map {
      flex: 1;
      margin-top: 0.8rem;
      margin-bottom: 2.4rem;
    }

    &__confirm {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 2.4rem;
      padding-bottom: 2.4rem;

      &__button {
        width: 100%;
        padding: 1.6rem 2.4rem;
        border-radius: 12rem;
        font-weight: 800;
        font-size: 1.6rem;
      }
    }
  }
`;

export default ChooseStoreInMapStyled;
