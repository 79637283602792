import { useContext } from "react";

import { SignUpUserContext } from "./signUpUser.context";
import { SignUpUserProviderValue } from "./signUpUser.context.types";

const useSignUpUser = () => {
  const context = useContext<SignUpUserProviderValue>(SignUpUserContext);
  if (typeof context === "undefined") {
    throw new Error("useSignUpUser must be used within a SignUpUserProvider");
  }
  return context
};

export default useSignUpUser;
