// State functions and data
import axios from "axios";
import { Auth } from "firebase/auth";

import CONSTANTS from "config/constants";
import { GlobalState } from "types/common.types";

const { ARTISN, API, GENERAL } = CONSTANTS;
const { ACCOUNT_ID } = ARTISN;
const { PLATFORM } = GENERAL;
const { API_URL, MOCK_WITH_INITIAL_USER_DATA, MOCK_SERVICES } = API;
const { DEFAULT_REQUEST_TIMEOUT } = API;
/** The initial global state value */
export const initialState: GlobalState = {
  shouldMock:
    process.env.NEXT_PUBLIC_ENV === "production" ? false : MOCK_SERVICES,
  accountId: ACCOUNT_ID,
  apiUrl: API_URL,
  platform: PLATFORM,
  defaultRequestTimeout: DEFAULT_REQUEST_TIMEOUT,
  axiosDefault: axios,
  initialized: false,
  user: null,
  mockWithInitialUserData: MOCK_WITH_INITIAL_USER_DATA,
  queryClient: undefined,
  auth: {} as Auth
};

Object.freeze(initialState);

let state: GlobalState = { ...initialState };

Object.seal(state);

/**
 * Sets the global state.
 *
 * @param {GlobalState} overrides New values for the global state
 */
export const setState = (overrides: Partial<GlobalState>) => {
  state = { ...state, ...overrides };
};

/**
 * Gets the global state.
 *
 * @returns {GlobalState} The current global state value
 */
export const getState = (): GlobalState => {
  return { ...state };
};
