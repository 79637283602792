import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Coords } from "google-map-react";

import { Google } from "types/geo.types";
import Map from "components/global/Map/Map";
import Styles from "./ChooseAddressInMap.styles";
import SearchAddress from "components/global/SearchAddress/SearchAddress";
import { ChooseAddressInMapProps as Props } from "./ChooseAddressInMap.types";
import { useFetchGoogleAddressFromCoords } from "services/geo/geo.service.hooks";
import { useFetchNearbyStores } from "services/stores/stores.service.hooks";
import useGeo from "contexts/geo/geo.hooks";
import useCountries from "contexts/countries/countries.hooks";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";

const ChooseAddressInMap: React.FC<Props> = props => {
  const { onSelectedAddress, initialCoordinates, onSelectedCoordinates } =
    props;
  const { isAnonymous } = useAuth();
  const { selectedCoordinates } = useGeo();
  const [shippingAddressCoordinates, setShippingAddressCoordinates] =
    useState<Google.Coordinates>();
  const [mapAddress, setMapAddress] = useState<
    Google.ReverseGeocodeAddress | undefined
  >();
  const { formatted_address } = mapAddress ?? {};
  const {
    data: predictions,
    error,
    isLoading
  } = useFetchGoogleAddressFromCoords(
    shippingAddressCoordinates ?? selectedCoordinates
  );
  const [selectedAddress, setSelectedAddress] = useState<Google.Geocode>();
  const { data: stores } = useFetchNearbyStores(
    shippingAddressCoordinates ?? selectedCoordinates
  );
  const { lat, lng } = useCountries().selectedCountry;
  const defaultCoords = useMemo(() => ({ lat, lng }), [lat, lng]);
  const changeHandler = useCallback((place: Google.Geocode) => {
    const { geometry } = place;
    const { location } = geometry;
    setShippingAddressCoordinates(location);
    setSelectedAddress(place);
  }, []);
  const { setAnonymousShippingAddress } = useShippingAddress();

  const confirmHandler = (coordinates: Coords) => {
    if (isAnonymous) {
      onSelectedCoordinates?.(coordinates);
      setAnonymousShippingAddress(selectedAddress);
      return;
    }
    if (!selectedAddress) return;
    setShippingAddressCoordinates(coordinates);
    onSelectedAddress?.(selectedAddress);
  };

  const changeHandlerMap = (coordinates: Coords) => {
    setShippingAddressCoordinates(coordinates);
  };

  useEffect(() => {
    if (predictions && predictions.length > 0) {
      setMapAddress(predictions[0]);
      setSelectedAddress(predictions[0]);
    }
  }, [predictions]);

  useEffect(() => {
    if (!error) return;
    dismissErrorNotification();
    createErrorNotification("Hubo un error al mostrar la dirección en el mapa");
    console.error(error);
  }, [error]);

  useEffect(() => {
    setShippingAddressCoordinates(initialCoordinates);
  }, [initialCoordinates]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.position = "static";
  }, []);

  return (
    <Styles className="ChooseAddressInMap">
      <SearchAddress onChange={changeHandler} showSearchDropdown={true} />
      <Map
        className="ChooseAddressInMap__map"
        isPinView
        defaultZoom={17}
        center={
          shippingAddressCoordinates ?? selectedCoordinates ?? defaultCoords
        }
        address={formatted_address}
        onConfirmClick={confirmHandler}
        onChange={changeHandlerMap}
        noCoverage={!stores?.length}
        isLoading={isLoading}
      />
    </Styles>
  );
};

ChooseAddressInMap.defaultProps = {};

export default ChooseAddressInMap;
