import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { SignUpUserProviderProps as Props } from "./signUpUser.context.types";
import { SignUpUserFormContext } from "./signUpUser.context.types";
import { SignUpUserProviderValue } from "./signUpUser.context.types";

// @ts-ignore
export const SignUpUserContext = createContext<SignUpUserProviderValue>();

const SignUpUserProvider: React.FC<Props> = props => {
  const [signUpUserFormContext, setSignUpUserFormContext] =
    useState<SignUpUserFormContext>({
      name: "",
      lastname: "",
      phone: "",
      terms: false,
      acceptEmails: true,
      acceptPushNotifications: true
    });

  const value: SignUpUserProviderValue = useMemo(() => {
    return {
      signUpUserFormContext,
      setSignUpUserFormContext
    };
  }, [signUpUserFormContext]);

  return (
    <SignUpUserContext.Provider value={value}>
      <ContextDevTool
        context={SignUpUserContext}
        id="signUpUser"
        displayName="SignUpUser"
      />
      {props.children}
    </SignUpUserContext.Provider>
  );
};

export default SignUpUserProvider;
