import React, { useEffect, useState } from "react";
import { getShoppingCart } from "artisn/shopping-cart";
import { Storage } from "@capacitor/storage";
import { events } from "@artisan-commerce/analytics-capacitor";
import { AxiosError } from "axios";
import { Capacitor } from "@capacitor/core";

import Styles from "./SignInModal.styles";
import { SignInModalProps as Props } from "./SignInModal.types";
import SingleSignOn from "components/global/SingleSignOn/SingleSignOn";
import SignInForm from "components/signIn/SignIn/SignInForm/SignInForm";
import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";
import { usePostMigrateAnonymousOrders } from "services/orders/orders.service.hooks";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { useFetchUser, usePostUser } from "services/user/user.service.hooks";
import SignUpUserForm from "components/signUpUser/SignUpUser/SignUpUserForm/SignUpUserForm";
import { usePostUserSettings } from "services/settings/settings.service.hooks";
import { ApiError } from "types/api.types";
import { SignUpUserValues } from "components/signUpUser/SignUpUser/SignUpUserForm/SignUpUserForm.types";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import { usePostShippingAddress } from "services/shippingAddress/shippingAddress.service.hooks";
import { usePostBillingData } from "services/billingData/billingData.service.hooks";
import useCountries from "contexts/countries/countries.hooks";
import { trimFields } from "utils/form.utils";
import { getPostShippingAddress } from "utils/common.utils";

import CloseSVG from "../../../../public/assets/images/close.svg";
import SignOptions from "../SignOptions/SignOptions";
import { BaseUser } from "artisn/types";

const { logSignIn, logSignUp } = events.auth;
const { ARTISN, STORAGE } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { ANONYMOUS_SHOPPING_CART_TOKEN } = STORAGE;

const SignInModal: React.FC<Props> = props => {
  const { mode: modeModal, title, onClose, ...rest } = props;
  const postMigrateAnonymousOrders = usePostMigrateAnonymousOrders();
  const { mutate: migrateAnonymousOrders } = postMigrateAnonymousOrders;
  const { transferAnonymousId, anonymousBillingData } = useCheckout();
  const { signInWithEmailAndPassword, isAnonymous = false } = useAuth();
  const { registerWithEmailAndPassword } = useAuth();
  const { data: user, isFetched } = useFetchUser();
  const [mode, setMode] = useState(modeModal);
  const postUser = usePostUser();
  const { mutateAsync: createUser, reset: resetCreateUser } = postUser;
  const { isLoading: isLoadingCreateUser } = postUser;
  const postUserSettings = usePostUserSettings();
  const { mutateAsync: createUserSettings } = postUserSettings;
  const { reset: resetCreateUserSettings } = postUserSettings;
  const { isLoading: isLoadingCreateUserSettings } = postUserSettings;
  const [apiError, setApiError] = useState<AxiosError<ApiError>>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { uid, email } = useAuth();
  const { selectedCountryId } = useCountries();
  const { selectedShippingAddress } = useShippingAddress();
  const postShippingAddress = usePostShippingAddress();
  const { mutateAsync: createShippingAddress } = postShippingAddress;
  const postBillingData = usePostBillingData();
  const { mutateAsync: createBillingData } = postBillingData;
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;

  const signIn = async (email: string, password: string) => {
    await signInWithEmailAndPassword(email, password);
    const { name, lastname } = user ?? {};
    logSignIn({
      method: "Email and Password",
      name: `${name} ${lastname}`
    });
  };

  const onSubmitSuccess = async (email: string, password: string) => {
    if (!uid) return;
    const anonymousCart = await getShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous,
      accountId: ACCOUNT_ID,
      customerId: uid
    });
    if (anonymousCart) {
      await Storage.set({
        key: ANONYMOUS_SHOPPING_CART_TOKEN,
        value: JSON.stringify(anonymousCart)
      });
    }
    if (mode === "signup") {
      await registerWithEmailAndPassword(email, password);
      onClose?.();
      return;
    }
    await signIn(email, password);
    // Sometimes when an anonymous user registers at the checkout the
    // registration might fail for any reasons, in those cases you will want
    // transfer the anonymous orders to the user who is signing in
    if (transferAnonymousId) migrateAnonymousOrders();
    onClose?.();
  };

  const onSubmitSignUpUser = async (formValues: SignUpUserValues) => {
    setApiError(undefined);
    setErrorMessage(undefined);
    resetCreateUser();
    resetCreateUserSettings();
    if (!uid || !email) {
      console.error("User unique identifier or email are undefined");
      return;
    }
    const { lastname, name, phone } = formValues;
    const completeUser: BaseUser = {
      uid,
      country: { id: selectedCountryId },
      email,
      name,
      lastname,
      phone
    };
    try {
      await createUser(completeUser);
      logSignUp({
        method: "Email and Password",
        name: `${name} ${lastname}`,
        email,
        identify: {
          id: uid,
          name: `${name} ${lastname}`,
          firstName: name,
          lastName: lastname,
          email
        }
      });
      if (selectedShippingAddress) {
        const newSelectedShippingAddress = trimFields(selectedShippingAddress);
        await createShippingAddress(
          getPostShippingAddress(newSelectedShippingAddress)
        );
      }
      if (anonymousBillingData) {
        const newAnonymousBillingData = trimFields(anonymousBillingData);
        await createBillingData(newAnonymousBillingData);
      }
    } catch (e) {
      setApiError(e);
      return;
    }
    try {
      if (!isMobile) await createUserSettings(uid);
      onClose?.();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const renderModal = () => {
    if (mode === "signup") {
      return (
        <>
          <SingleSignOn
            className="SignIn__sso"
            currentForm="signUp"
            onCloseModal={onClose}
            isModal
          />
          <div className="SignInModal__divider" />
          <SignInForm
            currentForm="signUp"
            buttonTitle="Crear cuenta"
            onSubmitSuccess={onSubmitSuccess}
            isFetched={isFetched}
          />
          <SignOptions
            title="¿Ya tienes cuenta?"
            buttonTitle="Inicia sesión"
            route="signin"
            isModal
            onChange={modal => setMode(modal)}
          />
        </>
      );
    }
    if (mode === "signin") {
      return (
        <>
          <SingleSignOn
            className="SignIn__sso"
            onCloseModal={onClose}
            isModal
          />
          <div className="SignInModal__divider" />
          <SignInForm onSubmitSuccess={onSubmitSuccess} />
          <SignOptions
            title="¿Aún no tienes cuenta?"
            buttonTitle="Crear cuenta"
            route="signup"
            isModal
            onChange={modal => setMode(modal)}
          />
        </>
      );
    }
    return (
      <SignUpUserForm
        onSubmitSuccess={onSubmitSignUpUser}
        isLoading={isLoadingCreateUser || isLoadingCreateUserSettings}
        apiError={apiError}
        errorMessage={errorMessage}
      />
    );
  };

  useEffect(() => {
    if (!modeModal) return;
    setMode(modeModal);
  }, [modeModal]);

  return (
    <Styles
      {...rest}
      className="SignInModal"
      closeIcon={mode !== "signup-user" ? <CloseSVG /> : null}
      closeOnClickOutside={false}
      onClose={onClose}
      blockScroll
    >
      <p className="SignInModal__title">Disfruta de nuestros beneficios</p>
      {renderModal()}
    </Styles>
  );
};

SignInModal.defaultProps = {};

export default SignInModal;
