import styled from "styled-components";

import { ModifierPricesStyledProps as Props } from "./ModifierPrices.types";

const ModifierPricesStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .ModifierPrices {
    &__sum {
      font-size: 1.6rem;
      color: var(--palette-black-s0-l20);
    }

    &__unitPrice {
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l50);
    }
  }
`;

export default ModifierPricesStyled;
