import styled from "styled-components";

import { AddToCartNotificationStyledProps as Props } from "./AddToCartNotification.types";
import Notification from "components/global/notifications/Notification/Notification";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

export const AddToCartNotificationStyled = styled(Notification)<Props>`
  display: grid;
  align-items: center;
  padding: 2rem 1.4rem;
  border-radius: 1rem;
  max-width: 40rem;

  @media (max-width: ${mobile}px) {
    padding: 1.2rem;
  }

  .AddToCartNotification {
    &__description {
      margin-bottom: 1.2rem;
      font-size: 3.2rem;
      color: var(--palette-black-s0-l20);
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
    }

    &__buttons {
      display: flex;
      margin-top: 3rem;
    }

    &__button {
      height: 5.6rem;
      padding: 0;
      font-weight: 600;
      font-size: 1.6rem;
      border-radius: 12rem;

      &.Button {
        margin-right: 1.6rem;
        padding: 0 3.4rem;
        border: none;
        background-color: var(--palette-secondary-s35-l95);
        color: var(--palette-primary);

        &:hover {
          border: none;
          background-color: var(--palette-secondary-s35-l95);
          color: var(--palette-primary);
          box-shadow: none;
        }
      }
    }
  }

  .CartPayButton {
    display: flex;
    flex-grow: 1;
  }

  .Cart__summary__button {
    flex-grow: 1;
    height: 5.6rem;
    padding: 0;
    font-weight: 600;
    font-size: 1.6rem;
    border-radius: 12rem;
  }
`;

export const AddToCartNotificationModifiable = styled(Notification)<Props>`
  display: grid;
  align-items: center;
  padding: 2rem 1.4rem !important;
  border-radius: 1rem;
  max-width: 40rem;

  @media (max-width: ${mobile}px) {
    padding: 1.2rem;
    padding-bottom: 3.2rem;
    padding-top: 4.8rem;

    && .Notification__close {
      top: 2.4rem;
      right: 2.4rem;
    }
  }

  .AddToCartNotification {
    &__description {
      margin-bottom: 1.2rem;
      font-size: 3.2rem;
      color: var(--palette-black-s0-l20);
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
    }

    &__divider {
      @media (max-width: ${mobile}px) {
        height: 0.1rem;
        background-color: var(--palette-lightgrey-s5-l90);
      }
    }

    &__buttons {
      display: flex;
      margin-top: 3rem;
    }

    &__button {
      height: 5.6rem;
      padding: 0;
      font-weight: 600;
      font-size: 1.6rem;
      border-radius: 0.8rem;

      &.Button {
        border-radius: 12rem;
        margin-right: 1.6rem;
        padding: 0 3.4rem;
        border: none;
        background-color: var(--palette-secondary-s35-l95);
        color: var(--palette-primary);

        @media (max-width: ${mobile}px) {
          padding: 0 1.6rem;
        }

        &:hover {
          border: none;
          background-color: var(--palette-secondary-s35-l95);
          color: var(--palette-primary);
          box-shadow: none;
        }

        span {
          white-space: nowrap;
        }
      }
    }
  }

  .CartPayButton {
    display: flex;
    flex-grow: 1;
  }

  .CartPayButton__total {
    &::before {
      content: "";
      width: 0.3rem;
      height: 0.3rem;
      background-color: var(--palette-white);
      border-radius: 100%;
      margin: 0 0.6rem;
    }
  }

  &&&& .Cart__summary__button {
    flex-grow: 1;
    height: 5.6rem;
    font-weight: 600;
    justify-content: center;
  }

  .Notification__close {
    @media (max-width: ${mobile}px) {
      top: 1.2rem;
      transform: initial;
    }
  }
`;
