import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { events } from "@artisan-commerce/analytics-capacitor";
import { getShoppingCart } from "artisn/shopping-cart";
import { Storage } from "@capacitor/storage";
import { Capacitor } from "@capacitor/core";
import { Device, DeviceInfo } from "@capacitor/device";

import Styles from "./SingleSignOn.styles";
import useAuth from "contexts/auth/auth.context.hooks";
import { SingleSignOnProps as Props } from "./SingleSignOn.types";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import CONSTANTS from "config/constants";
import { notify, sanitizeQueryParams } from "utils/common.utils";
import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";
import { usePostMigrateAnonymousOrders } from "services/orders/orders.service.hooks";

import AppleSVG from "../../../../public/assets/images/apple.svg";
import GoogleSVG from "../../../../public/assets/images/google-logo.svg";
import FacebookSVG from "../../../../public/assets/images/facebook-filled.svg";

const { logSignIn, logSignUp } = events.auth;
const { ARTISN, STORAGE } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { ANONYMOUS_SHOPPING_CART_TOKEN } = STORAGE;

const SingleSignOn: React.FC<Props> = props => {
  const { asPath, query, replace } = useRouter();
  const { currentForm = "signIn", className, isModal } = props;
  const { onCloseModal } = props;
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
  const authContext = useAuth();
  const { signInWithFacebook, signInWithGoogle, uid } = authContext;
  const { signInWithApple, isAnonymous = false } = authContext;
  const { redirect } = sanitizeQueryParams(query);
  const { transferAnonymousId } = useCheckout();
  const postMigrateAnonymousOrders = usePostMigrateAnonymousOrders();
  const { mutate: migrateAnonymousOrders } = postMigrateAnonymousOrders;
  const isIOS = Capacitor.getPlatform() === "ios";

  const getDeviceInfo = async () => {
    try {
      const deviceInfo = await Device.getInfo();
      setDeviceInfo(deviceInfo);
    } catch (error) {
      console.error("device information could not be obtained.");
    }
  };

  const handleRedirection = () => {
    const target = `${redirect ?? "/"}`;
    if (asPath === target) return;
    replace(target);
  };

  const setAnonymousCartStorage = async () => {
    if (!uid) return;
    const anonymousCart = await getShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous,
      accountId: ACCOUNT_ID,
      customerId: uid
    });
    if (!anonymousCart) return;
    await Storage.set({
      key: ANONYMOUS_SHOPPING_CART_TOKEN,
      value: JSON.stringify(anonymousCart)
    });
  };

  const signInWithGoogleHandler = async () => {
    try {
      setAnonymousCartStorage();
      const user = await signInWithGoogle();
      if (!user) throw new Error("No user was found with Google Provider");
      const { displayName, email } = user;

      if (currentForm === "signIn") {
        logSignIn({ method: "Google", name: displayName ?? "" });
        if (transferAnonymousId) migrateAnonymousOrders();
        if (isModal) {
          onCloseModal?.();
          return;
        }
        handleRedirection();
        return;
      }
      logSignUp({
        method: "Google",
        name: displayName ?? "",
        email: email ?? ""
      });
      if (transferAnonymousId) migrateAnonymousOrders();
      if (isModal) {
        onCloseModal?.();
        return;
      }
      handleRedirection();
    } catch (e) {
      notify(e, "Google Signin");
      dismissErrorNotification();
      createErrorNotification(
        "Prueba iniciar sesión nuevamente o intenta con otros proveedores."
      );
      console.error(e);
    }
  };

  const signInWithFacebookHandler = async () => {
    try {
      setAnonymousCartStorage();
      const user = await signInWithFacebook();
      if (!user) throw new Error("No user was found with Facebook Provider");
      const { displayName, email } = user;

      if (currentForm === "signIn") {
        logSignIn({ method: "Facebook", name: displayName ?? "" });
        if (transferAnonymousId) migrateAnonymousOrders();
        if (isModal) {
          onCloseModal?.();
          return;
        }
        handleRedirection();
        return;
      }
      logSignUp({
        method: "Facebook",
        name: displayName ?? "",
        email: email ?? ""
      });
      if (transferAnonymousId) migrateAnonymousOrders();
      if (isModal) {
        onCloseModal?.();
        return;
      }
      handleRedirection();
    } catch (e) {
      notify(e, "Facebook Signin");
      dismissErrorNotification();
      createErrorNotification(
        `Prueba iniciar sesión nuevamente o intenta con otros proveedores.`
      );
      console.error(e);
    }
  };

  const signInWithAppleHandler = async () => {
    try {
      setAnonymousCartStorage();
      const user = await signInWithApple();
      if (!user) throw new Error("No user was found with Apple Provider");
      const { displayName, email } = user;

      if (currentForm === "signIn") {
        logSignIn({ method: "Apple", name: displayName ?? "" });
        if (transferAnonymousId) migrateAnonymousOrders();
        if (isModal) {
          onCloseModal?.();
          return;
        }
        handleRedirection();
        return;
      }
      logSignUp({
        method: "Apple",
        name: displayName ?? "",
        email: email ?? ""
      });
      if (transferAnonymousId) migrateAnonymousOrders();
      if (isModal) {
        onCloseModal?.();
        return;
      }
      handleRedirection();
    } catch (e) {
      notify(e, "Apple Signin");
      dismissErrorNotification();
      createErrorNotification(
        "Prueba iniciar sesión nuevamente o intenta con otros proveedores."
      );
      console.error(e);
    }
  };

  useEffect(() => {
    getDeviceInfo();
  }, []);

  return (
    <Styles className={`SingleSignOn ${className}`}>
      <p className="SingleSignOn__title">
        {currentForm === "signIn"
          ? "Ingresa utilizando tus redes"
          : "Crea tu cuenta utilizando..."}
      </p>
      <div className="SingleSignOn__icons">
        <div
          className="SingleSignOn__google SingleSignOn__button"
          onClick={signInWithGoogleHandler}
        >
          <GoogleSVG viewBox="0 0 512 512" />
          <p className="SingleSignOn__button__text">
            {currentForm === "signIn"
              ? "Iniciar sesión con Google"
              : "Regístrate con Google"}
          </p>
        </div>
        <div
          className="SingleSignOn__facebook SingleSignOn__button"
          onClick={signInWithFacebookHandler}
        >
          <FacebookSVG viewBox="0 0 21 20" />
          <p className="SingleSignOn__button__text">
            {currentForm === "signIn"
              ? "Iniciar sesión con Facebook"
              : "Regístrate con Facebook"}
          </p>
        </div>
        {isIOS && deviceInfo && deviceInfo.osVersion >= "13" ? (
          <div
            className="SingleSignOn__apple SingleSignOn__button"
            onClick={signInWithAppleHandler}
          >
            <AppleSVG />
            <p className="SingleSignOn__button__text">
              {currentForm === "signIn"
                ? "Iniciar sesión con Apple"
                : "Regístrate con Apple"}
            </p>
          </div>
        ) : null}
      </div>
    </Styles>
  );
};

SingleSignOn.defaultProps = {
  className: ""
};

export default SingleSignOn;
