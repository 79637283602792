import styled from "styled-components";
import { ShoppingCartSummary } from "artisn-ui-react";

import { ShoppingCartSummaryStyledProps as Props } from "./ShoppingCartSummary.types";

const ShoppingCartSummaryStyled = styled(ShoppingCartSummary)<Props>`
  .ShoppingCartSummary {
    &__title {
      display: none;
    }
  }

  .ShoppingCartSummaryShippingCost__value {
    color: var(--palette-primary);
  }

  p {
    font-weight: 400;
    font-size: 1.4rem;
  }

  .ShoppingCartSummaryTotal__title,
  div {
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

export default ShoppingCartSummaryStyled;
