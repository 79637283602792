import styled from "styled-components";

import { ProductBaseInfoStyledProps as Props } from "./ProductBaseInfo.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ProductBaseInfoStyled = styled.div<Props>`
  border-bottom: 0.1rem solid var(--palette-lightgrey-s5-l90);

  .ProductBaseInfo {
    &__name {
      padding: 0 1.6rem;
      margin-bottom: 1.6rem;
      font-size: 2.4rem;
      font-weight: 800;
      color: var(--palette-gray-s0-l20);
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: ${tablet}px) {
        white-space: normal;
      }
    }

    &__description {
      max-width: 32.8rem;
      margin-bottom: 1.4rem;
      padding: 0 1.6rem;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: var(--palette-gray-s0-l50);
    }

    &__prices {
      margin-bottom: 1.6rem;
      padding: 0 1.6rem;
      font-size: 1.6rem;
      font-weight: 800;
      line-height: 2rem;
    }
  }
`;

export default ProductBaseInfoStyled;
