import { useContext } from "react";

import { ShoppingCartNotificationsContext } from "contexts/shoppingCartNotifications/shoppingCartNotifications.context";
import { ShoppingCartNotificationsProviderValue } from "contexts/shoppingCartNotifications/shoppingCartNotifications.context.types";

const useShoppingCartNotifications = () => {
  const context = useContext<ShoppingCartNotificationsProviderValue>(
    ShoppingCartNotificationsContext
  );

  if (typeof context === "undefined") {
    throw new Error(
      "useShoppingCartNotifications must be used within a ShoppingCartNotificationsProvider"
    );
  }
  return context;
};

export default useShoppingCartNotifications;
