import { Capacitor } from "@capacitor/core";

// Settings Service helper functions and data
const isIOS = Capacitor.getPlatform() === "ios";
const isAndroid = Capacitor.getPlatform() === "android";
const isMobile = isIOS || isAndroid;

export const defaultSettings = {
  emailNotifications: true,
  pushNotifications: isMobile
};

export const ElHorneroTopics = {
  GENERAL: "ELHORNERO_EC"
} as const;
