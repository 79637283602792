import { useContext } from "react";

import { ShoppingCartContext } from "contexts/shoppingCart/shoppingCart.context";
import { ShoppingCartProviderValue } from "contexts/shoppingCart/shoppingCart.context.types";

export const useShoppingCart = () => {
  const context = useContext<ShoppingCartProviderValue>(ShoppingCartContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useShoppingCart must be used within a ShoppingCartProvider"
    );
  }
  return context;
};
