import React from "react";
import { TextInput } from "artisn-ui-react";

import Styles from "./RedeemCouponInput.styles";
import Button from "components/global/Button/Button";
import { RedeemCouponInputProps as Props } from "./RedeemCouponInput.types";

const RedeemCouponInput: React.FC<Props> = props => {
  const { className, disabled, couponCode, onRedeem, setCouponCode } = props;

  return (
    <Styles className={`RedeemCouponInput ${className}`}>
      <TextInput
        className="RedeemCouponInput__text-input field"
        label="¿Tienes un cupón?"
        name="redeem"
        disabled={disabled}
        value={couponCode}
        onChange={e => setCouponCode(e.target.value.trim())}
        placeholder="Ingresa tu código aquí…"
        maxLength={50}
      />
      <Button
        className="RedeemCouponInput__input-button"
        type="LINK"
        disabled={disabled}
        onClick={() => onRedeem(couponCode)}
      >
        Agregar
      </Button>
    </Styles>
  );
};

RedeemCouponInput.defaultProps = {
  className: ""
};

export default RedeemCouponInput;
