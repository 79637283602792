import React, { useEffect, useMemo, useRef, useState } from "react";
import { ShareButton, Clickable } from "artisn-ui-react";
import { getShoppingCartProducts, removeBenefit } from "artisn/shopping-cart";
import { emptyShoppingCart } from "artisn/shopping-cart";
import { ValidateShoppingCartResult } from "artisn/shopping-cart";
import { events } from "@artisan-commerce/analytics-capacitor";
import { Storage } from "@capacitor/storage";

import Styles from "./ShoppingCartDrawer.styles";
import { ShoppingCartDrawerProps as Props } from "./ShoppingCartDrawer.types";
import RedeemCoupon from "components/Cart/RedeemCoupon/RedeemCoupon";
import CONSTANTS from "config/constants";
import useShippingCost from "hooks/useShippingCost";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import Button from "components/global/Button/Button";
import { getFullPath, notify } from "utils/common.utils";
import useStores from "contexts/stores/stores.context.hooks";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import { usePutCancelOrder } from "services/orders/orders.service.hooks";
import ShoppingCartSummary from "components/checkout/ShoppingCartSummary/ShoppingCartSummary";
import useAuth from "contexts/auth/auth.context.hooks";
import EmptyCart from "components/Cart/EmptyCart/EmptyCart";
import CouponDetail from "components/coupons/CouponDetail/CouponDetail";
import CartProducts from "components/Cart/CartProducts/CartProducts";
import CartPayButton from "components/Cart/CartPayButton/CartPayButton";
import useRequestNavigatorGeo from "hooks/useRequestNavigatorGeo";
import { useFetchRecommendedProducts } from "services/products/products.service.hooks";
import UpsaleModal from "components/global/UpsaleModal/UpsaleModal";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import { fetchRebuildCart } from "services/orders/orders.service";
import { fetchIncompleteOrders } from "services/orders/orders.service";
import { OrderDetails } from "artisn/types";
import useVendors from "contexts/vendors/vendors.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";

import CloseSVG from "../../../../../public/assets/images/close.svg";
import StoreSVG from "../../../../../public/assets/images/store.svg";
import CouponSVG from "../../../../../public/assets/images/coupon.svg";
import CloseCirlceSVG from "../../../../../public/assets/images/close-circle.svg";

const { ARTISN, FEATURE_FLAGS, STORAGE, API } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { logClearCart } = events.shoppingCart;
const { logViewCart } = events.shoppingCart;
const { WITH_COUPONS, WITH_SHARE_SHOPPING_CART } = FEATURE_FLAGS;
const { WITH_UPSALE_MODAL, WITH_PURCHASE, WITH_TALK_SHOP } = FEATURE_FLAGS;
const { PENDING_ORDER_TOKEN } = STORAGE;
const { API_URL } = API;

const ShoppingCartDrawer: React.FC<Props> = props => {
  const { onClose, opened } = props;
  const [errors, setErrors] = useState<ValidateShoppingCartResult>();
  const [disabled, setDisabled] = useState(false);
  const { shoppingCart, setTemporalBenefit } = useShoppingCart();

  const { analyticsInitialized } = useAnalytics();
  const viewCartLogged = useRef(false);
  const [removeBenefitError, setRemoveBenefitError] = useState("");
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const { uid, isAnonymous = false } = useAuth();

  const { selectedStore } = useStores();
  const { storeId } = selectedStore ?? {};
  const { mutateAsync: cancelOrder } = usePutCancelOrder();
  const empty = (products && products.length === 0) || !shoppingCart;
  useRequestNavigatorGeo();
  const { id: shoppingCartId, benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const shippingCost = useShippingCost();
  const { data: recommendedProducts } = useFetchRecommendedProducts();
  const [openUpsale, setOpenUpsale] = useState(WITH_UPSALE_MODAL);
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = !!talkShopProvider && !!talkShopIdentifier && WITH_TALK_SHOP;
  const { selectedVendorId } = useVendors();
  const { selectedCatalogueId } = useCatalogues();
  const [showCouponInfo, setShowCouponInfo] = useState(isAnonymous);

  const closeHandler = () => {
    onClose?.();
    setOpenUpsale(true);
  };

  const removeHandler = async () => {
    if (!shoppingCartId || !uid) return;

    try {
      setRemoveBenefitError("");
      const { benefitId } = selectedBenefit ?? {};

      const product = products?.find(
        product => product.benefitId === benefitId
      );

      await removeBenefit({
        benefitId: benefitId!,
        shippingCost,
        product,
        productConfig: {
          store: selectedStore!,
          accountId: ACCOUNT_ID,
          anonymous: isAnonymous,
          customerId: uid
        },
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous,
        apiURL: API_URL,
        accountId: ACCOUNT_ID,
        customerId: uid
      });
      setTemporalBenefit(undefined);
    } catch (e) {
      notify(e, "Remove benefit");
      setRemoveBenefitError(e.message);
    }
  };

  const emptyCartHandler = () => {
    if (!shoppingCartId || !uid) return;
    logClearCart({
      cartId: shoppingCartId
    });
    emptyShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous,
      accountId: ACCOUNT_ID,
      customerId: uid
    });
    setTemporalBenefit(undefined);
  };

  useEffect(() => {
    if (!storeId) return;
    if (!products?.length || errors) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [errors, products?.length, storeId]);

  useEffect(() => {
    if (
      !shoppingCartId ||
      viewCartLogged.current ||
      !analyticsInitialized ||
      !products?.length ||
      !opened
    ) {
      return;
    }
    const newProducts = products.map(product => {
      const {
        // @ts-ignore
        store: { latitude, longitude, location, ...rest }
      } = product;
      return { ...product, store: rest };
    });
    logViewCart({
      cartId: shoppingCartId,
      products: newProducts
    });
    viewCartLogged.current = true;
  }, [analyticsInitialized, products, shoppingCartId, opened]);

  useEffect(() => {
    if (!uid || !opened) return;
    return () => {
      (async () => {
        try {
          const { value } = await Storage.get({
            key: `${PENDING_ORDER_TOKEN}${uid}`
          });
          const orderStorage: OrderDetails = value ? JSON.parse(value) : {};
          const { id } = orderStorage ?? {};
          const [incompleteOrder] = await fetchIncompleteOrders(
            selectedCatalogueId,
            selectedVendorId
          );
          const { id: orderId } = incompleteOrder ?? {};
          if (!orderId || orderId === id) return;
          await cancelOrder(orderId);
          await fetchRebuildCart(orderId, shoppingCartId);
        } catch (e) {
          console.error(e.message);
        }
      })();
    };
  }, [
    cancelOrder,
    selectedCatalogueId,
    selectedVendorId,
    shoppingCartId,
    uid,
    opened
  ]);

  useEffect(() => {
    if (!isAnonymous) return;
    setShowCouponInfo(isAnonymous);
  }, [isAnonymous]);

  return (
    <Styles
      className="ShoppingCartDrawer"
      opened={opened}
      onClose={closeHandler}
      backdropConfig={{ className: "ShoppingCartDrawerBackdrop" }}
      talkshop={talkShop}
    >
      {onClose ? (
        <CloseSVG
          className="ShoppingCartDrawer__close"
          onClick={closeHandler}
        />
      ) : null}
      <div className="ShoppingCartDrawer__title">
        Carrito
        {WITH_SHARE_SHOPPING_CART && !empty ? (
          <ShareButton
            className="ShoppingCartDrawer__share"
            config={{
              title: `Compartir carrito`,
              text: `Comparte tu carrito con otra persona`,
              url: `${getFullPath()}?shareId=${uid}`
            }}
          />
        ) : null}
      </div>
      {!empty ? (
        <div className="ShoppingCartDrawer__store">
          <StoreSVG />
          {selectedStore?.storeName}
        </div>
      ) : null}
      {empty ? (
        <EmptyCart drawerCloseHandler={closeHandler} />
      ) : (
        <>
          <div className="ShoppingCartDrawer__cart">
            {WITH_COUPONS && !isAnonymous && !talkShop ? (
              <RedeemCoupon className="ShoppingCartDrawer__coupon" />
            ) : null}
            {WITH_COUPONS && showCouponInfo && !talkShop && isAnonymous ? (
              <div className="ShoppingCartDrawer__coupon-anonymous">
                <CouponSVG className="ShoppingCartDrawer__coupon-anonymous__svg" />
                <p className="ShoppingCartDrawer__coupon-anonymous__title">
                  Regístrate para obtener beneficios
                </p>
                <div className="ShoppingCartDrawer__coupon-anonymous__description">
                  Al registrarte podrás hacer uso de nuestros cupones y
                  obtendrás muchos beneficios.
                </div>
                <Clickable
                  className="ShoppingCartDrawer__coupon-anonymous__close"
                  onClick={() => setShowCouponInfo(false)}
                >
                  <CloseCirlceSVG />
                </Clickable>
              </div>
            ) : null}
            {selectedBenefit && !talkShop ? (
              <CouponDetail
                inCart
                removeError={removeBenefitError}
                benefit={selectedBenefit}
                onRemove={removeHandler}
              />
            ) : null}
            <CartProducts disabled={disabled} />
            <Button
              type="LINK"
              className="ShoppingCartDrawer__empty-button"
              onClick={emptyCartHandler}
            >
              Vaciar carrito
            </Button>
            <ShoppingCartSummary className="ShoppingCartDrawer__summary" />
          </div>
          <div className="ShoppingCartDrawer__pay-button">
            <CartPayButton
              empty={empty}
              setDisabled={setDisabled}
              shoppingCart={shoppingCart}
              setErrors={setErrors}
              talkShop={talkShop}
              isShoppingCartDrawer={true}
            />
          </div>
        </>
      )}
      {recommendedProducts?.length && WITH_PURCHASE && WITH_UPSALE_MODAL ? (
        <UpsaleModal
          opened={openUpsale}
          onSuccess={() => setOpenUpsale(false)}
          products={recommendedProducts}
          onClose={() => setOpenUpsale(false)}
        />
      ) : null}
    </Styles>
  );
};

ShoppingCartDrawer.defaultProps = {};

export default ShoppingCartDrawer;
