import React from "react";

import Styles from "./CounterModifier.styles";
import { CounterModifierProps as Props } from "./CounterModifier.types";
import Counter from "components/global/Counter/Counter";
import ModifierPrices from "../ModifierPrices/ModifierPrices";

const CounterModifier: React.FC<Props> = props => {
  const { modifier, maxDisabled = false, disabled = false } = props;
  const { amount, name, max, min, totals } = modifier;
  const { handleChange } = modifier;
  const totalDisabled = maxDisabled && !amount;

  const activeClassName = amount ? "CounterModifier__title--active" : "";

  return (
    <Styles className="CounterModifier">
      <div className="CounterModifier__info">
        <p className={`CounterModifier__title ${activeClassName}`}>{name}</p>
        <ModifierPrices totals={totals} />
      </div>
      <Counter
        className="CounterModifier__counter"
        initialValue={amount ?? min}
        max={max}
        min={min}
        onChange={handleChange}
        disabled={disabled ?? totalDisabled}
        maxDisabled={maxDisabled}
      />
    </Styles>
  );
};

CounterModifier.defaultProps = {};

export default CounterModifier;
