import styled from "styled-components";

import { PasswordStrengthLinesStyledProps as Props } from "./PasswordStrengthLines.types";

const PasswordStrengthLinesStyled = styled.div<Props>`
  display: flex;
  width: 50%;

  .PasswordStrengthLines {
    &__check {
      height: 0.4rem;
      width: 100%;
      border-radius: 1.6rem;

      &:before {
        background-color: var(--palette-gray-s0-l95);
        position: absolute;
        transform: scale(0);
      }

      &:not(:first-child) {
        margin-left: 0.8rem;
      }
    }

    &--weak {
      transform: scale(1);
      background-color: var(--palette-red-s60-l60);
    }

    &--medium {
      transform: scale(1);
      background-color: var(--palette-yellow-s100-l55);
    }

    &--strong {
      transform: scale(1);
      background-color: var(--palette-green-s63-l42);
    }
  }
`;

export default PasswordStrengthLinesStyled;
