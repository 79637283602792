// AddToCartButton helper functions and data
import { CurrencyCodes, Store } from "artisn/types";

import { AddToCartConfig } from "./AddToCartButton.types";
import { eventParamsToSnakeCase, getAnalitycsMeta } from "utils/global.utils";
import { googleProvider } from "config/artisn.config";

export const defaultConfig: AddToCartConfig = {
  amount: 1,
  comment: ""
};

export const addToCartField = ["amount", "questionAndAnswers"];

export const getTransformedParams = (
  userUid: string | undefined,
  currency: CurrencyCodes,
  selectedStore: Store,
  cartId: string,
  cartName: string,
  contentType: string
) => {
  const provider = googleProvider;
  const { trackingId, userId: providerUserId } = provider;
  const { currency: providerCurrency } = provider;
  const selectedUserId = userUid ?? providerUserId;
  const selectedCurrency = currency ?? providerCurrency;

  const { storeName, storeId } = selectedStore;
  const enhancedParams = {
    cartId,
    cartName,
    userId: selectedUserId,
    storeId,
    storeName,
    currency: selectedCurrency,
    contentType,
    send_to: trackingId,
    ...getAnalitycsMeta()
  };
  const transformedParams = eventParamsToSnakeCase(enhancedParams);
  return transformedParams;
};
