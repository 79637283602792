import CONSTANTS from "config/constants";
import styled from "styled-components";

import { ShippingAddressModalStyledProps as Props } from "./ShippingAddressModal.types";

const { tablet } = CONSTANTS.BREAKPOINTS;

const ShippingAddressModalStyled = styled.div<Props>`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ShippingAddressModal {
    &__header {
      padding: 2rem;
      padding-top: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: ${tablet}px) {
        padding-top: 6.4rem;
      }
    }

    &__title {
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 2.4rem;
      color: var(--palette-black-s0-l10);

      @media (max-width: ${tablet}px) {
        line-height: 4rem;
      }
    }

    &__wrapper {
      display: flex;
      padding: 0.8rem 1.6rem;
      flex: 1;
      flex-direction: column;
    }

    &__list {
      padding-top: 3.2rem;
    }
  }
`;

export default ShippingAddressModalStyled;
