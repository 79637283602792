import styled from "styled-components";

import { DropdownOptionsStyledProps as Props } from "./DropdownOptions.types";
import { DropdownOverlayStyledProps as OverlayProps } from "./DropdownOptions.types";

const DropdownOptionsStyled = styled.div<Props>`
  display: flex;
  align-items: center;

  .DropdownOptions {
  }
`;

export const DropdownOverlayStyled = styled.div<OverlayProps>`
  display: flex;
  flex-direction: column;
  width: max-content;
  border: 0.1rem solid var(--palette-gray-s0-l95);
  border-radius: 0.8rem;
  background: var(--palette-white);
  position: ${props => (props.isFixed ? "fixed" : "static")};

  .DropdownOverlay {
    &__option {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
      border: none;
      justify-content: flex-start;
    }
  }
`;

export default DropdownOptionsStyled;
