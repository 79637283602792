import React, { useState, createContext, useMemo } from "react";
import { Store } from "artisn/types";
import { ContextDevTool } from "react-context-devtool";

import { StoresProviderProps as Props } from "./stores.context.types";
import { StoresProviderValue } from "./stores.context.types";

// @ts-ignore
export const StoresContext = createContext<StoresProviderValue>({});

const StoresProvider: React.FC<Props> = props => {
  const [defaultStore, setDefaultStore] = useState<Store>();
  const [noCoverage, setNoCoverage] = useState(false);
  const [selectedStore, setSelectedStore] = useState<Store>();
  const [showStoreNotification, setShowStoreNotification] = useState(false);

  const resetStoresContext = () => {
    setNoCoverage(false);
    setSelectedStore(undefined);
  };

  const value: StoresProviderValue = useMemo(() => {
    return {
      defaultStore,
      setDefaultStore,
      selectedStore,
      setSelectedStore,
      noCoverage,
      setNoCoverage,
      resetStoresContext,
      showStoreNotification,
      setShowStoreNotification
    };
  }, [defaultStore, selectedStore, noCoverage, showStoreNotification]);

  return (
    <StoresContext.Provider value={value}>
      <ContextDevTool
        context={StoresContext}
        id="stores"
        displayName="Stores"
      />
      {props.children}
    </StoresContext.Provider>
  );
};

export default StoresProvider;
