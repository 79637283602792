import styled from "styled-components";

import { RadioButtonModifierStyledProps as Props } from "./RadioButtonModifier.types";

const RadioButtonModifierStyled = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  background-color: var(--palette-gray-s0-l98);

  &.RadioButtonModifier .Button {
    box-shadow: none;
    padding: 0;
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    background-color: transparent;

    :hover {
      box-shadow: none;
    }
  }

  .RadioButtonModifier {
    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__button {
      display: flex;
      border: none;
      align-items: center;
      background-color: var(--palette-white);
    }

    &__icon {
      width: 2.4rem;
      height: 2.4rem;
      border: 0.1rem solid var(--palette-gray-s0-l80);
      border-radius: 10rem;

      &--active {
        border: 0.7rem solid
          ${props =>
            props.disabled
              ? "var(--palette-gray-s0-l80)"
              : "var(--palette-primary)"};
      }
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 800;
      color: ${props =>
        props.disabled
          ? "var(--palette-gray-s0-l70-a48)"
          : "var(--palette-black-s0-l10)"};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &--active {
        font-weight: 600;
      }
    }
  }

  .ModifierPrices {
    margin: 0;
    display: inline-block;

    &__unitPrice {
      display: none;
    }
  }
`;

export default RadioButtonModifierStyled;
