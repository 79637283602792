import { useCallback, useMemo, useState } from "react";
import { events } from "@artisan-commerce/analytics-capacitor";
import { createShoppingCart, getShoppingCart } from "artisn/shopping-cart";
import { addProduct, replaceProduct } from "artisn/shopping-cart";

import useStores from "contexts/stores/stores.context.hooks";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import useShippingCost from "hooks/useShippingCost";
import useGeo from "contexts/geo/geo.hooks";
import CONSTANTS from "config/constants";
import { UseAddToCartProps } from "./AddToCartButton.types";
import { addToCartField, defaultConfig } from "./AddToCartButton.helpers";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import useProducts from "contexts/products/products.context.hooks";
import { saveProductPreference } from "utils/product.utils";
import { getBenefitProductId } from "utils/common.utils";
import { useFetchUser } from "services/user/user.service.hooks";

const { CONTENT_TYPE, SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } =
  CONSTANTS.ARTISN;
const { logAddProductToCart, logUpdateProductInCart } = events.shoppingCart;

export const useAddToCart = (props: UseAddToCartProps) => {
  const { form, onFinish, config = defaultConfig, onError } = props;
  const { selectedStore } = useStores();
  const { selectedProduct, setSelectedProduct } = useProducts();
  const { shoppingCart, temporalBenefit } = useShoppingCart();
  const { selectedCatalogueId } = useCatalogues();
  const shippingCost = useShippingCost();
  const { amount, comment = "" } = config;
  const { selectedCoordinates } = useGeo();
  const [isAdding, setIsAdding] = useState(false);
  const { isAnonymous = false, uid } = useAuth();
  const { data: user } = useFetchUser();
  const { uid: userUid } = user ?? {};

  const shouldReplace = !!selectedProduct;
  const { product, validate } = form ?? {};
  const { lat, lng } = selectedCoordinates ?? {};

  const onClick = useCallback(async () => {
    if (!uid) return;
    setIsAdding(true);

    if (!product || !validate) {
      setIsAdding(false);
      return;
    }

    const valid = validate() === "OK";
    if (!valid) {
      setIsAdding(false);
      onError?.();
      return;
    }

    if (shouldReplace) {
      const { id, name, benefits } =
        (await getShoppingCart({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous,
          accountId: ACCOUNT_ID,
          customerId: uid
        })) ?? {};

      const selectedBenefit = benefits ? benefits[0] : undefined;
      const benefitProductId = getBenefitProductId(selectedBenefit);
      /* If the product being modified is not equal to the benefitId in the
        cart, replace it and continue normally. If not, do nothing. */
      if (benefitProductId !== product.productId) {
        await replaceProduct(product, {
          amount,
          comment,
          store: selectedStore!,
          shoppingCartName: name,
          anonymous: isAnonymous,
          accountId: ACCOUNT_ID,
          customerId: uid
        });
        saveProductPreference(form, userUid);
        setSelectedProduct(undefined);

        if (selectedStore && id && name) {
          logUpdateProductInCart({
            cartId: id,
            cartName: name,
            product,
            store: selectedStore,
            fields: addToCartField,
            contentType: CONTENT_TYPE
          });
        }
      }
    } else {
      if (!shoppingCart) {
        await createShoppingCart(
          {
            anonymous: isAnonymous,
            accountId: ACCOUNT_ID,
            customerId: uid
          },
          {
            channelId: +selectedCatalogueId,
            shippingCost,
            latitude: lat ?? 0,
            longitude: lng ?? 0,
            name: SHOPPING_CART_DEFAULT_NAME
          }
        );
      }
      const { id, name } =
        (await getShoppingCart({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous,
          accountId: ACCOUNT_ID,
          customerId: uid
        })) ?? {};

      /* If a benefit of type PRODUCT exists in context, prevent addProduct
        from firing up. If this condition is removed, a benefit product is added
        first and another product is added to the cart too, removing the
        benefitId key from the product and breaking the coupons functionality.
      */
      if (!temporalBenefit) {
        await addProduct(product, {
          amount,
          store: selectedStore!,
          comment,
          shoppingCartName: name,
          anonymous: isAnonymous,
          accountId: ACCOUNT_ID,
          customerId: uid
        });
        saveProductPreference(form, userUid);
      }

      /* The add product to cart event is kept for coupons because in the
      background, the applyBenefit function runs addProduct too. */
      if (selectedStore && id && name) {
        logAddProductToCart({
          cartId: id,
          cartName: name,
          product,
          store: selectedStore,
          contentType: CONTENT_TYPE
        });
      }
    }

    await onFinish?.();

    setIsAdding(false);
  }, [
    uid,
    product,
    validate,
    shouldReplace,
    onFinish,
    onError,
    isAnonymous,
    amount,
    comment,
    selectedStore,
    form,
    userUid,
    setSelectedProduct,
    shoppingCart,
    temporalBenefit,
    selectedCatalogueId,
    shippingCost,
    lat,
    lng
  ]);

  return useMemo(() => {
    return { shouldReplace, onClick, isAdding };
  }, [isAdding, onClick, shouldReplace]);
};
