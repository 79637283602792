import React, { useMemo } from "react";
import { formatByCurrency } from "artisn-ui-react";
import { validateShoppingCart } from "artisn/shopping-cart";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { useRouter } from "next/router";

import Styles from "./CartPayButton.styles";
import { CartPayButtonProps as Props } from "./CartPayButton.types";
import Button from "components/global/Button/Button";
import useGeo from "contexts/geo/geo.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import { defaultFunction, shouldMock } from "utils/common.utils";
import CONSTANTS from "config/constants";
import { usePostTalkShop } from "services/talkShop/talkShop/talkShop.service.hooks";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import useCountries from "contexts/countries/countries.hooks";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import { getCartMessage } from "./CartPayButton.helpers";
import { dismissAddToCartNotification } from "utils/notifications.utils";
import { currencyOptionsDefault } from "components/categories/FiltersAccordion/FilterAccordion.helpers";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import useStores from "contexts/stores/stores.context.hooks";
import { getMissingProduct } from "../ShoppingCartProduct/ShoppingCartProduct.helpers";
import useShoppingCartNotifications from "contexts/shoppingCartNotifications/shoppingCartNotifications.context.hooks";
import { buildArtisnHeaders } from "utils/services.utils";

const { ARTISN } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { PHONE_NUMBER, RETURN_TO_APP_URL } = CONSTANTS.INTEGRATIONS.WHATSAPP;
const { WITH_PURCHASE, WITH_DELIVERY } = CONSTANTS.FEATURE_FLAGS;
const { API_URL } = CONSTANTS.API;

const CartPayButton: React.FC<Props> = props => {
  const { empty, shoppingCart, setDisabled = defaultFunction } = props;
  const { setErrors = defaultFunction, className, talkShop } = props;
  const { isShoppingCartDrawer = false } = props;
  const { selectedCoordinates } = useGeo();
  const { uid, isAnonymous = false } = useAuth();
  const { setShowNoCoverage, setClosedStore: setShowStoreNotification } =
    useShoppingCartNotifications();
  const { setShowMapCoordinates } = useShoppingCartNotifications();
  const router = useRouter();
  const { mutate, isLoading } = usePostTalkShop();
  const cartProducts = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const { talkShopIdentifier } = useTalkShop();
  const { selectedCountry } = useCountries();
  const { showModifiers, setOpenShoppingCartDrawer } = useShoppingCart();
  const { currency } = selectedCountry;
  const { selectedStore } = useStores();
  const totals = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartTotal(shoppingCart);
  }, [shoppingCart]);
  const { minOrderAmount = 0, maxOrderAmount = 0 } = selectedStore ?? {};
  const { total = 0 } = totals ?? {};
  const textButton = isShoppingCartDrawer ? "Ir a pagar" : "Pagar";

  const payButtonHandler = async () => {
    if (minOrderAmount > 0 && minOrderAmount > total) {
      dismissAddToCartNotification();
      setOpenShoppingCartDrawer(true);
      setTimeout(() => {
        dismissErrorNotification();
        createErrorNotification(
          `Agrega al menos $${minOrderAmount} para realizar tu pedido`,
          "Tu pedido no cumple con el monto mínimo"
        );
      }, 500);
      return;
    }

    if (maxOrderAmount > 0 && maxOrderAmount < total) {
      dismissAddToCartNotification();
      setOpenShoppingCartDrawer(true);
      setTimeout(() => {
        dismissErrorNotification();
        createErrorNotification(
          `Agrega artículos hasta un valor de $${maxOrderAmount} para poder hacer tu pedido`,
          "Tu pedido excede el monto máximo"
        );
      }, 500);
      return;
    }

    if (!selectedCoordinates) {
      setShowMapCoordinates(true);
      return;
    }
    if (!shoppingCart || !uid) return;

    if (!Object.keys(shoppingCart.stores).length) return;

    const { lat, lng } = selectedCoordinates;
    const validate = !shouldMock ? validateShoppingCart : () => undefined;

    const headers = await buildArtisnHeaders();

    const errors = await validate(
      {
        apiURL: API_URL,
        latitude: lat,
        longitude: lng,
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous,
        accountId: ACCOUNT_ID,
        customerId: uid
      },
      headers
    );
    const { products, stores } = errors ?? {};

    stores?.forEach(store => {
      const { type } = store;
      if (type === "IS_OPEN" && stores.length === 1) {
        setShowStoreNotification(true);
      }
      if (type === "OUT_OF_COVERAGE" && WITH_DELIVERY) {
        setShowNoCoverage(true);
      }
    });
    const validationErrors = stores?.filter(store => {
      if (!WITH_DELIVERY) {
        return !(store.type === "OUT_OF_COVERAGE");
      }
      return store;
    });

    if (validationErrors?.length) return;

    const hasErrors = !!products?.length;
    if (hasErrors && !shouldMock) {
      setDisabled(true);
      if (!isShoppingCartDrawer) {
        dismissAddToCartNotification();
        setOpenShoppingCartDrawer(true);
        return;
      }
      dismissErrorNotification();
      createErrorNotification(
        getMissingProduct(products!) || "Hubo un error en los productos"
      );
      setErrors(errors);
      return;
    }
    const talkShopData = {
      to: `whatsapp:+${talkShopIdentifier.trim()}`,
      from: `whatsapp:+${PHONE_NUMBER}`,
      body: `${getCartMessage(
        cartProducts,
        showModifiers,
        shoppingCart,
        selectedCountry
      )}`
    };

    if (talkShop) {
      mutate(talkShopData, {
        onSuccess: () => {
          router.push(RETURN_TO_APP_URL);
        },
        onError: e => {
          console.error(e.message);
        }
      });
      return;
    }
    setOpenShoppingCartDrawer(false);
    dismissAddToCartNotification();
    router.push("/checkout?redirect=false");
  };

  return (
    <Styles
      className={`CartPayButton ${className}`}
      talkShop={!!talkShop}
      isShoppingCartDrawer={isShoppingCartDrawer}
    >
      <Button
        onClick={payButtonHandler}
        disabled={empty || isLoading || !WITH_PURCHASE}
        className="Cart__summary__button"
      >
        {talkShop ? (
          <p className="CartPayButton__amount">{cartProducts?.length}</p>
        ) : null}
        {talkShop ? "Ver carrito" : textButton}
        <p className="CartPayButton__total">
          {totals
            ? `${formatByCurrency(
                totals.total || 0,
                currencyOptionsDefault(currency, 2)
              )}`
            : "$0.00"}
        </p>
      </Button>
    </Styles>
  );
};

CartPayButton.defaultProps = {
  className: ""
};

export default CartPayButton;
