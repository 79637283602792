import React, { useState, createContext, useMemo } from "react";
import { ContextDevTool } from "react-context-devtool";

import { Google } from "types/geo.types";
import { GeoProviderValue } from "./geo.context.types";
import { GeoProviderProps as Props } from "./geo.context.types";

// @ts-ignore
export const GeoContext = createContext<GeoProviderValue>({});

const GeoProvider: React.FC<Props> = props => {
  const [deviceCoordinates, setDeviceCoordinates] =
    useState<Google.Coordinates>();
  const [deviceCoordinatesError, setDeviceCoordinatesError] = useState("");
  const [selectedCoordinates, setSelectedCoordinates] =
    useState<Google.Coordinates>();

  const resetGeoContext = () => {
    setDeviceCoordinatesError("");
    setDeviceCoordinates(undefined);
    setSelectedCoordinates(undefined);
  };

  const value: GeoProviderValue = useMemo(() => {
    return {
      deviceCoordinates,
      setDeviceCoordinates,
      selectedCoordinates,
      setSelectedCoordinates,
      deviceCoordinatesError,
      setDeviceCoordinatesError,
      resetGeoContext
    };
  }, [deviceCoordinates, deviceCoordinatesError, selectedCoordinates]);

  return (
    <GeoContext.Provider value={value}>
      <ContextDevTool context={GeoContext} id="geo" displayName="Geo" />
      {props.children}
    </GeoContext.Provider>
  );
};

export default GeoProvider;
