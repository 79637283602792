// Settings services
import { UserSettings } from "artisn/types";

import { buildHeaders } from "utils/services.utils";
import { FcmTokenBody, PutUserSettingsPayload } from "./settings.service.types";
import { getState } from "services/state";

const baseUrl = "api/userDevices";

/**
 * Fetches the list of settings preferences of the signed in user.
 *
 * @returns {UserSettings} The list of user setting preferences
 */
export const fetchUserSettings = async (): Promise<UserSettings> => {
  const { shouldMock, axiosDefault } = getState();
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(`${baseUrl}`, {
        headers: await buildHeaders()
      });
      return data.data;
    } else {
      const { mockSettings } = await import("./settings.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockSettings);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Creates a subset of the settings preferences of the signed in user.
 *
 * @param {string} uid The unique user identifier
 * @returns {UserSettings} The updated list of user setting preferences
 */
export const postUserSettings = async (
  body: FcmTokenBody
): Promise<UserSettings | boolean> => {
  const { shouldMock, axiosDefault } = getState();
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.post(`${baseUrl}`, body, {
        headers: await buildHeaders()
      });

      return data.data;
    } else {
      const { mockSettings } = await import("./settings.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockSettings);
        }, 1000);
      });
    }
  } catch (e) {
    const handlee = e.response?.data;
    if (
      handlee?.code === "422" &&
      handlee?.warning[0].value ===
        "Ya existe un device token asociado a ese account"
    ) {
      return true;
    }
    throw new Error(e);
  }
};

/**
 * Updates a subset of the settings preferences of the signed in user.
 *
 * @param {UserSettings} userSettings The list of settings to update
 * @returns {UserSettings} The updated list of user setting preferences
 */
export const putUserSettings = async (
  body: PutUserSettingsPayload
): Promise<UserSettings> => {
  const { shouldMock, axiosDefault } = getState();
  try {
    if (!shouldMock) {
      const commonHeaders = await buildHeaders();

      const { data } = await axiosDefault.put(`${baseUrl}`, body, {
        headers: commonHeaders
      });

      return data.data;
    } else {
      const { mockPutSettings } = await import("./settings.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockPutSettings(body));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e);
  }
};
