import React, { cloneElement } from "react";
import { Modal, Image } from "artisn-ui-react";

import Styles from "./InfoActionModal.styles";
import { InfoActionModalProps as Props } from "./InfoActionModal.types";
import Button from "../Button/Button";

import CloseSVG from "../../../../public/assets/images/close.svg";

const InfoActionModal: React.FC<Props> = props => {
  const { className, icon, title, description, opened } = props;
  const { cancelAction, confirmAction, cancelText, confirmText } = props;
  const { onClose } = props;
  const { children } = props;
  const { isRating, hideButtons, disabled } = props;

  if (!cancelAction && !confirmAction) {
    throw new Error(
      `${InfoActionModal.name}: You must provide at least one action for this modal`
    );
  }

  const renderIcon = () => {
    if (!icon) return null;
    if (typeof icon === "string") {
      return (
        <Image
          className="InfoActionModal__img"
          image={icon}
          alt="Action icon"
          placeholder="blur"
        />
      );
    }
    return cloneElement(icon, {
      className: "InfoActionModal__img"
    });
  };

  return (
    <Modal
      opened={opened}
      closeIcon={cancelAction ? <CloseSVG /> : undefined}
      onClose={onClose}
      backdropConfig={{
        className: "InfoActionModal__backdrop"
      }}
    >
      <Styles
        className={`InfoActionModal ${className}`}
        hasIcon={!!icon}
        isRating={isRating}
        hideButtons={hideButtons}
      >
        {renderIcon()}
        <h3 className="InfoActionModal__title">{title}</h3>
        {description ? (
          <p className="InfoActionModal__description">{description}</p>
        ) : null}
        {children}
        {!isRating && (
          <div className="InfoActionModal__buttons">
            {cancelAction ? (
              <Button
                className="InfoActionModal__button InfoActionModal__cancel-button"
                color="primary-light"
                type="BORDER"
                onClick={cancelAction}
              >
                {cancelText ?? "Cancel"}
              </Button>
            ) : null}
            {confirmAction ? (
              <Button
                className="InfoActionModal__button InfoActionModal__confirm-button"
                color="black"
                onClick={confirmAction}
              >
                {confirmText ?? "Confirm"}
              </Button>
            ) : null}
          </div>
        )}
        {isRating && !hideButtons && (
          <div className="RateModal__buttons">
            {cancelAction ? (
              <Button
                className="RateModal__buttons__button RateModal__buttons__cancel-button"
                onClick={cancelAction}
              >
                {cancelText ?? "Cancel"}
              </Button>
            ) : null}
            {confirmAction ? (
              <Button
                className="RateModal__buttons__button RateModal__buttons__confirm-button"
                onClick={confirmAction}
                disabled={disabled}
              >
                {confirmText ?? "Confirm"}
              </Button>
            ) : null}
          </div>
        )}
      </Styles>
    </Modal>
  );
};

InfoActionModal.defaultProps = {
  className: ""
};

export default InfoActionModal;
