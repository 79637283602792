import { useContext } from "react";

import { CountriesContext } from "./countries.context";
import { CountriesProviderValue } from "./countries.context.types";

const useCountries = () => {
  const context = useContext<CountriesProviderValue>(CountriesContext);
  if (typeof context === "undefined") {
    throw new Error("useCountries must be used within a CountriesProvider");
  }
  return context;
};

export default useCountries;
