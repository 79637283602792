// Products utility functions
import { CartProduct, Product, ProductDetails } from "artisn/types";
import { getProductTotals, ModifiersForm } from "artisn/products";
import { UseProductCardConfig } from "artisn-ui-react";
import { ProductCardData, ProductTotals } from "artisn-ui-react";
import { Storage } from "@capacitor/storage";

import { ProductPreferences } from "types/common.types";

/**
 * This function is used to transform the details of the product because the
 * artisn library does not yet support an undefined array of questions in the
 * answers.
 *
 * This function will be removed when the artisn library supports this
 * functionality
 */
export const normalizeProductDetails = (
  product: ProductDetails
): ProductDetails => {
  const { questions = [] } = product;
  return {
    ...product,
    questions: questions.map(question => {
      const { answers = [] } = question;
      return {
        ...question,
        answers: answers.map(answer => {
          const { questions = [] } = answer;
          return {
            ...answer,
            questions
          };
        })
      };
    })
  };
};

export const saveProductPreference = async (
  form: ModifiersForm | undefined,
  userUid: string | undefined
) => {
  if (!form || !userUid) return;
  const { product, renderer } = form ?? {};
  const { productId } = product;

  const productPreferences: ProductPreferences = {};
  const pastPreferences = await getProductPreferences(product, userUid);

  renderer.forEach(question => {
    const { id: modifierGroupId, modifiers } = question;
    modifiers.forEach(answer => {
      const { id: modifierId, amount, type } = answer;
      if (!amount || type !== "RADIO") return undefined;
      // If there already is a preference saved, update it.
      if (pastPreferences[`${modifierGroupId}-${modifierId}`]) {
        pastPreferences[`${modifierGroupId}-${modifierId}`] = {
          modifierId,
          amount,
          type,
          times: pastPreferences[`${modifierGroupId}-${modifierId}`].times + 1
        };
        Storage.set({
          key: `${userUid}-${productId}`,
          value: JSON.stringify({ ...pastPreferences })
        });
        return;
      }
      // If the preference is different, save it for the first time
      productPreferences[`${modifierGroupId}-${modifierId}`] = {
        modifierId,
        amount,
        type,
        times: 1
      };
    });
  });

  Storage.set({
    key: `${userUid}-${productId}`,
    value: JSON.stringify({ ...pastPreferences, ...productPreferences })
  });
};

export const getMostChosenOptionKey = (preferences: ProductPreferences) => {
  const objectKeys = Object.keys(preferences);
  if (objectKeys.length === 0) return;
  return objectKeys.reduce((acc, value) => {
    if (preferences[acc].times > preferences[value].times) return acc;
    return value;
  });
};

export const getProductPreferences = async (
  product: Product,
  userUid: string | undefined
): Promise<ProductPreferences> => {
  const { productId } = product;

  const { value } = await Storage.get({
    key: `${userUid}-${productId}`
  });
  const productPreference = value ? JSON.parse(value) : {};
  return productPreference;
};

export const getSanitizedProductTotals = (
  product: Product,
  config: UseProductCardConfig = {}
): ProductCardData => {
  const { amount, decimals } = config;
  const { prices, available, priceCategory } = product as CartProduct;
  const { NORMAL, POINTS } = prices;
  const productTotals = getProductTotals(product, amount);
  const sanitizedProductTotals = Object.entries(productTotals).reduce(
    (acc, field) => {
      const [key, value] = field;
      if (typeof value === "string" || value === null) {
        return { ...acc, [key]: value };
      }
      return { ...acc, [key]: +(value as number).toFixed(decimals) };
    },
    {} as ProductTotals
  );
  const totals: ProductTotals = {
    ...sanitizedProductTotals,
    symbol: NORMAL.symbol,
    unitPoints: +POINTS?.netPrice.toFixed(decimals),
    points: +(POINTS?.netPrice * productTotals.amount).toFixed(decimals),
    priceCategory
  };

  return { totals, available };
};
