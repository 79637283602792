import React from "react";
import Script from "next/script";
import { AppProps as Props } from "next/app";
import "artisn-ui-react/main.css";

import Providers from "containers/Providers/Providers";
import Layout from "components/global/Layout/Layout";

const App: React.FC<Props> = props => {
  const { Component, pageProps } = props;

  return (
    <Providers dehydratedState={pageProps.dehydratedState}>
      <Layout>
        <Script
          id="google-tag-manager"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-W9X3GCD');`
          }}
        ></Script>
        <Component {...pageProps} />
      </Layout>
    </Providers>
  );
};

export default App;
