import styled from "styled-components";

import { SingleSignOnStyledProps as Props } from "./SingleSignOn.types";

const SingleSignOnStyled = styled.div<Props>`
  border-radius: 0.8rem;
  padding-bottom: 4rem;

  .SingleSignOn {
    &__title {
      margin-bottom: 2.4rem;
      font-size: 2.4rem;
      font-weight: 800;
    }

    &__icons {
      display: flex;
      flex-direction: column;
    }

    &__button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.4rem;
      height: 4.8rem;
      border-radius: 12rem;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 1.4rem;
      }

      svg {
        position: absolute;
        left: 1.6rem;
        width: 2rem;
        height: 2rem;
      }

      p {
        font-size: 1.6rem;
        font-weight: 800;
        color: var(--palette-white);
      }
    }

    &__google {
      background-color: var(--palette-gray-s0-l98);

      p {
        color: var(--palette-gray-s0-l20);
      }
    }

    &__facebook {
      background-color: var(--palette-blue-s97-l45);

      svg path {
        fill: var(--palette-white);
      }
    }

    &__apple {
      background-color: var(--palette-black-s0-l20);
    }
  }
`;

export default SingleSignOnStyled;
