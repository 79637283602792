import React from "react";

import { ProductPlaceholderStyled as Styles } from "./Product.styles";
import { ProductPlaceholderProps as Props } from "./Product.types";

const ProductPlaceholder: React.FC<Props> = props => {
  return (
    <Styles className="ProductPlaceholder">
      <div className="ProductPlaceholder__image-container">
        <div className="ProductPlaceholder__image loading-shine" />
      </div>
      <div className="ProductPlaceholder__form">
        <div className="ProductPlaceholder__name loading-shine" />
        <div className="ProductPlaceholder__price loading-shine" />
        <div className="ProductPlaceholder__description loading-shine" />
        <div className="ProductPlaceholder__actions">
          <div className="ProductPlaceholder__counter loading-shine" />
          <div className="ProductPlaceholder__button loading-shine" />
        </div>
        <div className="ProductPlaceholder__modifierGroup">
          <div className="ProductPlaceholder__modifierGroup-name loading-shine" />
          <div className="ProductPlaceholder__modifierGroup-description loading-shine" />
          <div className="ProductPlaceholder__modifier">
            <div className="ProductPlaceholder__modifier-field loading-shine" />
            <div className="ProductPlaceholder__modifier-name loading-shine" />
            <div className="ProductPlaceholder__modifier-price loading-shine" />
          </div>
          <div className="ProductPlaceholder__modifier">
            <div className="ProductPlaceholder__modifier-field loading-shine" />
            <div className="ProductPlaceholder__modifier-name loading-shine" />
            <div className="ProductPlaceholder__modifier-price loading-shine" />
          </div>
          <div className="ProductPlaceholder__modifier">
            <div className="ProductPlaceholder__modifier-field loading-shine" />
            <div className="ProductPlaceholder__modifier-name loading-shine" />
            <div className="ProductPlaceholder__modifier-price loading-shine" />
          </div>
        </div>
      </div>
    </Styles>
  );
};

ProductPlaceholder.defaultProps = {};

export default ProductPlaceholder;
