// Interfaces and types from context Payments
import { Dispatch, SetStateAction } from "react";

import CONSTANTS from "config/constants";
import { Card } from "types/payment.types";
import { PagoPluxCard } from "services/payments/pagoPlux/pagoPlux.service.types";
import { PagoPlux3dsTokens } from "services/payments/pagoPlux/pagoPlux.service.types";
import { PagoPlux3dsPayload } from "services/payments/pagoPlux/pagoPlux.service.types";
import { OtpValidationPayload } from "services/payments/pagoPlux/pagoPlux.service.types";
import { IncompleteOrder } from "types/order.types";
import { CardFormValues } from "components/checkout/CardForm/CardForm.types";
import { OrderDetails, OrderShoppingCart, ShippingCost } from "artisn/types";

const { PAYMENT_METHODS, PAYMENT_PROVIDERS } = CONSTANTS.ARTISN;
const { CASH_ID: CASH, CARD_ID: CARD } = PAYMENT_METHODS;
const { KUSHKI_ID: KUSHKI, PAY_PHONE_ID: PAY_PHONE } = PAYMENT_PROVIDERS;
const { PLACE_TO_PAY_ID: PLACE_TO_PAY } = PAYMENT_PROVIDERS;
const { PAGO_PLUX_ID: PAGO_PLUX } = PAYMENT_PROVIDERS;

// Provider Props
export interface PaymentsProviderProps {
  children: React.ReactNode;
}
export interface ThreeDsPayload {
  card: PagoPluxCard | undefined;
  order: IncompleteOrder | OrderDetailsResponse | undefined;
  threeDS: PagoPlux3dsPayload | undefined;
}

export interface CheckoutInfo {
  cardNumber: string;
  comment: string;
  isPagoPlux: boolean;
  cardFormValues: CardFormValues;
}

export interface OrderDetailsResponse
  extends Omit<OrderDetails, "shippingCost"> {
  shoppingCart: OrderShoppingCart;
  shippingCost: ShippingCost;
}

// Provider value
export interface PaymentsProviderValue {
  selectedCard: Card | undefined;
  setSelectedCard: Dispatch<SetStateAction<Card | undefined>>;
  resetPaymentsContext: () => void;
  selectedPaymentMethodId: PaymentMethodId;
  setSelectedPaymentMethodId: Dispatch<SetStateAction<PaymentMethodId>>;
  selectedPaymentProviderId: PaymentProviderId | undefined;
  setSelectedPaymentProviderId: Dispatch<
    SetStateAction<PaymentProviderId | undefined>
  >;
  showModalOtpPagoPlux: boolean;
  setShowModalOtpPagoPlux: Dispatch<SetStateAction<boolean>>;
  otpRequest: OtpValidationPayload | undefined;
  setOtpRequest: Dispatch<SetStateAction<OtpValidationPayload | undefined>>;
  showModal3dsPagoPlux: boolean;
  setShowModal3dsPagoPlux: Dispatch<SetStateAction<boolean>>;
  threeDSRequest: ThreeDsPayload | undefined;
  setThreeDSRequest: Dispatch<SetStateAction<ThreeDsPayload | undefined>>;
  checkoutInfo: CheckoutInfo | undefined;
  setCheckoutInfo: Dispatch<SetStateAction<CheckoutInfo | undefined>>;
  new3dsTokens: PagoPlux3dsTokens | undefined;
  setNew3dsTokens: Dispatch<SetStateAction<PagoPlux3dsTokens | undefined>>;
}

// Additional Types
export enum PaymentMethodId {
  CASH_ID = CASH,
  CARD_ID = CARD,
  OTHER = -1
}

export enum PaymentProviderId {
  KUSHKI_ID = KUSHKI,
  PAY_PHONE_ID = PAY_PHONE,
  PLACE_TO_PAY_ID = PLACE_TO_PAY,
  PAGO_PLUX_ID = PAGO_PLUX
}
