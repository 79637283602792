import React, { useState } from "react";
import { events } from "@artisan-commerce/analytics-capacitor";
import { Clickable } from "artisn-ui-react";

import Styles, { DeleteAddressModal } from "./ChooseAddress.styles";
import { ChooseAddressProps as Props } from "./ChooseAddress.types";
import { useDeleteShippingAddress } from "services/shippingAddress/shippingAddress.service.hooks";
import { usePutShippingAddress } from "services/shippingAddress/shippingAddress.service.hooks";
import { defaultFunction } from "utils/common.utils";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import ListItem from "components/global/ListItem/ListItem";
import { trimFields } from "utils/form.utils";

import TrashSVG from "../../../../public/assets/images/trash.svg";

const { logSelectShippingAddress } = events.shipping;

const ChooseAddress: React.FC<Props> = props => {
  const { shippingAddress, actions, onClick = defaultFunction } = props;
  const { onEdit: onPressEdit = defaultFunction, dropdownFixed } = props;
  const { mainStreet, secondaryStreet, number } = shippingAddress;
  const { nickname, id, default: isDefault } = shippingAddress;
  const { selectedShippingAddress } = useShippingAddress();
  const { setSelectedShippingAddress } = useShippingAddress();
  const putState = usePutShippingAddress();
  const { mutate: updateAddress, isLoading: isPutLoading } = putState;
  const isActive = selectedShippingAddress?.id === id;
  const [openModal, setOpenModal] = useState(false);
  const deleteState = useDeleteShippingAddress();
  const { mutate: deleteAddress, isLoading: isDeleteLoading } = deleteState;
  const isLoading = isPutLoading || isDeleteLoading;
  const activeClassName = isActive ? "ChooseAddress__radio-button--active" : "";

  const onEdit = () => {
    onPressEdit(shippingAddress);
  };

  const onDelete = () => {
    setOpenModal(true);
  };

  const onDefault = () => {
    const newShippingAddress = trimFields(shippingAddress);
    updateAddress(
      { ...newShippingAddress, default: true },
      {
        onError: () => {
          dismissErrorNotification();
          createErrorNotification(
            "Se produjo un error al intentar actualizar los datos de la dirección."
          );
        }
      }
    );
  };

  const clickHandler = () => {
    logSelectShippingAddress({
      address: `${mainStreet} ${secondaryStreet} ${number}`,
      alias: nickname,
      addressId: id
    });
    setSelectedShippingAddress(shippingAddress);
    onClick(shippingAddress);
  };

  const onClose = () => setOpenModal(false);

  const deleteHandler = () => {
    deleteAddress(id, {
      onError: () => {
        dismissErrorNotification();
        createErrorNotification(
          "Se produjo un error al intentar eliminar los datos de la dirección"
        );
        console.error(
          "DeleteAddressModal: An error occurred while trying to delete address data"
        );
      },
      onSuccess: () => {
        setSelectedShippingAddress(undefined);
        onClose();
      }
    });
  };

  return (
    <Styles className="ChooseAddress" isActive={isActive} disabled={isLoading}>
      <Clickable
        className="ChooseAddress__radio-button-container"
        onClick={clickHandler}
      >
        <div className={`ChooseAddress__radio-button ${activeClassName}`} />
      </Clickable>
      <ListItem
        title={nickname}
        description={`${mainStreet}, ${secondaryStreet}-${number}`}
        isLoading={isLoading}
        isDefault={isDefault}
        onEdit={onEdit}
        onDelete={onDelete}
        onDefault={onDefault}
        actions={actions}
        isFixed={dropdownFixed}
      />
      <DeleteAddressModal
        opened={openModal}
        className="DeleteAddressModal"
        icon={<TrashSVG />}
        title="¿Deseas proceder a eliminar?"
        description="Una vez eliminado no podrás recuperar esta información"
        confirmText="Eliminar"
        confirmAction={deleteHandler}
        cancelText="Cancelar"
        cancelAction={onClose}
        onClose={onClose}
      />
    </Styles>
  );
};

ChooseAddress.defaultProps = {};

export default ChooseAddress;
