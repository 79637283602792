import React, { useCallback, useEffect, useState } from "react";
import { Notification } from "artisn-ui-react";

import Styles from "./ShoppingCartNotifications.styles";
import { ShoppingCartNotificationsProps as Props } from "./ShoppingCartNotifications.types";
import ChooseStoreModal from "components/chooseStore/ChooseStoreModal/ChooseStoreModal";
import ShippingAddressModal from "components/checkout/ShippingAddressModal/ShippingAddressModal";
import { dismissNoCoverageNotification } from "utils/notifications.utils";
import { createClosedStoreNotification } from "utils/notifications.utils";
import { dismissClosedStoreNotification } from "utils/notifications.utils";
import { createNoCoverageNotification } from "utils/notifications.utils";
import { createInfoNotification } from "utils/notifications.utils";
import { dismissInfoNotification } from "utils/notifications.utils";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import useStores from "contexts/stores/stores.context.hooks";
import useShoppingCartNotifications from "contexts/shoppingCartNotifications/shoppingCartNotifications.context.hooks";
import { useShoppingCart as useShoppingCartContext } from "contexts/shoppingCart/shoppingCart.context.hooks";

const ShoppingCartNotifications: React.FC<Props> = props => {
  const { showNoCoverage, setShowNoCoverage } = useShoppingCartNotifications();
  const { showClosedStore, setClosedStore } = useShoppingCartNotifications();
  const { showMapCoordinates, setShowMapCoordinates } =
    useShoppingCartNotifications();
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openChooseStore, setOpenChooseStore] = useState(false);
  const { selectedCatalogue } = useCatalogues();
  const { name } = selectedCatalogue ?? {};
  const selectedCatalogueName = name.toUpperCase();
  const { setShowStoreNotification } = useStores();
  const { setOpenShoppingCartDrawer } = useShoppingCartContext();

  const closeNoCoverageNotification = useCallback(() => {
    setShowNoCoverage(false);
  }, [setShowNoCoverage]);

  const closeStoreNotification = useCallback(() => {
    setClosedStore(false);
  }, [setClosedStore]);

  const showAddressModal = useCallback(() => {
    setOpenShoppingCartDrawer(false);
    setOpenAddressModal(true);
    setShowStoreNotification(true);
    closeNoCoverageNotification();
  }, [
    closeNoCoverageNotification,
    setOpenShoppingCartDrawer,
    setShowStoreNotification
  ]);

  const showStoreModal = useCallback(() => {
    setOpenShoppingCartDrawer(false);
    if (selectedCatalogueName === "DELIVERY") {
      setOpenAddressModal(true);
    } else {
      setOpenChooseStore(true);
    }
    setShowStoreNotification(true);
    closeStoreNotification();
  }, [
    closeStoreNotification,
    selectedCatalogueName,
    setOpenShoppingCartDrawer,
    setShowStoreNotification
  ]);

  const closeInfoNotification = useCallback(() => {
    setShowMapCoordinates(false);
  }, [setShowMapCoordinates]);

  useEffect(() => {
    if (showNoCoverage) {
      createNoCoverageNotification(
        showAddressModal,
        openAddressModal,
        closeNoCoverageNotification
      );
    } else {
      dismissNoCoverageNotification();
    }
    return () => Notification.destroy();
  }, [
    showNoCoverage,
    openAddressModal,
    closeNoCoverageNotification,
    showAddressModal
  ]);

  useEffect(() => {
    if (showClosedStore) {
      createClosedStoreNotification(
        selectedCatalogueName,
        showStoreModal,
        openChooseStore || openAddressModal,
        closeStoreNotification
      );
    } else {
      dismissClosedStoreNotification();
    }
    return () => Notification.destroy();
  }, [
    showClosedStore,
    showStoreModal,
    openChooseStore,
    openAddressModal,
    selectedCatalogueName,
    closeStoreNotification
  ]);

  useEffect(() => {
    if (showMapCoordinates) {
      createInfoNotification(
        "Faltan permisos de ubicación",
        "Debes habilitar los permisos de ubicación para avanzar",
        "Cambiar de ubicación",
        "Cambiar",
        showAddressModal,
        true,
        closeInfoNotification
      );
    } else {
      dismissInfoNotification();
    }
    return () => Notification.destroy();
  }, [showMapCoordinates, closeInfoNotification, showAddressModal]);

  return (
    <Styles className="ShoppingCartNotifications">
      {openAddressModal ? (
        <ShippingAddressModal
          opened={openAddressModal}
          onClose={() => {
            setShowMapCoordinates(false);
            setOpenAddressModal(false);
            setShowStoreNotification(false);
          }}
          locationOnly
          onDismiss={() => closeNoCoverageNotification()}
        />
      ) : null}
      <ChooseStoreModal
        opened={openChooseStore}
        onClose={() => {
          setOpenChooseStore(false);
          setShowStoreNotification(false);
        }}
      />
    </Styles>
  );
};

ShoppingCartNotifications.defaultProps = {};

export default ShoppingCartNotifications;
