import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import { ShippingAddress } from "artisn/types";

import { ShippingAddressProviderProps as Props } from "./shippingAddress.context.types";
import { ShippingAddressProviderValue } from "./shippingAddress.context.types";
import { Google } from "types/geo.types";

// @ts-ignore
export const ShippingAddressContext = createContext<AddressProviderValue>({});

const ShippingAddressProvider: React.FC<Props> = props => {
  // SelectedShippingAddress Data
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState<ShippingAddress>();
  const [anonymousShippingAddress, setAnonymousShippingAddress] = useState<
    Google.Geocode | undefined
  >();
  const [selectedAddressDropdown, setSelectedAddressDropdown] = useState(false);
  const [showBackdropAddressDropdown, setShowBackdropAddressDropdown] =
    useState(false);

  const resetShippingAddressContext = () => {
    setSelectedShippingAddress(undefined);
  };

  const value: ShippingAddressProviderValue = useMemo(() => {
    return {
      selectedShippingAddress,
      setSelectedShippingAddress,
      anonymousShippingAddress,
      setAnonymousShippingAddress,
      resetShippingAddressContext,
      selectedAddressDropdown,
      setSelectedAddressDropdown,
      showBackdropAddressDropdown,
      setShowBackdropAddressDropdown
    };
  }, [
    selectedShippingAddress,
    anonymousShippingAddress,
    selectedAddressDropdown,
    showBackdropAddressDropdown
  ]);

  return (
    <ShippingAddressContext.Provider value={value}>
      <ContextDevTool
        context={ShippingAddressContext}
        id="address"
        displayName="Address"
      />
      {props.children}
    </ShippingAddressContext.Provider>
  );
};

export default ShippingAddressProvider;
