import React from "react";
import { formatByCurrency } from "artisn-ui-react";

import Styles from "./ProductBasePriceInfo.styles";
import { ProductBasePriceInfoProps as Props } from "./ProductBasePriceInfo.types";
import { getFormatPointsOption } from "./ProductBasePriceInfo.helpers";
import useCountries from "contexts/countries/countries.hooks";
import CONSTANTS from "config/constants";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";

const { WITH_LOYALTY } = CONSTANTS.FEATURE_FLAGS;

const ProductBasePriceInfo: React.FC<Props> = props => {
  const { showUnits = false } = props;
  const { className, productTotals, orderTotal } = props;
  const { unitNetPrice, unitPoints } = productTotals ?? {};
  const normal = orderTotal ? orderTotal : unitNetPrice;
  const { selectedCountry } = useCountries();
  const defaultFormatter = useDefaultCurrencyFormatter();

  return (
    <Styles className={`ProductBasePriceInfo ${className}`}>
      <p className="ProductBasePriceInfo--normal">
        {formatByCurrency(normal ?? 0, defaultFormatter)}
        {showUnits ? " c/u" : null}
      </p>
      {WITH_LOYALTY && unitPoints && !isNaN(unitPoints) ? (
        <p className="ProductBasePriceInfo--points">
          {formatByCurrency(
            Number(unitPoints ?? 0),
            getFormatPointsOption(selectedCountry),
            true
          )}
        </p>
      ) : null}
    </Styles>
  );
};

ProductBasePriceInfo.defaultProps = {
  className: ""
};

export default ProductBasePriceInfo;
