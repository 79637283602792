// SignInForm helper functions and data
import * as yup from "yup";

import { validationRules } from "utils/form.utils";

const { requiredPhoneNumber } = validationRules;
const { customName } = validationRules;

const alwaysAccepted = () =>
  yup
    .mixed()
    .test(
      "alwaysAccepted",
      "Debe aceptar los términos y condiciones",
      () => true
    );

export const signUpUserSchema = yup.object().shape({
  name: customName(3),
  lastname: customName(3),
  phone: requiredPhoneNumber,
  terms: alwaysAccepted(),
  acceptEmails: yup.boolean().optional(),
  acceptPushNotifications: yup.boolean().optional()
});
