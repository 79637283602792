import styled from "styled-components";

import { SignUpUserFormStyledProps as Props } from "./SignUpUserForm.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const SignUpUserFormStyled = styled.div<Props>`
  background-color: var(--palette-white);

  .SignUpUserForm {
    &__title {
      font-size: 2.4rem;
      font-weight: 800;
      margin-bottom: 4rem;
      color: var(--palette-black-s0-l20);
    }

    &__field {
      margin-top: 1.6rem;
    }

    &__error-message {
      margin-top: 1.2rem;
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }

    &__checkbox {
      .Checkbox {
        &__check {
          flex-shrink: 0;
        }

        &__label {
          color: var(--palette-gray-s0-l40);
        }
      }
    }

    &__checkboxTextOne {
    }

    &__checkboxTextTwo {
      color: var(--palette-gray-s0-l35);
      text-decoration: underline;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4.8rem;

      @media (max-width: ${mobile}px) {
        flex-direction: column;
        margin-top: 2.4rem;
      }
    }

    &__button {
      width: 100%;
      height: 4.8rem;
      font-size: 1.6rem;
      font-weight: 800;
      border-radius: 10rem;

      &--secondary {
        font-weight: 400;
        width: max-content;
      }
    }
  }
`;

export default SignUpUserFormStyled;
