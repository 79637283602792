import { lighten, darken } from "polished";

const primary = "hsla(150, 100%, 19%, 1)";

const palette = {
  empty: "", // Fixes a bug with styled components that appends a coma
  "primary-dark": darken(0.12, primary),
  "primary-light": lighten(0.47, primary),
  // This variable is for capacitor use only, it represents the primary color.
  "primary-hex": "#006130",
  "green-s100-l19": primary,
  primary: "var(--palette-green-s100-l19)",
  "secondary-s35-l95": "hsla(153, 35%, 95%, 1)",
  "green-s24-l65": "hsla(153, 24%, 65%, 1)",
  "green-s100-l14": "hsla(153, 100%, 14%, 1)",
  "green-s100-l40": "hsla(88, 100%, 40%, 1)",
  "green-s63-l42": "hsla(145, 63%, 42%, 1)",
  "red-s61-l87": "hsla(0, 61%, 87%, 1)",
  "red-s60-l60": "hsla(2, 60%, 60%, 1)",
  "red-s69-l63": "hsla(2, 69%, 63%, 1)",
  "blue-s89-l52": "hsla(214, 89%, 52%, 1)",
  "blue-s97-l45": "hsla(200, 97%, 45%, 1)",
  "yellow-s100-l55": "hsla(50, 100%, 54%, 1)",
  "yellow-s93-l51": "hsla(50, 93%, 51%, 1)",
  "brown-s5-l25": "hsla(32, 5%, 25%, 1)",
  "brown-s82-l26": "hsla(41, 82%, 26%, 1)",
  "black-s0-l0-a1": "hsla(0, 0%, 0%, 0.1)",
  "black-s0-l0-a15": "hsla(0, 0%, 0%, 0.15)",
  // This variable is for capacitor use only, it represents the black color.
  "black-hex": "#000000",
  black: "hsla(0, 0%, 0%, 1)",
  "black-s0-l10": "hsla(0, 0%, 10%, 1)",
  "black-s0-l20": "hsla(0, 0%, 20%, 1)",
  "gray-s0-l20-a04": "hsla(0, 0%, 20%, 0.04)",
  "gray-s0-l0-a05": "hsla(0, 0%, 0%, 0.05)",
  "gray-s0-l20-a08": "hsla(0, 0%, 20%, 0.08)",
  "gray-s0-l20-a16": "hsla(0, 0%, 20%, 0.16)",
  "gray-s0-l20-a20": "hsla(0, 0%, 20%, 0.20)",
  "gray-s0-l20-a70": "hsla(0, 0%, 20%, 0.70)",
  "gray-s0-l20": "hsla(0, 0%, 20%, 1)",
  "gray-s0-l30": "hsla(0, 0%, 30%, 1)",
  "gray-s0-l35": "hsla(0, 0%, 35%, 1)",
  "gray-s0-l40-a40": "hsla(0, 0%, 40%, 0.4)",
  "gray-s0-l40": "hsla(0, 0%, 40%, 1)",
  "gray-s0-l50": "hsla(0, 0%, 50%, 1)",
  "gray-s0-l70-a48": "hsla(0, 0%, 70%, 0.48)",
  "gray-s0-l70": "hsla(0, 0%, 70%, 1)",
  "gray-s0-l75": "hsla(0, 0%, 75%, 1)",
  "gray-s0-l80": "hsla(0, 0%, 80%, 1)",
  "gray-s0-l85": "hsla(0, 0%, 85%, 1)",
  "gray-s0-l90": "hsla(0, 0%, 90%, 1)",
  "gray-s0-l92": "hsla(0, 0%, 92%, 1)",
  "gray-s0-l95": "hsla(0, 0%, 95%, 1)",
  "gray-s0-l97": "hsla(0, 0%, 97%, 1)",
  "gray-s0-l98": "hsla(0, 0%, 98%, 1)",
  "lightgrey-s5-l90": "hsla(210, 5%, 90%, 1)",
  "grey-s6-l40": "hsla(200, 6%, 40%, 1)",
  // This variable is for capacitor use only, it represents the white color.
  "white-hex": "#ffffff",
  "black-s0-l10-hex": "#1a1a1a",
  white: "hsla(0, 0%, 100%, 1)",
  text: {
    primary: "var(--palette-primary)",
    white: "var(--palette-white)"
  }
};

const fonts = {
  primary: "'Montserrat', sans-serif"
};

const sizes = {
  page: {
    minWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    maxWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    minHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    maxHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    columns:
      "minmax(1.6rem, 1fr) [content-start] minmax(0, 120rem) [content-end] minmax(1.6rem, 1fr)"
  },
  navbar: {
    height: "calc(9rem + env(safe-area-inset-top))",
    "height-mobile": "calc(10.8rem + env(safe-area-inset-top))",
    "height-mobile-without-search": "calc(7.2rem + env(safe-area-inset-top))",
    "height-tablet-with-search-with-close-component":
      "calc(10.8rem + 5.6rem + env(safe-area-inset-top))",
    "height-tablet-with-search-without-close-component":
      "calc(10.8rem + 5.6rem - 2.6rem + env(safe-area-inset-top))"
  }
};

const variables = { palette, fonts, sizes };

export default variables;
