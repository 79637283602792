import { useEffect } from "react";
import { Storage } from "@capacitor/storage";

import CONSTANTS from "config/constants";
import usePayments from "contexts/payments/payments.context.hooks";
import { useFetchCards } from "services/payments/payments.service.hooks";

const { CARD_TOKEN } = CONSTANTS.STORAGE;

const useListenPayments = () => {
  const { selectedCard, setSelectedCard } = usePayments();
  const { data: cards } = useFetchCards();

  // Get card from local storage and set it on context
  useEffect(() => {
    (async () => {
      /* TODO: Esto es temporal hasta que tengamos integrado la billetera de 
      tarjetas en payphone */
      /* if (selectedPaymentProviderId === PaymentProviderId.PAY_PHONE_ID) {
        setSelectedCard(undefined);
        return;
      } */
      const { value } = await Storage.get({ key: CARD_TOKEN });
      const lastSelectedCard = value ? JSON.parse(value) : undefined;
      if (!cards) {
        setSelectedCard(lastSelectedCard);
        return;
      }
      const [firstCard] = cards;
      const card = lastSelectedCard ?? firstCard;
      setSelectedCard(card);
    })();
  }, [cards, setSelectedCard]);

  // Every time the selected card changes, save it on local storage
  useEffect(() => {
    if (!selectedCard) return;
    Storage.set({
      key: CARD_TOKEN,
      value: JSON.stringify(selectedCard)
    });
  }, [selectedCard]);
};

export default useListenPayments;
