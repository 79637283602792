import React from "react";

import Styles from "./ProductErrorMessage.styles";
import { ProductErrorMessageProps as Props } from "./ProductErrorMessage.types";
import Button from "components/global/Button/Button";

import InfoErrorSVG from "../../../../public/assets/images/info-error.svg";

const ProductErrorMessage: React.FC<Props> = props => {
  const { goToCategories } = props;

  return (
    <Styles className="ProductErrorMessage">
      <div className="ProductErrorMessage__error">
        <InfoErrorSVG />
        <p className="ProductErrorMessage__error__title">
          Este producto no está disponible
        </p>
        <p className="ProductErrorMessage__error__description">
          Revisa nuestros{" "}
          <Button
            type="LINK"
            className="ProductErrorMessage__error__link"
            onClick={goToCategories}
          >
            productos relacionados
          </Button>
        </p>
      </div>
    </Styles>
  );
};

ProductErrorMessage.defaultProps = {};

export default ProductErrorMessage;
