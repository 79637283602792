import styled from "styled-components";

import CONSTANTS from "config/constants";
import { CouponDetailStyledProps as Props } from "./CouponDetail.types";

const { mobile } = CONSTANTS.BREAKPOINTS;

const CouponDetailStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex: 1;

  .CouponDetail {
    &__container {
      display: flex;
      justify-content: space-between;
      min-height: 10.4rem;
      background-color: ${props =>
        props.selected
          ? "var(--palette-primary-light)"
          : "var(--palette-white)"};
      border-radius: 0.8rem;
      padding: 0.8rem;
      border: 1px solid var(--palette-lightgrey-s5-l90);

      @media (max-width: ${mobile}px) {
        padding: 0.8rem;
      }
    }

    &__left {
      display: flex;
      text-align: left;
      align-items: center;

      &__benefit-image {
        width: 12rem;
        height: 12rem;
        margin-right: 1.6rem;

        .Image {
          width: 100%;
          height: 100%;

          &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &__icon {
        min-width: 2.4rem;
        min-height: 2.4rem;
        border: 0.1rem solid var(--palette-gray-s0-l40);
        border-radius: 10rem;
        margin-right: 1.2rem;
      }

      &__label {
        &__title {
          margin-bottom: 0.8rem;
          font-size: 1.6rem;
          line-height: 1.6rem;
          font-weight: 800;
          color: var(--palette-black-s0-l20);
        }

        &__code {
          margin-bottom: 0.8rem;
          font-size: 1.6rem;
          line-height: 1.6rem;
          font-weight: 800;
          color: var(--palette-gray-s0-l50);
        }

        &__expiration-date {
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: var(--palette-gray-s0-l75);
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
    }

    &__remove {
      padding: 0;
    }

    &__remove-error {
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
      padding-top: 0.4rem;
    }
  }
`;

export default CouponDetailStyled;
