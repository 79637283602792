import { AxiosRequestHeaders } from "axios";

import CONSTANTS from "config/constants";
import { auth } from "config/artisn.config";
import { getState } from "services/state";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;
const { PLATFORM } = CONSTANTS.GENERAL;

export const buildBaseHeaders = async (
  additionalHeaders?: Record<string, string | number | boolean | undefined>
): Promise<AxiosRequestHeaders> => {
  const { user } = getState();
  const token = await user?.getIdToken();

  return {
    Platform: PLATFORM,
    account: `${ACCOUNT_ID}`,
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    ...additionalHeaders
  };
};

export const buildHeaders = async (
  additionalHeaders?: Record<string, string | number | boolean | undefined>
): Promise<AxiosRequestHeaders> => {
  return await buildBaseHeaders(additionalHeaders);
};

export const buildHeaderInit = async (
  additionalHeaders?: Record<string, string | number | boolean | undefined>
): Promise<HeadersInit> => {
  return await buildBaseHeaders(additionalHeaders);
};

export const buildArtisnHeaders = async () => {
  const token = await auth.currentUser?.getIdToken();
  const headers = new Headers();

  headers.set("Platform", PLATFORM);
  headers.set("account", `${ACCOUNT_ID}`);
  headers.set("Authorization", `Bearer ${token}`);
  headers.set("Content-Type", "application/json");

  return headers;
};
