import styled from "styled-components";

import { PasswordStrengthStyledProps as Props } from "./PasswordStrength.types";

const PasswordStrengthStyled = styled.div<Props>`
  .PasswordStrength {
    &__conditions {
      padding-top: 1.2rem;
    }

    &__list-title {
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l40);
    }

    &__condition {
      padding-top: 1.2rem;
      display: flex;
      align-items: center;
    }

    &__icon {
    }

    &__message {
      color: var(--palette-black-s0-l10);
      padding-left: 0.8rem;
      font-size: 1.4rem;
    }
  }
`;

export default PasswordStrengthStyled;
