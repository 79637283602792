import React from "react";
import { formatByCurrency } from "artisn-ui-react";

import Styles from "./ModifierPrices.styles";
import { ModifierPricesProps as Props } from "./ModifierPrices.types";

const ModifierPrices: React.FC<Props> = props => {
  const { totals } = props;
  const { unitNetPrice, netPrice } = totals;

  return (
    <Styles className="ModifierPrices">
      <p className="ModifierPrices__sum">
        +{" "}
        {formatByCurrency(netPrice, {
          currency: "USD"
        })}
      </p>
      <p className="ModifierPrices__unitPrice">
        {formatByCurrency(unitNetPrice, {
          currency: "USD"
        })}{" "}
        c/u
      </p>
    </Styles>
  );
};

ModifierPrices.defaultProps = {};

export default ModifierPrices;
