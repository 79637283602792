import styled from "styled-components";

import { ProductMainStyledProps as Props } from "./ProductMain.types";
import CONSTANTS from "config/constants";
import { getMaxWidth } from "utils/styling.utils";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const ProductMainStyled = styled.div<Props>`
  .ProductMain {
    &__content {
      display: flex;
      padding-bottom: 6rem;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-top: 0;
        padding-bottom: 4rem;
      }
    }

    &__icons {
      display: flex;
    }

    &__preview-image {
      padding: 0;
      flex: 1 0 0;
      display: flex;
      justify-content: center;
      position: relative;
      align-items: flex-start;

      @media (max-width: ${tablet}px) {
        width: auto;
        min-width: none;
      }

      .Image {
        width: 100%;
        border-radius: 1rem;
        aspect-ratio: 4/2;

        &__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      & .ProductDetailsImage {
        position: sticky;
        top: 8rem;
      }
    }

    &__form {
      flex: 1 0.3 0;
      min-width: 38rem;
      height: max-content;
      margin-left: 3.2rem;
      padding: 1.6rem 0;
      padding-bottom: 0;
      border: 0.1rem solid var(--palette-lightgrey-s5-l90);
      border-radius: 1.6rem;

      @media (max-width: ${tablet}px) {
        min-width: 28rem;
        padding-top: 3.2rem;
        margin-top: 1.6rem;
        margin-left: 0;
      }

      @media (max-width: ${mobile}px) {
        width: 100%;
        min-width: auto;
      }
    }

    &__baseInfo-icons {
      display: ${props => (props.isModal ? "none" : "flex")};

      .ProductModal {
        &__share {
          margin-left: 1.6rem;

          .Clickable {
            padding: 0;
          }
        }
      }

      @media (max-width: ${tablet}px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__description {
      &__text {
        font-size: 1.4rem;
        color: var(--palette-black-s0-l10);
        padding: 1.6rem 0;
        font-family: inherit;
        white-space: pre-wrap;
      }
    }

    &__comment-box {
      padding-top: 4.8rem;
    }

    &__share-product {
      &--mobile {
        display: none;
      }

      @media (max-width: ${tablet}px) {
        &--desktop {
          display: none;
        }

        &--mobile {
          bottom: -2.4rem;
          left: unset;
          right: 1.2rem;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: auto;
          padding: 0;
          position: absolute;
          background-color: transparent;
          margin: 0;
        }
      }
    }

    &__share-button {
      display: flex;

      .Clickable {
        padding: 0;
      }

      &__content {
        display: flex;
        align-items: center;
      }

      &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;
        width: 3.6rem;
        height: 3.6rem;
        margin-right: 1.2rem;
        border-radius: 4.8rem;
        background-color: var(--palette-secondary-s35-l95);

        @media (max-width: ${tablet}px) {
          margin: 0;
          width: 4.8rem;
          height: 4.8rem;
        }
      }

      &__text {
        font-size: 1.6rem;
        font-weight: 800;
        color: var(--palette-primary);

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__actions {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: ${getMaxWidth()};
      padding: 1.2rem 1.6rem;
      background-color: var(--palette-white);
      z-index: 3;
      box-shadow: ${props => {
        const { isModal } = props;
        return isModal
          ? "none"
          : "0 -0.1rem 0.8rem 0.1rem var(--palette-gray-s0-l20-a20)";
      }};

      @media (max-width: ${tablet}px) {
        justify-content: space-between;
      }
    }

    &__addToCart {
      width: 100%;
      max-width: 29.6rem;
      margin-left: 7.2rem;

      @media (max-width: ${mobile}px) {
        margin-left: 2rem;
      }

      .Button {
        width: 100%;
        border-radius: 12rem;
        padding: 1.4rem 1.6rem;
        font-weight: 800;

        @media (max-width: ${tablet}px) {
          display: flex;
          align-self: center;
        }
      }
    }

    &__counter {
      @media (max-width: ${tablet}px) {
        display: flex;
        align-self: center;
        order: -1;
      }

      @media (max-width: ${mobile}px) {
        margin-right: 0;
      }
    }
  }
`;

export default ProductMainStyled;
