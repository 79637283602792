import styled from "styled-components";

import { CounterModifierStyledProps as Props } from "./CounterModifier.types";

const CounterModifierStyled = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  padding: 0;
  background-color: var(--palette-gray-s0-l98);

  .CounterModifier {
    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__counter {
      margin-right: 0.8rem;

      .Counter {
        &__quantity {
          font-size: 2rem;
        }

        &__button:disabled {
          path {
            fill: var(--palette-white);
          }
        }
      }
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 800;
      color: var(--palette-black-s0-l20);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &--active {
        font-weight: 600;
      }
    }
  }

  .ModifierPrices {
    margin: 0;
    display: flex;
    flex-direction: row;

    &__unitPrice {
      margin-left: 0.8rem;
    }
  }
`;

export default CounterModifierStyled;
