import styled from "styled-components";

import { ProductStyledProps as Props } from "./Product.types";
import { ProductPlaceholderStyledProps as PlaceholderProps } from "./Product.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const ProductStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  padding-top: ${props =>
    props.talkShop ? "1.6rem" : `var(--sizes-navbar-height)`};
  background-color: var(--palette-white);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas:
    ".      header ."
    ".      main   ."
    "footer footer footer";

  @media (max-width: ${tablet}px) {
    padding-top: ${props =>
      props.talkShop
        ? `calc(var(--sizes-navbar-height-mobile) - env(safe-area-inset-top))`
        : `var(--sizes-navbar-height-mobile-without-search)`};
  }

  & .Pocket {
    &__header {
      padding: 0.8rem 0;
      grid-area: header;

      &__title {
        font-size: 1.6rem;
        color: var(--palette-black-s0-l10);
      }
    }
  }

  .Product {
    &__header {
      .Product__shareButton {
        position: relative;
        top: -0.1rem;

        .Clickable {
          padding: 0;
        }
      }
    }

    &__navbar {
      @media (max-width: ${tablet}px) {
        display: grid;
      }
    }

    &__main {
      grid-area: main;

      &__header {
        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__topActions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.4rem 0;

      @media (max-width: 851px) {
        width: 100%;
      }

      .Product__shareButton {
        display: block;

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__go-back {
      .GoBack__icon {
        padding: 0;

        svg {
          width: 1.6rem;
        }
      }
    }

    &__shareButton {
      display: flex;

      @media (max-width: ${tablet}px) {
        display: none;
      }

      .Clickable {
        padding: 0;
      }

      &__content {
        display: flex;
        align-items: center;
      }

      &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.6rem;
        height: 3.6rem;
        margin-right: 1.2rem;
        border-radius: 4.8rem;
        background-color: var(--palette-secondary-s35-l95);
      }

      &__text {
        font-size: 1.6rem;
        font-weight: 800;
        color: var(--palette-primary);
      }
    }

    &__footer {
      grid-area: footer;
      margin-bottom: 7.4rem;
    }
  }

  && .ProductBaseInfo {
    &__name {
      white-space: normal;
    }
  }
`;

export const ProductPlaceholderStyled = styled.div<PlaceholderProps>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  grid-area: col;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
    align-items: center;
  }

  .ProductPlaceholder {
    &__image-container {
      display: flex;
      justify-content: center;
      flex: 1 0 0;
      display: flex;
      height: max-content;

      @media (max-width: ${tablet}px) {
        width: 100%;
        min-width: 0;
      }
    }

    &__image {
      width: 100%;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      aspect-ratio: 4/2;

      @media (max-width: ${tablet}px) {
        width: 100%;
      }
    }

    &__form {
      flex: 1 0.3 0;
      padding-left: 3.2rem;

      @media (max-width: ${tablet}px) {
        width: 100%;
        min-width: 32rem;
        padding-top: 3.2rem;
        padding-right: 1.6rem;
        padding-left: 1.6rem;
      }

      @media (max-width: ${mobile}px) {
        min-width: 28rem;
      }
    }

    &__name {
      width: 90%;
      height: 2.4rem;
      background-color: var(--palette-gray-s0-l95);
      margin-bottom: 1.6rem;
      border-radius: 0.4rem;
    }

    &__price {
      width: 50%;
      height: 3.2rem;
      background-color: var(--palette-gray-s0-l95);
      margin-bottom: 1.6rem;
      border-radius: 0.4rem;
    }

    &__description {
      width: 80%;
      height: 5rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }

    &__actions {
      margin-top: 1.6rem;
      display: flex;
      align-items: center;
    }

    &__counter {
      width: 15%;
      height: 3.2rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      margin-right: 3.2rem;
    }

    &__button {
      width: 40%;
      height: 4rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }

    &__modifierGroup {
      width: 100%;
      margin-top: 3.2rem;
      display: flex;
      flex-direction: column;

      &-name {
        width: 60%;
        height: 2.4rem;
        background-color: var(--palette-gray-s0-l95);
        margin-bottom: 1.6rem;
        border-radius: 0.4rem;
      }

      &-description {
        width: 40%;
        height: 2rem;
        background-color: var(--palette-gray-s0-l95);
        margin-bottom: 3.2rem;
        border-radius: 0.4rem;
      }
    }

    &__modifier {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      align-items: center;
    }

    &__modifier-field {
      width: 10%;
      height: 2rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }

    &__modifier-name {
      width: 40%;
      height: 2rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }

    &__modifier-price {
      width: 20%;
      height: 3.2rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }
  }
`;

export default ProductStyled;
