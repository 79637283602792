import React from "react";

import Styles from "./Button.styles";
import { ButtonProps as Props } from "./Button.types";

import SpinnerSVG from "../../../../public/assets/images/spinner.svg";

const Button: React.FC<Props> = props => {
  return <Styles {...props} loadingIcon={<SpinnerSVG />} />;
};

Button.defaultProps = {};

export default Button;
