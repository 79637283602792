import styled from "styled-components";

import { RedeemCouponInputStyledProps as Props } from "./RedeemCouponInput.types";

const RedeemCouponInputStyled = styled.div<Props>`
  position: relative;
  width: 100%;

  .RedeemCouponInput {
    &__text-input {
      &.TextInput {
        input.TextInput__input {
          padding-right: 8.8rem;

          &::placeholder {
          }
        }
      }
    }

    &__input-button {
      position: absolute;
      height: 3.8rem;
      right: 0;
      bottom: 0;
      margin-right: 1.6rem;
      padding: 0;
      font-size: 1.6rem;
      font-weight: 800;
      color: var(--palette-primary);
      z-index: 1;
    }
  }
`;

export default RedeemCouponInputStyled;
