// PasswordStrengthLines helper functions and data
import { Strength } from "../PasswordStrength.types";
import { StrengthClassName } from "./PasswordStrengthLines.types";

export const mapStrengthToClassName = (
  strength: Strength
): StrengthClassName => {
  switch (strength) {
    case "Too weak":
      return "weak";
    case "Weak":
      return "weak";
    case "Medium":
      return "medium";
    case "Strong":
      return "strong";
    default:
      return "weak";
  }
};
