import { Storage } from "@capacitor/storage";
import { events } from "@artisan-commerce/analytics-capacitor";
import { getAuth, signOut as fSignOut } from "firebase/auth";
import { FirebaseAuthentication as FirebaseAuth } from "@capacitor-firebase/authentication";

import CONSTANTS from "config/constants";
import useBillingData from "contexts/billingData/billingData.context.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import useGeo from "contexts/geo/geo.hooks";
import usePayments from "contexts/payments/payments.context.hooks";
import useProducts from "contexts/products/products.context.hooks";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import { useShoppingCart } from "contexts/shoppingCart/shoppingCart.context.hooks";
import useCountries from "contexts/countries/countries.hooks";
import useVendors from "contexts/vendors/vendors.hooks";
import { useFetchUser } from "services/user/user.service.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import { notify } from "utils/common.utils";

const { STORAGE } = CONSTANTS;
const { BILLING_DATA_TOKEN } = STORAGE;
const { CATEGORY_TOKEN, SELECTED_COORDINATES_TOKEN, CARD_TOKEN } = STORAGE;
const { SHIPPING_ADDRESS_TOKEN, THEME_PREFERENCE_TOKEN } = STORAGE;
const { TRANSFER_ANONYMOUS_ID } = STORAGE;
const { logSignOut } = events.auth;

const useSignOut = () => {
  const { setNotificationRegister } = useAuth();
  const { resetBillingContext } = useBillingData();
  const { resetCataloguesContext } = useCatalogues();
  const { resetGeoContext } = useGeo();
  const { resetPaymentsContext } = usePayments();
  const { resetProductsContext } = useProducts();
  const { resetShoppingCartContext } = useShoppingCart();
  const { resetShippingAddressContext } = useShippingAddress();
  const { resetCountriesContext } = useCountries();
  const { resetVendorsContext } = useVendors();
  const { data: user } = useFetchUser();

  const cleanLocalStorage = () => {
    const tokens = [
      SHIPPING_ADDRESS_TOKEN,
      BILLING_DATA_TOKEN,
      CATEGORY_TOKEN,
      THEME_PREFERENCE_TOKEN,
      SELECTED_COORDINATES_TOKEN,
      CARD_TOKEN,
      TRANSFER_ANONYMOUS_ID
    ];
    tokens.forEach(token => {
      Storage.remove({
        key: token
      });
    });
  };

  const signOut = async () => {
    const auth = getAuth();

    try {
      cleanLocalStorage();
      resetBillingContext();
      resetCataloguesContext();
      resetCountriesContext();
      resetGeoContext();
      resetPaymentsContext();
      resetProductsContext();
      resetShoppingCartContext();
      resetShippingAddressContext();
      resetVendorsContext();
      await FirebaseAuth.signOut();
      await fSignOut(auth);
      window.scrollTo(0, 0);
      setNotificationRegister(true);
      logSignOut({ userId: user?.uid ?? "", name: user?.name ?? "" });
      window.location.reload();
    } catch (e) {
      notify(e, "Sign out");
      console.error(e.message);
    }
  };

  return signOut;
};

export default useSignOut;
