// Checkout helper functions and data
import { AnonymousBillingValues } from "types/payment.types";
import { AnonymousUserValues } from "types/payment.types";
import { BillingValuesAction } from "./checkout.context.types";
import { UserValuesAction } from "./checkout.context.types";

export const userInitialState: AnonymousUserValues = {
  documentType: "PASSPORT",
  document: "",
  name: "",
  email: "",
  phone: ""
};

export const billingInitialState: AnonymousBillingValues = {
  ...userInitialState,
  address: ""
};

export const userReducer = (
  state: AnonymousUserValues,
  action: UserValuesAction
): AnonymousUserValues => {
  return { ...state, [action.type]: action.payload };
};

export const billingReducer = (
  state: AnonymousBillingValues,
  action: BillingValuesAction
): AnonymousBillingValues => {
  return { ...state, [action.type]: action.payload };
};
