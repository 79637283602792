// Auth context
import React, { createContext, FC, useCallback, useMemo } from "react";
import { UserInfo, signInWithCredential } from "firebase/auth";
import { GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";
import { FacebookAuthProvider, unlink, getAuth } from "firebase/auth";
import { signInWithPopup } from "firebase/auth";
import { linkWithCredential } from "firebase/auth";
import { signInAnonymously as fSignInAnonymously } from "firebase/auth";
import { signInWithEmailAndPassword as fsignInWithEmailAndPassword } from "firebase/auth";
import { sendPasswordResetEmail as fsendPasswordResetEmail } from "firebase/auth";
import { useEffect, useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import { FirebaseAuthentication as FirebaseAuth } from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";

import { AuthProviderProps as Props } from "./auth.context.types";
import { AuthProviderValue } from "./auth.context.types";
import { auth, facebookProvider } from "config/artisn.config";
import { updateUser } from "utils/updateUser";

// @ts-ignore
export const AuthContext = createContext<AuthProviderValue>({});

const AuthProvider: FC<Props> = props => {
  const [isAnonymous, setIsAnonymous] = useState<boolean>(true);
  const [uid, setUid] = useState("");
  const [providerData, setProviderData] = useState<(UserInfo | null)[]>([]);
  const [notificationRegister, setNotificationRegister] = useState(true);
  const [termsAndPrivacyModal, setTermsAndPrivacyModal] = useState(false);
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;

  const signInAnonymously = async () => {
    const auth = getAuth();
    return await fSignInAnonymously(auth);
  };

  const subscriber = useCallback(async user => {
    updateUser(user);
    const { uid: userId, isAnonymous: isUserAnonymous } = user ?? {};
    const { providerData: provider } = user ?? {};
    setIsAnonymous(!!isUserAnonymous);
    if (provider) {
      setProviderData(provider);
    }
    if (userId) {
      setUid(userId);
      return;
    }
    signInAnonymously();
  }, []);

  useEffect(() => {
    const authSubscriber = auth.onAuthStateChanged(subscriber);

    return authSubscriber;
  }, [subscriber]);

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    const auth = getAuth();
    return await fsignInWithEmailAndPassword(auth, email, password);
  };

  const sendPasswordResetEmail = async (email: string) => {
    const auth = getAuth();
    return await fsendPasswordResetEmail(auth, email);
  };

  const signInWithGoogle = useCallback(async () => {
    if (isMobile) {
      const { credential } = await FirebaseAuth.signInWithGoogle();
      const gcredential = GoogleAuthProvider.credential(credential?.idToken);
      const auth = getAuth();
      const { user } = await signInWithCredential(auth, gcredential);
      return user;
    }
    const provider = new GoogleAuthProvider();
    const credentials = await signInWithPopup(auth, provider);
    const { user } = credentials;
    return user;
  }, [isMobile]);

  const unlinkGoogle = useCallback(async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    if (!auth?.currentUser) return;
    const user = await unlink(auth?.currentUser, provider.providerId);
    if (user) subscriber(user);
  }, [subscriber]);

  const signInWithFacebook = useCallback(async () => {
    const { credential } = await FirebaseAuth.signInWithFacebook();
    if (!credential?.accessToken) throw new Error("Facebook no credentials");
    const fbCredential = FacebookAuthProvider.credential(
      credential?.accessToken
    );
    const auth = getAuth();
    const { user } = await signInWithCredential(auth, fbCredential);
    return user;
  }, []);

  const unlinkFacebook = useCallback(async () => {
    const auth = getAuth();
    const provider = new FacebookAuthProvider();
    if (!auth?.currentUser) return;
    const user = await unlink(auth?.currentUser, provider.providerId);
    if (user) subscriber(user);
  }, [subscriber]);

  const signInWithApple = useCallback(async () => {
    const { user } = await FirebaseAuth.signInWithApple({
      skipNativeAuth: false
    });
    const { uid, isAnonymous: isUserAnonymous, email } = user ?? {};
    const { displayName, providerId } = user ?? {};
    setIsAnonymous(!!isUserAnonymous);
    if (uid) {
      setUid(uid);
    }

    // Solo en el primer registro el proveedor de Apple te devuelve displayName
    if (email && providerId && uid) {
      setProviderData([
        {
          displayName: displayName ?? "",
          email,
          phoneNumber: "",
          photoURL: "",
          providerId,
          uid
        }
      ]);
    }

    return user;
  }, []);

  const registerWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      const auth = getAuth();
      const credential = EmailAuthProvider.credential(email, password);
      if (!auth?.currentUser) return;
      const response = await linkWithCredential(auth.currentUser, credential);
      if (response) subscriber(response.user);
      return response;
    },
    [subscriber]
  );

  const getEmail = useCallback(() => {
    const auth = getAuth();
    return auth?.currentUser?.email ?? providerData[0]?.email;
  }, [providerData]);

  useEffect(() => {
    const auth = getAuth();
    const { conversionsApiConfig } = facebookProvider;
    const { countryISOCode, eventSourceUrl } = conversionsApiConfig ?? {};
    const { fbDataSourceId } = conversionsApiConfig ?? {};
    const { fbAccessToken, apiURL } = conversionsApiConfig ?? {};

    if (!countryISOCode || !apiURL) return;
    if (!fbDataSourceId || !fbAccessToken) return;

    const idTokenSubscribe = auth.onIdTokenChanged(async user => {
      if (!user) return;
      const token = await user.getIdToken();
      facebookProvider.updateConfig({
        conversionsApiConfig: {
          ...facebookProvider.conversionsApiConfig,
          countryISOCode,
          apiURL,
          eventSourceUrl: eventSourceUrl!,
          fbDataSourceId,
          fbAccessToken,
          authToken: `Bearer ${token}`
        }
      });
    });

    return idTokenSubscribe;
  }, []);

  // @ts-ignore
  const value: AuthProviderValue = useMemo(() => {
    return {
      notificationRegister,
      setNotificationRegister,
      isAnonymous,
      signInWithEmailAndPassword,
      signInWithGoogle,
      signInWithFacebook,
      signInWithApple,
      signInAnonymously,
      sendPasswordResetEmail,
      registerWithEmailAndPassword,
      uid,
      email: getEmail(),
      unlinkFacebook,
      unlinkGoogle,
      providerData,
      setProviderData,
      termsAndPrivacyModal,
      setTermsAndPrivacyModal
    };
  }, [
    notificationRegister,
    isAnonymous,
    signInWithGoogle,
    signInWithFacebook,
    signInWithApple,
    registerWithEmailAndPassword,
    uid,
    getEmail,
    unlinkFacebook,
    unlinkGoogle,
    providerData,
    termsAndPrivacyModal
  ]);

  return (
    <AuthContext.Provider value={value}>
      <ContextDevTool context={AuthContext} id="auth" displayName="Auth" />
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
