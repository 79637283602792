import styled from "styled-components";

import { CounterStyledProps as Props } from "./Counter.types";

const CounterStyled = styled.div<Props>`
  display: inline-block;
  border-radius: 0.4rem;
  background-color: transparent;

  .Counter {
    &__quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 1.6rem;
      font-size: 2rem;
      font-weight: 600;
      color: var(--palette-black);
      background-color: transparent;
      border-radius: 0.4rem;
    }

    &__button {
      width: 2.4rem;
      height: 2.4rem;
      padding: 0;
      border: 0;
      border-radius: 2.4rem;
      background-color: var(--palette-primary);
      box-shadow: 0 0 0 0;

      &:disabled {
        background-color: var(--palette-gray-s0-l75);
        border: 0;
        box-shadow: var(--palette-gray-s0-l75) 0 0 0 0.02rem;

        path {
          fill: var(--palette-white);
        }
      }
    }
  }
`;

export default CounterStyled;
