import React from "react";
import { ButtonGroup } from "artisn-ui-react";

import Styles from "./BenefitsList.styles";
import CouponDetail from "../CouponDetail/CouponDetail";
import { BenefitsListProps as Props } from "./BenefitsList.types";

import EmptySVG from "../../../../public/assets/images/empty-benefit-list.svg";

const { Button } = ButtonGroup;

const BenefitsList: React.FC<Props> = props => {
  const { className, benefits, showRemove = true, inCart = false } = props;
  const { selectedBenefit, setSelectedBenefit } = props;
  const empty = benefits && benefits.length === 0;

  const emptyNode = (
    <div className="BenefitsList__empty">
      <EmptySVG />
      <p className="BenefitsList__empty__title">Añade cupones</p>
      <p className="BenefitsList__empty__message">
        Con tus cupones podrás obtener descuentos y muchos más beneficios
      </p>
    </div>
  );

  const changeHandler = (benefitId?: number) => {
    const benefit = benefits?.find(item => item.benefitId === benefitId);
    setSelectedBenefit?.(benefit);
  };

  return (
    <Styles className={`BenefitsList ${className}`}>
      {empty ? emptyNode : null}
      <ButtonGroup
        className="BenefitsList__buttonGroup"
        active={selectedBenefit?.benefitId.toString()}
        onChange={e => changeHandler(e.target.value)}
      >
        {benefits?.map((item, index) => {
          const { benefitId } = item;
          const selected = selectedBenefit?.benefitId === benefitId;
          return (
            <Button
              disabled={inCart}
              className="BenefitsList__button"
              value={benefitId}
              type="LINK"
              id={benefitId.toString()}
              key={index}
            >
              <CouponDetail
                key={index}
                inCart={inCart}
                showRemove={showRemove}
                benefit={item}
                selected={selected}
              />
            </Button>
          );
        })}
      </ButtonGroup>
    </Styles>
  );
};

BenefitsList.defaultProps = {};

export default BenefitsList;
