import styled from "styled-components";

import { CartPayButtonStyledProps as Props } from "./CartPayButton.types";

const CartPayButtonStyled = styled.div<Props>`
  position: ${props =>
    props.talkShop && !props.isShoppingCartDrawer ? "fixed" : "static"};
  width: 100%;
  bottom: ${props => (props.talkShop ? "1.6rem" : "0")};
  z-index: 4;
  padding: ${props => (props.talkShop ? "0 1.2rem" : "0")};

  &&.CartPayButton {
    .Cart__summary__button {
      width: 100%;
      padding: 1.6rem 2.4rem;
      border-radius: 12rem;
      justify-content: space-between;
      font-size: 1.6rem;
      font-weight: ${props => (props.talkShop ? "600" : "500")};
    }
  }

  .CartPayButton {
    &__amount,
    &__total {
      color: var(--palette-white);
      font-size: 1.6rem;
      display: flex;
      align-items: center;
    }
  }
`;

export default CartPayButtonStyled;
