import styled from "styled-components";

import { RedeemCouponStyledProps as Props } from "./RedeemCoupon.types";

const RedeemCouponStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;

  .Badge {
    &__value {
      min-width: 2.4rem;
      height: 2rem;
      padding: 0.4rem;
      font-size: 1.2rem;
      font-weight: 800;
      color: var(--palette-primary);
      background-color: var(--palette-yellow-s93-l51);
      z-index: 1;
    }
  }

  .RedeemCoupon {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__redeem-error {
      padding-top: 0.8rem;
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }

    &__button-wallet {
      width: 4.6rem;
      height: 4.6rem;
      padding: 0;
      background-color: var(--palette-primary);
      border: 0.1px solid var(--palette-primary);
      border-radius: 50%;

      svg {
        width: 2.6rem;
        height: 1.7rem;

        path {
          fill: var(--palette-white);
        }
      }
    }

    &__input {
      z-index: 1;
    }

    &__badge {
      margin-left: 0.8rem;
      z-index: 1;
    }
  }
`;

export default RedeemCouponStyled;
