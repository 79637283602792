import { useContext } from "react";

import { CheckoutContext } from "./checkout.context";
import { CheckoutProviderValue } from "./checkout.context.types";

const useCheckout = () => {
  const context = useContext<CheckoutProviderValue>(CheckoutContext);
  if (typeof context === "undefined") {
    throw new Error("useCheckout must be used within a CheckoutProvider");
  }
  return context;
};

export default useCheckout;
