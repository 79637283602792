import React, { memo, useEffect, useRef, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { KeyboardInfo } from "@capacitor/keyboard";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { doc, onSnapshot } from "firebase/firestore";

import Styles from "./Layout.styles";
import { LayoutProps as Props } from "./Layout.types";
import useListeners from "hooks/useListeners";
import useSetupArtisn from "hooks/artisn/useSetupArtisn";
import ShareCartModal from "components/global/ShareCartModal/ShareCartModal";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";
import { getENVs, firestore } from "config/artisn.config";
import useCountries from "contexts/countries/countries.hooks";
import StoreNotification from "components/global/notifications/StoreNotification/StoreNotification";
import MergeCartModal from "components/global/MergeCartModal/MergeCartModal";
import OutOfServiceModal from "components/global/OutOfServiceModal/OutOfServiceModal";
import UpdateAppModal from "components/global/UpdateAppModal/UpdateAppModal";
import ShoppingCartDrawer from "components/global/drawer/ShoppingCartDrawer/ShoppingCartDrawer";
import { useShoppingCart as useShoppingCartContext } from "contexts/shoppingCart/shoppingCart.context.hooks";
import ShoppingCartNotifications from "components/global/notifications/ShoppingCartNotifications/ShoppingCartNotifications";
import useTheme from "contexts/theme/theme.context.hooks";
import useProducts from "contexts/products/products.context.hooks";
import ProductModal from "../ProductModal/ProductModal";
import { notify } from "utils/common.utils";
import SearchBarProductsDrawer from "components/global/drawer/SearchBarProductsDrawer/SearchBarProductsDrawer";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import TermsAndPrivacyModal from "components/home/TermsAndPrivacyModal/TermsAndPrivacyModal";
import useAuth from "contexts/auth/auth.context.hooks";
import useCoupons from "hooks/useCoupons/useCoupons";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import "config/services.config";
import usePagoPlux3ds from "hooks/usePagoPlux3ds";

const { TITLE, FEATURE_FLAGS, GENERAL, ARTISN, APP } = CONSTANTS;
const { WITH_SHARE_SHOPPING_CART, WITH_PRODUCT_MODAL } = FEATURE_FLAGS;
const { WITH_ANONYMOUS_SHOPPING_CART_TOKEN, WITH_CART_DRAWER } = FEATURE_FLAGS;
const { WITH_PURCHASE } = FEATURE_FLAGS;
const { VERSION_CODE } = GENERAL;
const { ACCOUNT_ID } = ARTISN;
const { APPLE_STORE_URL, PLAY_STORE_URL, WEB_URL } = APP;

const Layout: React.FC<Props> = props => {
  const { children } = props;
  useSetupArtisn();
  useListeners();
  const { locale } = useCountries().selectedCountry;
  const { openShoppingCartDrawer } = useShoppingCartContext();
  const { setOpenShoppingCartDrawer } = useShoppingCartContext();
  const { listenShoppingCart } = useShoppingCart();
  const { listenBenefitsWallet } = useCoupons();
  const { setOpenProductModal } = useProducts();
  const { openProductModal } = useProducts();
  const { openSearchBarDrawer } = useProducts();
  const { setOpenSearchBarDrawer } = useProducts();
  const [remoteVersionCode, setRemoteVersionCode] = useState("");
  const [updateApp, setUpdateApp] = useState(false);
  const { setKeyboardHeight } = useTheme();
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;
  const { push } = useRouter();
  const { setShowBackdropAddressDropdown } = useShippingAddress();
  const firstRender = useRef(false);
  const auth = useAuth();
  const { verifyPayment } = usePagoPlux3ds();
  const { termsAndPrivacyModal, setTermsAndPrivacyModal } = auth;

  useEffect(() => {
    if (firstRender.current) return;
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      setShowBackdropAddressDropdown(false);
      setOpenProductModal("");
      setOpenShoppingCartDrawer(false);
      const slug = event.url.split(".ec").pop();
      const url = JSON.stringify(slug);
      if (url.includes("products")) {
        const arr = url.split("/");
        const [productId] = arr.filter(
          str => !isNaN(Number(str)) && !isNaN(parseFloat(str))
        );
        setOpenProductModal(productId);
        return;
      }
      if (url.includes("checkout")) {
        verifyPayment(url);
        return;
      }
      if (
        slug &&
        !(
          url.includes("profile") ||
          url.includes("checkout") ||
          url.includes("cart") ||
          url.includes("signin") ||
          url.includes("signup") ||
          url.includes("privacy") ||
          url.includes("recover") ||
          url.includes("terms") ||
          url.includes("update-password")
        )
      ) {
        push(slug);
      }
    });
    firstRender.current = true;
  }, [
    push,
    setOpenProductModal,
    setOpenShoppingCartDrawer,
    setShowBackdropAddressDropdown,
    verifyPayment
  ]);

  useEffect(() => {
    if (isIOS) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }

    if (isMobile) {
      StatusBar.setStyle({ style: Style.Light });
    }

    if (isAndroid) {
      StatusBar.setBackgroundColor({ color: variables.palette["white-hex"] });
    }
  }, [isAndroid, isIOS, isMobile]);

  useEffect(() => {
    return listenShoppingCart?.();
  }, [listenShoppingCart]);

  useEffect(() => {
    return listenBenefitsWallet?.();
  }, [listenBenefitsWallet]);

  useEffect(() => {
    (async () => {
      try {
        if (
          isMobile &&
          remoteVersionCode &&
          remoteVersionCode !== "0" &&
          remoteVersionCode !== VERSION_CODE
        ) {
          setUpdateApp(remoteVersionCode > VERSION_CODE);
          return;
        }
        setUpdateApp(false);
      } catch (e) {
        notify(e, "Remote version code");
        console.error(e);
      }
    })();
  }, [isMobile, remoteVersionCode]);

  useEffect(() => {
    onSnapshot(doc(firestore, `appParameters/${ACCOUNT_ID}`), doc => {
      if (doc.exists()) {
        setRemoteVersionCode(doc.data()?.versionCode);
      }
    });
  }, []);

  useEffect(() => {
    if (isMobile) {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        setKeyboardHeight(info.keyboardHeight);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        setKeyboardHeight(undefined);
      });
    }

    return () => {
      if (isMobile) {
        Keyboard.removeAllListeners();
      }
    };
  }, [isMobile, setKeyboardHeight]);

  return (
    <Styles className="Layout">
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1, viewport-fit=cover, maximum-scale=1"
        />
        <script
          defer
          src={`https://maps.googleapis.com/maps/api/js?key=${getENVs?.mapsApiKey}&libraries=places&language=${locale}`}
        />
        <title>{TITLE}</title>
      </Head>
      {children}
      <StoreNotification />
      {WITH_SHARE_SHOPPING_CART ? <ShareCartModal /> : null}
      {WITH_ANONYMOUS_SHOPPING_CART_TOKEN ? <MergeCartModal /> : null}
      {isMobile && remoteVersionCode === "0" ? (
        <OutOfServiceModal url={WEB_URL} />
      ) : null}
      {updateApp ? (
        <UpdateAppModal storeUrl={isIOS ? APPLE_STORE_URL : PLAY_STORE_URL} />
      ) : null}
      {WITH_CART_DRAWER && WITH_PURCHASE ? (
        <ShoppingCartDrawer
          opened={openShoppingCartDrawer}
          onClose={() => setOpenShoppingCartDrawer(prev => !prev)}
        />
      ) : null}
      <SearchBarProductsDrawer
        opened={openSearchBarDrawer}
        onClose={() => setOpenSearchBarDrawer(prev => !prev)}
      />
      {WITH_PRODUCT_MODAL ? (
        <ProductModal
          isOpen={!!openProductModal}
          onClose={() => setOpenProductModal("")}
          productId={openProductModal}
        />
      ) : null}
      <ShoppingCartNotifications />
      <TermsAndPrivacyModal
        isModalVisible={termsAndPrivacyModal}
        setIsModalVisible={setTermsAndPrivacyModal}
      />
    </Styles>
  );
};

Layout.defaultProps = {};

export default memo(Layout);
