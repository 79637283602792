import { useContext } from "react";

import { GeoContext } from "./geo.context";
import { GeoProviderValue } from "./geo.context.types";

const useGeo = () => {
  const context = useContext<GeoProviderValue>(GeoContext);

  if (typeof context === "undefined") {
    throw new Error("useGeo must be used within a GeoProvider");
  }
  return context;
};

export default useGeo;
