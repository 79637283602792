// Order builder functions
import { utils } from "artisn/builders";

import { IncompleteOrder } from "types/order.types";

const { genNumericId, genDate, genId, genWord } = utils;

export const buildIncompleteOrder = (
  overrides: Partial<IncompleteOrder> = {}
): IncompleteOrder => {
  return {
    id: genNumericId(),
    created_at: genDate(),
    issue_id: null,
    payment_status: "CANCELED",
    seq_val: genId(),
    status_category: "IN_PROGRESS",
    status_code: "PAYMENT_IN_PROGRESS",
    status_id: genNumericId(),
    status_name: genWord(),
    uid: genId(),
    ...overrides
  };
};

export const genIncompleteOrders = (quantity?: number): IncompleteOrder[] => {
  const num = quantity ?? Math.floor(Math.random() * 2) + 1;

  const incompleteOrders = [];
  for (let i = 0; i < num; i++) {
    const method = buildIncompleteOrder();
    incompleteOrders.push(method);
  }
  return incompleteOrders;
};
