import styled from "styled-components";

import { InfoActionModalStyledProps as Props } from "./InfoActionModal.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const InfoActionModalStyled = styled.div<Props>`
  width: ${props => (props.isRating ? "60rem" : "100%")};
  max-width: ${props => (props.isRating ? "60rem" : "45rem")};
  min-width: 32rem;
  padding: 3.2rem;
  padding-top: ${props => (props.isRating ? "3.6rem" : "6.4rem")};
  padding-bottom: ${props =>
    props.isRating && !props.hideButtons ? "9.2rem" : "3.2rem"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${tablet}px) {
    min-height: 100vh;
    width: 100vw;
    padding-top: 2.4rem;
  }

  &&& .InfoActionModal {
    &__icon {
      width: 64px;
      height: 64px;
    }

    &__title {
      padding-top: ${props => (props.hasIcon ? "5.6" : "3.2")}rem;
      font-size: 2.4rem;
      font-weight: 400;
      text-align: center;
      margin-bottom: ${props => (props.isRating ? "4.4rem" : "0")};
    }

    &__description {
      padding-top: 3.2rem;
      text-align: center;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
      color: var(--palette-black-s0-l20);
    }

    &__buttons {
      display: flex;
      width: 100%;
      padding-top: 6.4rem;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
      }
    }

    &__button {
      flex: 1;
      padding: 1.6rem 2rem;
      font-size: 1.6rem;
      font-weight: 600;
      border-radius: 12rem;
      border: none;

      &:hover {
        border: none;
      }

      &:not(:first-child) {
        margin-left: 0.8rem;

        @media (max-width: ${tablet}px) {
          margin-left: 0;
          margin-top: 0.8rem;
        }
      }

      .Button__wrapper {
        white-space: nowrap;
      }
    }

    &__confirm-button {
      background-color: var(--palette-primary);
      color: var(--palette-white);
    }

    &__cancel-button {
      background-color: var(--palette-secondary-s35-l95);
      color: var(--palette-primary);
    }
  }

  .RateModal {
    &__rate {
      &__order {
        text-align: center;
        color: var(--palette-gray-s0-l50);
        margin-top: 1.6rem;
        margin-bottom: 3.8rem;
      }
    }

    &__answers {
      width: 100%;
      margin-top: 4rem;
      margin-bottom: 6.6rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 2rem;

      @media (max-width: ${mobile}px) {
        display: flex;
        flex-direction: column;
      }

      &__answer {
        display: flex;
        align-items: center;

        &__image {
          width: 7rem;
          height: 7rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--palette-gray-s0-l75);
          border-radius: 1rem;
        }
        &__text {
          margin-left: 1.6rem;
          max-width: 12rem;
          font-size: 1.6rem;
        }
      }
    }

    &__finish {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        font-size: 3.6rem;
        line-height: 4rem;
        font-weight: 500;
        text-align: center;
        margin-top: 5.6rem;
        margin-bottom: 10rem;
        max-width: 40rem;
      }
    }

    &__buttons {
      display: flex;
      width: 100%;
      border-top: 0.1rem solid var(--palette-lightgrey-s5-l90);
      position: absolute;
      bottom: 0;
      height: 6rem;
      margin-top: 5.6rem;

      &__button {
        flex: 1;
        text-align: center;
        font-weight: 800;
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
        padding: 0;
        box-shadow: none;
        border: none;
        background-color: var(--palette-white);
        border-radius: 0;

        &:hover,
        &:hover:not([disabled]),
        &:focus {
          padding: 0;
          box-shadow: none;
          border: none;
          background-color: var(--palette-white);
        }
      }

      &__cancel-button {
        border-right: 0.1rem solid var(--palette-lightgrey-s5-l90);
        color: var(--palette-black-s0-l20);

        &:hover,
        &:hover:not([disabled]),
        &:focus {
          border-right: 0.1rem solid var(--palette-lightgrey-s5-l90);
        }
      }

      &__confirm-button {
        color: var(--palette-primary);

        &:disabled {
          background-color: var(--palette-white);
          color: var(--palette-primary);
        }
      }
    }
  }
`;

export default InfoActionModalStyled;
