import React, { MouseEvent } from "react";
import { Button } from "artisn-ui-react";

import useOnMount from "hooks/useOnMount";
import Styles from "./RadioButtonModifier.styles";
import ModifierPrices from "../ModifierPrices/ModifierPrices";
import useProducts from "contexts/products/products.context.hooks";
import { RadioButtonModifierProps as Props } from "./RadioButtonModifier.types";
import { getMostChosenOptionKey } from "utils/product.utils";

const RadioButtonModifier: React.FC<Props> = props => {
  const { modifier, disabled = false, preferences } = props;
  const { amount, name, totals, id, handleChange, groupId } = modifier;
  const { selectedProduct } = useProducts();

  const iconActiveClassName = amount ? "RadioButtonModifier__icon--active" : "";
  const textActiveClassName = amount ? "RadioButtonModifier__text--active" : "";

  const onClickHandle = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    if (amount) {
      handleChange(0);
      return;
    }
    handleChange(1);
  };

  useOnMount(() => {
    const mostChosenOptionKey = getMostChosenOptionKey(preferences);
    if (selectedProduct || !mostChosenOptionKey) return;
    const key = `${groupId}-${id}`;
    if (key === mostChosenOptionKey) {
      handleChange(preferences[mostChosenOptionKey].amount);
    }
  });

  return (
    <Styles className="RadioButtonModifier" disabled={disabled}>
      <div className="RadioButtonModifier__info">
        <p className={`RadioButtonModifier__title ${textActiveClassName}`}>
          {name}
        </p>
        <ModifierPrices totals={totals} />
      </div>
      <Button
        className="RadioButtonModifier__button"
        id={id}
        onClick={onClickHandle}
        value={amount}
      >
        <div className={`RadioButtonModifier__icon ${iconActiveClassName}`} />
      </Button>
    </Styles>
  );
};

RadioButtonModifier.defaultProps = {};

export default RadioButtonModifier;
