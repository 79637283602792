import React, { createContext, useEffect, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import { Storage } from "@capacitor/storage";

import { CheckoutInfo, ThreeDsPayload } from "./payments.context.types";
import { PaymentsProviderProps as Props } from "./payments.context.types";
import { PaymentProviderId } from "./payments.context.types";
import { PaymentMethodId } from "./payments.context.types";
import { PaymentsProviderValue } from "./payments.context.types";
import { Card } from "types/payment.types";
import { OtpValidationPayload } from "services/payments/pagoPlux/pagoPlux.service.types";
import { PagoPlux3dsTokens } from "services/payments/pagoPlux/pagoPlux.service.types";
import useAuth from "contexts/auth/auth.context.hooks";
import CONSTANTS from "config/constants";

const { THREE_DS_PAYLOAD } = CONSTANTS.STORAGE;

// @ts-ignore
export const PaymentsContext = createContext<PaymentsProviderValue>();

const PaymentsProvider: React.FC<Props> = props => {
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    PaymentMethodId.OTHER
  );
  const [selectedPaymentProviderId, setSelectedPaymentProviderId] =
    useState<PaymentProviderId>();
  const [selectedCard, setSelectedCard] = useState<Card>();
  const [showModalOtpPagoPlux, setShowModalOtpPagoPlux] = useState(false);
  const [otpRequest, setOtpRequest] = useState<OtpValidationPayload>();
  const [showModal3dsPagoPlux, setShowModal3dsPagoPlux] = useState(false);
  const [threeDSRequest, setThreeDSRequest] = useState<ThreeDsPayload>();
  const [new3dsTokens, setNew3dsTokens] = useState<PagoPlux3dsTokens>();
  const [checkoutInfo, setCheckoutInfo] = useState<CheckoutInfo>();
  const { uid } = useAuth();

  const resetPaymentsContext = () => {
    setSelectedPaymentMethodId(PaymentMethodId.OTHER);
    setSelectedPaymentProviderId(undefined);
    setSelectedCard(undefined);
  };

  useEffect(() => {
    // Recuperamos el tresDSRequest del storage
    (async () => {
      const { value } = await Storage.get({ key: `${THREE_DS_PAYLOAD}${uid}` });
      const threeDSRequest: ThreeDsPayload & CheckoutInfo = value
        ? JSON.parse(value)
        : undefined;
      if (threeDSRequest) {
        const { card, order, threeDS } = threeDSRequest;
        const { cardNumber, comment, isPagoPlux, cardFormValues } =
          threeDSRequest;
        setThreeDSRequest({ card, order, threeDS });
        setCheckoutInfo({ cardNumber, comment, isPagoPlux, cardFormValues });
      }
    })();
  }, [uid]);

  useEffect(() => {
    (async () => {
      if (!threeDSRequest || !checkoutInfo) return;
      await Storage.set({
        key: `${THREE_DS_PAYLOAD}${uid}`,
        value: JSON.stringify({
          ...threeDSRequest,
          ...checkoutInfo,
          threeDS: {
            ...threeDSRequest.threeDS,
            html: ""
          }
        })
      });
    })();
  }, [checkoutInfo, threeDSRequest, uid]);

  const value: PaymentsProviderValue = useMemo(() => {
    return {
      resetPaymentsContext,
      selectedCard,
      setSelectedCard,
      selectedPaymentMethodId,
      setSelectedPaymentMethodId,
      selectedPaymentProviderId,
      setSelectedPaymentProviderId,
      showModalOtpPagoPlux,
      setShowModalOtpPagoPlux,
      otpRequest,
      setOtpRequest,
      showModal3dsPagoPlux,
      setShowModal3dsPagoPlux,
      threeDSRequest,
      setThreeDSRequest,
      checkoutInfo,
      setCheckoutInfo,
      new3dsTokens,
      setNew3dsTokens
    };
  }, [
    checkoutInfo,
    new3dsTokens,
    otpRequest,
    selectedCard,
    selectedPaymentMethodId,
    selectedPaymentProviderId,
    showModal3dsPagoPlux,
    showModalOtpPagoPlux,
    threeDSRequest
  ]);

  return (
    <PaymentsContext.Provider value={value}>
      <ContextDevTool
        context={PaymentsContext}
        id="payments"
        displayName="Payments"
      />
      {props.children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsProvider;
