import React from "react";

import Styles from "./SuccessNotification.styles";
import { SuccessNotificationProps as Props } from "./SuccessNotification.types";

import SuccessSVG from "../../../../../public/assets/images/success-notification.svg";

const SuccessNotification: React.FC<Props> = props => {
  const { className, message, title } = props;

  return (
    <Styles
      className={`SuccessNotification ${className}`}
      showCloseIcon={false}
    >
      <SuccessSVG />
      <p className="SuccessNotification__title">{title}</p>
      <p className="SuccessNotification__message">{message ?? ""}</p>
    </Styles>
  );
};

SuccessNotification.defaultProps = {};

export default SuccessNotification;
