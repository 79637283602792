import styled from "styled-components";

import { ScheduleStyledProps as Props } from "./Schedule.types";

const ScheduleStyled = styled.div<Props>`
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-bottom: 2.4rem;
  padding-top: 1.2rem;
  background-color: var(--white);

  .Schedule {
    &__title {
      margin-bottom: 1.6rem;
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l40);
    }

    &__items {
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      color: var(--palette-gray-s0-l40);

      &:not(:last-child) {
        padding-bottom: 0.8rem;
      }

      &--isToday {
        color: var(--palette-black);
        font-weight: 600;
      }
    }

    &__day {
      color: inherit;
    }

    &__hours {
      color: inherit;
    }
  }
`;

export default ScheduleStyled;
