import styled from "styled-components";

import { ListItemStyledProps as Props } from "./ListItem.types";

const ListItemStyled = styled.div<Props>`
  display: grid;
  grid-template-columns: ${props =>
    props.hasIcon || props.isLoading
      ? "max-content 1fr max-content"
      : "1fr max-content"};
  align-items: center;
  column-gap: 1.6rem;
  flex: 1;

  .ListItem {
    &__loading {
      svg {
        height: 2rem;
        width: 2rem;
        animation: rotate 0.6s linear infinite;
        overflow: visible;
      }
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.4rem;
      color: var(--palette-black-s0-l10);
      color: ${props =>
        props.isActive
          ? "var(--palette-primary)"
          : "var(--palette-black-s0-l10)"};
      word-break: break-word;
    }

    &__value {
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: 100%;
    }

    &__description {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l40);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: 100%;
    }

    &__dropdown-options {
      justify-self: end;

      .Clickable {
        padding: 0.8rem;
      }
    }

    &__default {
      font-size: 1.2rem;
    }
  }
`;

export default ListItemStyled;
