import styled from "styled-components";

import { CartProductsStyledProps as Props } from "./CartProducts.types";

const CartProductsStyled = styled.div<Props>`
  &.CartProducts {
    border-top: 0.1rem solid var(--palette-lightgrey-s5-l90);
    margin-top: 2.4rem;
  }
`;

export default CartProductsStyled;
