import React from "react";
import { Modal } from "artisn-ui-react";
import { useRouter } from "next/router";

import Styles from "./UpdateAppModal.styles";
import { UpdateAppModalProps as Props } from "./UpdateAppModal.types";
import Button from "../Button/Button";
import { backdropConfig } from "./UpdateAppModal.helpers";

import CloudSVG from "../../../../public/assets/images/cloud-download.svg";

const UpdateAppModal: React.FC<Props> = props => {
  const { storeUrl } = props;
  const { push } = useRouter();

  return (
    <Modal
      backdropConfig={backdropConfig}
      className="UpdateAppModal__modal"
      opened={true}
    >
      <Styles className="UpdateAppModal">
        <div className="UpdateAppModal__svg">
          <CloudSVG />
        </div>
        <h1 className="UpdateAppModal__title">
          Hay una nueva versión de tu app
        </h1>
        <p className="UpdateAppModal__description">
          La versión de tu app caducó. Descarga la nueva versión en la tienda.
        </p>
        <Button
          onClick={() => push(storeUrl)}
          className="UpdateAppModal__button"
        >
          Descargar ahora
        </Button>
      </Styles>
    </Modal>
  );
};

UpdateAppModal.defaultProps = {};

export default UpdateAppModal;
