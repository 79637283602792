// shoppingCartSummary helper functions and data

import { ShoppingCartTotals } from "artisn/shopping-cart";

export const cleanShoppingCartTotal = (
  shoppingCartTotal: ShoppingCartTotals
) => {
  const newShoppingCartTotals: Record<string, any> = {};
  const shoppingCartTotalArray = Object.entries(shoppingCartTotal);
  shoppingCartTotalArray.forEach(value => {
    if (!value[1]) {
      newShoppingCartTotals[value[0]] = 0;
    }
  });

  return { ...shoppingCartTotal, ...newShoppingCartTotals };
};
