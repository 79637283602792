import React, { useState } from "react";
import Link from "next/link";
import { Capacitor } from "@capacitor/core";
import { Phone } from "artisn/types";

import Styles from "./TermsAndPrivacyModal.styles";
import { TermsAndPrivacyModalProps as Props } from "./TermsAndPrivacyModal.types";
import Checkbox from "components/global/Checkbox/Checkbox";
import Button from "components/global/Button/Button";
import useAuth from "contexts/auth/auth.context.hooks";
import { useFetchUser, usePutUser } from "services/user/user.service.hooks";
import { ExtendedUser } from "services/user/user.service.types";
import useCountries from "contexts/countries/countries.hooks";
import CONSTANTS from "config/constants";
import { getIsoToDateString, notify } from "utils/common.utils";
import { usePutUserSettings } from "services/settings/settings.service.hooks";
import { ElHorneroTopics } from "services/settings/settings.service.helpers";

const { DEFAULT_COUNTRY } = CONSTANTS.ARTISN;

const TermsAndPrivacyModal: React.FC<Props> = props => {
  const { className, isModalVisible, setIsModalVisible } = props;
  const [termsAndPrivacy, setTermsAndPrivacy] = useState(false);
  const [acceptEmails, setAcceptEmails] = useState(true);
  const [acceptPushNotifications, setAcceptPushNotifications] = useState(true);
  const disabled = !termsAndPrivacy;
  const { uid } = useAuth();
  const { data: user } = useFetchUser();
  const { selectedCountry = DEFAULT_COUNTRY } = useCountries();
  const { id: selectedCountryId } = selectedCountry ?? {};
  const { mutate: putMutate, reset, isLoading } = usePutUser();
  const { mutate: updateUserSettings } = usePutUserSettings();
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;

  const submitHandler = () => {
    if (!user) {
      console.error(
        "UserForm: to update the user information you must pass the prop 'user'."
      );
    }
    if (!uid || !user) return;

    const isoToDateString = getIsoToDateString();

    const { isoCode, code } = selectedCountry;

    const updatedUser: ExtendedUser = {
      ...user,
      active: !!user.active,
      country: { id: selectedCountryId },
      privacyAcceptDate: isoToDateString,
      phone: {
        ...(user.phone as Phone),
        countryCode: user.phone?.countryCode || code,
        countryIsoCode: user.phone?.countryIsoCode || isoCode
      }
    };
    
    putMutate(updatedUser, {
      onError: e => {
        console.error(
          "Se produjo un error al intentar actualizar los datos del usuario."
        );
      },
      onSuccess: () => {
        reset();
        setIsModalVisible(!isModalVisible);
      }
    });

    updateUserSettings(
      {
        uid,
        settings: {
          emailNotifications: acceptEmails,
          pushNotifications: acceptPushNotifications
        },
        topic: isMobile ? ElHorneroTopics.GENERAL : undefined
      },
      {
        onError: e => {
          console.error(
            "Se produjo un error al intentar actualizar los datos del usuario."
          );
          notify(e.message, "Update user settings");
        }
      }
    );
  };

  return (
    <Styles
      className={`TermsAndPrivacyModal ${className}`}
      opened={isModalVisible}
      backdropConfig={{
        className: "TermsAndPrivacyModalBackdrop",
        opacity: 0.5
      }}
    >
      <h2 className="TermsAndPrivacyModal__title">
        Te invitamos a revisar las nuevas políticas y aceptarlas.
      </h2>
      <p className="TermsAndPrivacyModal__description">
        Manejamos la información que nos brindas para gestionar nuestras
        iniciativas comerciales y mantener una relación cercana con nuestros
        clientes. Queremos que sepas que tienes el derecho de acceder,
        actualizar, revocar, eliminar y oponerte al tratamiento de tus datos. Si
        deseas ejercer alguno de estos derechos, no dudes en enviarnos un correo
        electrónico a: callcenter@elhornero.com.ec. Estaremos encantados de
        ayudarte en lo que necesites.
      </p>
      <Checkbox
        name="termsAndPrivacy"
        className="TermsAndPrivacyModal__checkbox"
        label={
          <p>
            <span className="TermsAndPrivacyModal__checkboxTextOne">
              Acepto los{" "}
              <Link href="/terms">
                <a className="TermsAndPrivacyModal__checkboxTextTwo">
                  Términos y condiciones
                </a>
              </Link>{" "}
              y{" "}
              <Link href="/privacy">
                <a className="TermsAndPrivacyModal__checkboxTextTwo">
                  políticas de privacidad
                </a>
              </Link>
            </span>
          </p>
        }
        defaultChecked={termsAndPrivacy}
        onChange={() => setTermsAndPrivacy(prev => !prev)}
      />
      <Checkbox
        name="acceptEmails"
        className="TermsAndPrivacyModal__checkbox"
        label="Acepto recibir correos electrónicos"
        defaultChecked={acceptEmails}
        onChange={() => setAcceptEmails(prev => !prev)}
      />
      {isMobile ? (
        <Checkbox
          name="acceptPushNotifications"
          className="TermsAndPrivacyModal__checkbox"
          label="Acepto recibir notificaciones push"
          defaultChecked={acceptPushNotifications}
          onChange={() => setAcceptPushNotifications(prev => !prev)}
        />
      ) : null}

      <Button
        className="TermsAndPrivacyModal__button"
        type="FILLED"
        disabled={isLoading || disabled}
        onClick={() => submitHandler()}
        isLoading={isLoading}
      >
        Aceptar
      </Button>
    </Styles>
  );
};

TermsAndPrivacyModal.defaultProps = {
  className: ""
};

export default TermsAndPrivacyModal;
