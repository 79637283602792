import { useContext } from "react";
import { PaymentsContext } from "./payments.context";
import { PaymentsProviderValue } from "./payments.context.types";

const usePayments = () => {
  const context = useContext<PaymentsProviderValue>(PaymentsContext);
  if (typeof context === "undefined") {
    throw new Error("usePayments must be used within a PaymentsProvider");
  }
  return context;
};

export default usePayments;
