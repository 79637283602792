// Product services
import { BaseProduct, CategoryWithProducts } from "artisn/types";
import { ProductDetails } from "artisn/types";

import { FetchCategoriesWithProductsPayload } from "./products.service.types";
import { FetchProductsPayload } from "./products.service.types";
import { FetchProductDetailsPayload } from "./products.service.types";
import { NewPaginatedResponse } from "types/pagination.types";
import { normalizeProductDetails } from "utils/product.utils";
import CONSTANTS from "config/constants";
import { getState } from "services/state";

const { API } = CONSTANTS;
const { PRODUCTS_PER_CATEGORY_REQUEST } = API;

/**
 * Fetches a list of paginated products.
 *
 * @param {FetchProductsPayload} config The config needed to fetch a page
 * @returns {NewPaginatedResponse<BaseProduct>} The paginated list of products
 */
export const fetchProducts = async (
  config: FetchProductsPayload
): Promise<NewPaginatedResponse<BaseProduct>> => {
  const { vendorId, catalogueId, storeId, categoryId, size = 20 } = config;
  const { page, query } = config;
  const { shouldMock, axiosDefault } = getState();

  try {
    if (!shouldMock) {
      const { data: response } = await axiosDefault.get(`/api/v3/productsBy`, {
        params: {
          query,
          vendorId,
          storeId,
          channelId: catalogueId,
          catalogueId,
          categoryId,
          size,
          orderBy: "predefined",
          orderMethod: "asc",
          page
        }
      });

      return response;
    } else {
      const { mockProductsPaginated } = await import("./products.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(
            mockProductsPaginated(
              {
                page,
                pageSize: size
              },
              { query: query ?? "query", field: "" },
              { imageHeight: 300, imageWidth: 300 }
            )
          );
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches a list of products group by their parent categories
 *
 * @param {FetchCategoriesWithProductsPayload} config The config needed to fetch a page
 * @returns {RawPaginatedResponse<CategoryWithProducts>} The paginated list of categories
 */
export const fetchCategoriesWithProducts = async (
  config: FetchCategoriesWithProductsPayload
): Promise<NewPaginatedResponse<CategoryWithProducts>> => {
  const { shouldMock, axiosDefault } = getState();
  try {
    if (!shouldMock) {
      const { vendorId, catalogueId, storeId, categoryId, size = 3 } = config;
      const { page } = config;

      const { productsByGroup = PRODUCTS_PER_CATEGORY_REQUEST } = config;
      const { data } = await axiosDefault.get(`/api/v3/productsBy`, {
        params: {
          vendorId,
          storeId,
          channelId: catalogueId,
          catalogueId,
          categoryId,
          groupBy: "category",
          productsByGroup,
          size,
          orderBy: "predefined",
          orderMethod: "asc",
          page
        }
      });
      return data;
    } else {
      const { mockPaginatedCategoriesWithProduct } = await import(
        "./products.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockPaginatedCategoriesWithProduct);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches a product's details.
 *
 * @param {FetchProductDetailsPayload} config The config needed to fetch the product
 * @returns {ProductDetails} The product details object
 */
export const fetchProductDetails = async (
  config: FetchProductDetailsPayload
): Promise<ProductDetails> => {
  try {
    const { withCategories } = config;
    const { vendorId, catalogueId, storeId, categoryId, productId } = config;
    const { shouldMock, axiosDefault } = getState();

    if (!shouldMock) {
      const { data } = await axiosDefault.get(`/v1/products/${productId}`, {
        params: {
          vendorId,
          storeId,
          channelId: catalogueId,
          catalogueId,
          categoryId,
          withCategories
        }
      });

      if (!data.data) {
        throw new Error(`Couldn't find a product with id ${productId}`);
      }

      return normalizeProductDetails(data.data);
    } else {
      const { mockProductDetails } = await import("./products.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(
            mockProductDetails(productId, { imageHeight: 600, imageWidth: 600 })
          );
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches a base product array.
 *
 * @returns {BaseProduct[]} The base products array
 */
export const fetchRecommendedProducts = async (): Promise<BaseProduct[]> => {
  const { shouldMock } = getState();
  try {
    if (!shouldMock) {
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve([]);
        }, 1000);
      });
    } else {
      const { mockRecommendedProducts } = await import(
        "./products.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockRecommendedProducts);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
