import React from "react";
import { useRouter } from "next/router";

import Styles from "./EmptyCart.styles";
import { EmptyCartProps as Props } from "./EmptyCart.types";
import Button from "components/global/Button/Button";

import CartEmptySVG from "../../../../public/assets/images/cart-empty.svg";

const EmptyCart: React.FC<Props> = props => {
  const { drawerCloseHandler } = props;
  const { push, pathname } = useRouter();
  const isInCategories = pathname.includes("categories");
  const goToCategories = isInCategories
    ? drawerCloseHandler
    : () => push("/categories");

  return (
    <Styles className="EmptyCart">
      <div className="EmptyCart__icon">
        <CartEmptySVG />
      </div>
      <p className="EmptyCart__title">Tu carrito de compras está vacio.</p>
      <Button className="EmptyCart__button" onClick={goToCategories}>
        Ver productos
      </Button>
    </Styles>
  );
};

EmptyCart.defaultProps = {};

export default EmptyCart;
