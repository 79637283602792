import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhoneNumberInputValues } from "artisn-ui-react";
import { TextInput, PhoneNumberInput } from "artisn-ui-react";
import { useRouter } from "next/router";
import Link from "next/link";
import { Capacitor } from "@capacitor/core";

import Styles from "./SignUpUserForm.styles";
import Button from "components/global/Button/Button";
import Checkbox from "components/global/Checkbox/Checkbox";
import { signUpUserSchema } from "./SignUpUserForm.helpers";
import { SignUpUserFormValues } from "./SignUpUserForm.types";
import { SignUpUserFormProps as Props } from "./SignUpUserForm.types";
import useSignOut from "hooks/useSignOut";
import useCountries from "contexts/countries/countries.hooks";
import InfoActionModal from "components/global/InfoActionModal/InfoActionModal";
import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";
import { trimFields } from "utils/form.utils";
import useAuth from "contexts/auth/auth.context.hooks";
import useSignUpUser from "contexts/signUpUser/signUpUser.hooks";

const SignUpUserForm: React.FC<Props> = props => {
  const { onSubmitSuccess, isLoading, errorMessage } = props;
  const { signUpUserFormContext, setSignUpUserFormContext } = useSignUpUser();
  const {
    handleSubmit,
    register,
    formState,
    getValues,
    control,
    setValue,
    watch,
    reset
  } = useForm<SignUpUserFormValues>({
    mode: "onChange",
    resolver: yupResolver(signUpUserSchema),
    defaultValues: {
      name: signUpUserFormContext.name,
      lastname: signUpUserFormContext.lastname,
      phone: signUpUserFormContext.phone,
      terms: signUpUserFormContext.terms,
      acceptEmails: signUpUserFormContext.acceptEmails,
      acceptPushNotifications: signUpUserFormContext.acceptPushNotifications
    }
  });
  const { providerData } = useAuth();
  const signOut = useSignOut();
  const { replace } = useRouter();
  const { countryISOCodes, selectedCountry } = useCountries();
  const { userError } = useCheckout();
  const { isoCode: defaultCountryISOCode } = selectedCountry;
  const { code: countryCallingCode } = selectedCountry;
  const { name, lastname, phone: phoneError, terms } = formState.errors ?? {};
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [phone, setPhone] = useState<PhoneNumberInputValues>({
    countryCallingCode,
    countryCode: defaultCountryISOCode,
    international: "",
    internationalRaw: "",
    isPossible: false,
    isValid: false,
    national: getValues().phone ?? "",
    nationalRaw: ""
  });
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;

  const signOutHandler = () => {
    signOut();
    replace("/");
  };

  const submitHandler = async (form: SignUpUserFormValues) => {
    const newForm = trimFields(form);

    const { name, lastname, terms, acceptEmails } = newForm;
    const { acceptPushNotifications } = newForm;
    onSubmitSuccess({
      name,
      lastname,
      phone: {
        countryCode: phone.countryCallingCode,
        countryIsoCode: phone.countryCode,
        number: phone.national
      },
      terms,
      acceptEmails,
      acceptPushNotifications
    });
    reset();
  };

  const continueWithAnotherUserHandler = () => {
    if (userError) {
      setShowInfoModal(true);
      return;
    }
    signOutHandler();
    reset();
  };

  useEffect(() => {
    if (!providerData || !providerData.length) return;
    const userInfo = providerData[0];
    if (userInfo === null) return;
    const { displayName, phoneNumber } = userInfo;
    let lastname = displayName?.split(" ")[1];
    if (displayName && displayName?.split(" ").length > 2) {
      lastname = displayName?.split(" ")[2];
    }
    // @ts-ignore
    const newLastname = userInfo?.lastName ?? lastname;
    // @ts-ignore
    const newFirstname = userInfo?.firstName ?? displayName?.split(" ")[0];
    setValue("name", signUpUserFormContext.name || newFirstname);
    setValue("lastname", signUpUserFormContext.lastname || newLastname);
    setValue("phone", signUpUserFormContext.phone || (phoneNumber ?? ""));
  }, [
    providerData,
    setValue,
    signUpUserFormContext.lastname,
    signUpUserFormContext.name,
    signUpUserFormContext.phone
  ]);

  const nameWatched = watch("name");
  const lastnameWatched = watch("lastname");
  const phoneWatched = watch("phone");
  const termsWatched = watch("terms");
  const acceptEmailsWatched = watch("acceptEmails");
  const acceptPushNotificationsWatched = watch("acceptPushNotifications");

  useEffect(() => {
    setSignUpUserFormContext({
      name: nameWatched,
      lastname: lastnameWatched,
      phone: phoneWatched,
      terms: termsWatched,
      acceptEmails: acceptEmailsWatched,
      acceptPushNotifications: acceptPushNotificationsWatched
    });
    return () => {};
  }, [
    acceptEmailsWatched,
    acceptPushNotificationsWatched,
    lastnameWatched,
    nameWatched,
    phoneWatched,
    setSignUpUserFormContext,
    termsWatched
  ]);

  return (
    <Styles className="SignUpUserForm">
      <p className="SignUpUserForm__title">Solo un paso más</p>
      <form onSubmit={handleSubmit(submitHandler)}>
        <TextInput
          {...register("name")}
          label="Nombre"
          className="field"
          errorMessage={name?.message}
          maxLength={50}
        />
        <TextInput
          {...register("lastname")}
          label="Apellido"
          className="field SignUpUserForm__field"
          errorMessage={lastname?.message}
          maxLength={50}
        />
        <Controller
          name="phone"
          control={control}
          defaultValue={getValues().phone}
          render={({ field }) => (
            <PhoneNumberInput
              {...field}
              className="field SignUpUserForm__field"
              label="Número de teléfono"
              countriesCodes={countryISOCodes}
              onChange={e => {
                setPhone(e);
                field.onChange(e.national);
              }}
              placeholder="099 999 9999"
              errorMessage={phoneError?.message}
              inputValue={phone.national}
              maxLength={12}
            />
          )}
        />
        {errorMessage ? (
          <p className="SignUpUserForm__error-message">{errorMessage}</p>
        ) : null}
        <Checkbox
          {...register("terms")}
          className="SignUpUserForm__field SignUpUserForm__checkbox"
          label={
            <p>
              <span className="TermsAndPrivacyModal__checkboxTextOne">
                Acepto los{" "}
                <Link href="/terms">
                  <a className="TermsAndPrivacyModal__checkboxTextTwo">
                    Términos y condiciones
                  </a>
                </Link>{" "}
                y{" "}
                <Link href="/privacy">
                  <a className="TermsAndPrivacyModal__checkboxTextTwo">
                    políticas de privacidad
                  </a>
                </Link>
              </span>
            </p>
          }
          errorMessage={terms?.message}
          defaultChecked={watch("terms")}
        />
        <Checkbox
          {...register("acceptEmails")}
          className="SignUpUserForm__field SignUpUserForm__checkbox"
          label="Acepto recibir correos electrónicos"
          defaultChecked={watch("acceptEmails")}
        />
        {isMobile ? (
          <Checkbox
            {...register("acceptPushNotifications")}
            className="SignUpUserForm__field SignUpUserForm__checkbox"
            label="Acepto recibir notificaciones push"
            defaultChecked={watch("acceptPushNotifications")}
          />
        ) : null}
        <div className="SignUpUserForm__buttons">
          <Button
            type="FILLED"
            color="primary"
            htmlType="submit"
            className="SignUpUserForm__button"
            isLoading={isLoading}
            disabled={!getValues().terms}
          >
            Crear cuenta
          </Button>
          <Button
            type="LINK"
            color="black"
            className="SignUpUserForm__button SignUpUserForm__button--secondary"
            htmlType="button"
            onClick={continueWithAnotherUserHandler}
          >
            Continuar con otro usuario
          </Button>
        </div>
      </form>
      <InfoActionModal
        opened={showInfoModal}
        cancelAction={() => setShowInfoModal(false)}
        cancelText="Cancelar"
        confirmAction={signOutHandler}
        confirmText="Continuar"
        title="Toma en cuenta lo siguiente"
        description="Si continuas vas a perder los datos asociados a tu identificador"
        onClose={() => setShowInfoModal(false)}
      />
    </Styles>
  );
};

SignUpUserForm.defaultProps = {};

export default SignUpUserForm;
