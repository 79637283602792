import { useEffect } from "react";
import { events } from "@artisan-commerce/analytics-capacitor";

import useStores from "contexts/stores/stores.context.hooks";
import { useFetchDefaultStore } from "services/stores/stores.service.hooks";
import { useFetchNearbyStores } from "services/stores/stores.service.hooks";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import useAnalytics from "contexts/analytics/analytics.context.hooks";

const { logSetStore, logChangeStore } = events.store;

const useListenStores = () => {
  const { setDefaultStore, setSelectedStore, setNoCoverage } = useStores();
  const { selectedShippingAddress } = useShippingAddress();
  const { analyticsInitialized } = useAnalytics();

  const { data: defaultStore } = useFetchDefaultStore();
  const { data: nearbyStores, isLoading } = useFetchNearbyStores();

  /* Set default store on context if default store changes. */
  useEffect(() => {
    if (!defaultStore) return;
    setDefaultStore(defaultStore);
  }, [defaultStore, setDefaultStore]);

  /** Set selected store if there is a nearby store or a default store. */
  useEffect(() => {
    const selectedStore = nearbyStores?.[0] ?? defaultStore;

    setSelectedStore(previousStore => {
      // Send analytics events
      if (!previousStore && selectedStore && analyticsInitialized) {
        logSetStore({ store: selectedStore });
      } else if (previousStore && selectedStore && analyticsInitialized) {
        logChangeStore({
          previousStore,
          nextStore: selectedStore
        });
      }

      return selectedStore;
    });
  }, [nearbyStores, defaultStore, setSelectedStore, analyticsInitialized]);

  /**
   * Set coverage if there aren't nearby stores and selected shipping address.
   */
  useEffect(() => {
    const noClosestStore = !nearbyStores?.length && !isLoading;
    setNoCoverage(noClosestStore && !!selectedShippingAddress);
  }, [nearbyStores?.length, isLoading, selectedShippingAddress, setNoCoverage]);
};

export default useListenStores;
