import styled from "styled-components";

import { ClosedStoreNotificationStyledProps as Props } from "./ClosedStoreNotification.types";
import Notification from "components/global/notifications/Notification/Notification";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const ClosedStoreNotificationStyled = styled(Notification)<Props>`
  display: ${props => (props.opened ? "block" : "none")};
  border-left: 1rem solid var(--palette-primary);
  padding-top: 1.2rem;

  @media (max-width: ${mobile}px) {
    padding: 1rem;
    border-left: 0.6rem solid var(--palette-primary);
    display: ${props => (props.opened ? "grid" : "none")};
    grid-template-columns: auto 10.8rem;
    align-items: center;
  }

  .ClosedStoreNotification {
    &__title {
      font-size: 2.4rem;
      font-weight: 900;
      line-height: 2.8rem;
      padding-top: 1.2rem;

      @media (max-width: ${mobile}px) {
        font-size: 1.4rem;
        padding-top: 0;
        line-height: 2.4rem;
      }
    }

    &__button,
    &__description {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }

    &__icon {
      width: 5.2rem;
      height: 5.2rem;
      background-color: var(--palette-secondary-s35-l95);
      border-radius: 90%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: ${mobile}px) {
        display: none;
      }

      svg {
        width: 1.6rem;
        height: 1.6rem;

        path {
          fill: var(--palette-primary);
        }
      }
    }

    &__description {
      padding-bottom: 1.6rem;
      color: var(--palette-black-s0-l10);
      font-size: 1.6rem;

      @media (max-width: ${mobile}px) {
        padding-bottom: 0.8rem;
        line-height: 1.6rem;
      }
    }

    &__button {
      font-weight: 600;
      padding: 0.8rem 2rem;
      max-width: 20.8rem;
      border-radius: 12rem;

      .Button__wrapper {
        font-size: 1.6rem;
      }

      &--desktop {
        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &--mobile {
        grid-column: 2/3;
        grid-row: 1/3;
        max-width: 8rem;
        height: 4.8rem;
        padding: 1rem;
        display: none;

        @media (max-width: ${mobile}px) {
          display: block;
        }
      }
    }
  }
`;

export default ClosedStoreNotificationStyled;
