import styled from "styled-components";

import { ShoppingCartProductStyledProps as Props } from "./ShoppingCartProduct.types";
import { ShoppingCartProductPlaceholderProps as PlaceholderProps } from "./ShoppingCartProduct.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ShoppingCartProductStyled = styled.div<Props>`
  padding-bottom: 1.6rem;
  padding-top: 2.4rem;

  .ShoppingCartProduct {
    &__product-details {
      display: grid;
      grid-template-areas:
        "product-name total-price remove-product"
        "product-description unit-price remove-product";
      column-gap: 1.2rem;
      row-gap: 0.2rem;
    }

    &__name {
      grid-area: product-name;
      font-size: 1.6rem;
      font-weight: 800;
      color: var(--palette-gray-s0-l20);
      cursor: pointer;
    }

    &__description {
      grid-area: product-description;

      display: inline-block;
      width: 100%;
      font-size: 1.5rem;
      color: var(--palette-gray-s0-l50);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
      font-weight: 500;
    }

    &__total-price {
      grid-area: total-price;
      font-size: 1.6rem;
      text-align: end;
    }

    &__unit-price {
      grid-area: unit-price;
      color: var(--palette-gray-s0-l50);
      text-align: end;
    }

    &__remove-button {
      display: flex;
      grid-area: remove-product;
      width: min-content;
      height: min-content;
      background: transparent;
      border: none;
      cursor: pointer;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    &__counter {
      grid-area: counter;
      margin-top: 1.2rem;
    }

    &__error {
      display: flex;
      align-items: center;
      margin-top: 1.2rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--palette-red-s60-l60);

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.6rem;

        path {
          fill: var(--palette-red-s60-l60);
        }
      }
    }
  }
`;

export const ShoppingCartProductPlaceholderStyled = styled.div<PlaceholderProps>`
  height: 16.6rem;
  display: flex;
  padding: 0.8rem;

  @media (max-width: ${tablet - 1}px) {
    height: 11.4rem;
  }

  .ShoppingCartProductPlaceholder {
    &__details {
      padding: 1.2rem 0.8rem;
    }

    &__image {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 10rem;
      overflow: hidden;
      position: relative;
      width: 10rem;
    }

    &__price {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 4.2rem;
    }

    &__name {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 18.4rem;

      @media (max-width: ${tablet - 1}px) {
        width: 16rem;
      }
    }

    &__description {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      margin-top: 0.8rem;
      margin-bottom: 1.2rem;
      position: relative;
      width: 7.2rem;
    }

    &__shine {
      animation: shine 2.5s ease-in-out infinite;
      background-color: var(--palette-gray-s0-l98);
      height: 300%;
      position: absolute;
      width: 25%;
      opacity: 0.4;
    }
  }
`;

export default ShoppingCartProductStyled;
