// useShippingCost custom hook
import { useMemo } from "react";

import useGeo from "contexts/geo/geo.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import useStores from "contexts/stores/stores.context.hooks";
import { useFetchShippingCost } from "services/shippingCost/shippingCost.service.hooks";

const useShippingCost = () => {
  const { catalogueId } = useCatalogues().selectedCatalogue;
  const { selectedStore } = useStores();
  const { storeId } = selectedStore ?? {};
  const { selectedCoordinates } = useGeo();
  const { lat, lng } = selectedCoordinates ?? {};

  const { data: shippingCost } = useFetchShippingCost({
    storeId,
    catalogueId,
    lat,
    lng
  });

  return useMemo(() => {
    return shippingCost ?? null;
  }, [shippingCost]);
};

export default useShippingCost;
