// ShoppingCartProduct helper functions and data

import { Alert } from "artisn/types";

export const getMissingProduct = (alerts: Alert[]) => {
  // TODO: Tipear alert en type Alert
  // @ts-ignore
  const [error] = alerts?.filter(e => e.alert === "missingProduct") ?? [];
  return error?.message ?? "";
};
