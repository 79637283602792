import React from "react";
import { Clickable, Image } from "artisn-ui-react";
import dayjs from "dayjs";

import Styles from "./CouponDetail.styles";
import useCountries from "contexts/countries/countries.hooks";
import { CouponDetailProps as Props } from "./CouponDetail.types";

import CloseSVG from "../../../../public/assets/images/close.svg";

const CouponDetail: React.FC<Props> = props => {
  const { benefit, selected = false, inCart, className, removeError } = props;
  const { showRemove = true, onRemove } = props;
  const { title, expirationDate, code } = benefit;
  const { image: benefitImages } = benefit;
  const { selectedCountry } = useCountries();
  const { locale } = selectedCountry;
  const expired = dayjs(expirationDate)
    .locale(locale)
    .format("DD [de] MMMM, YYYY");

  return (
    <Styles className={`CouponDetail ${className}`} selected={selected}>
      <div className="CouponDetail__container">
        <div className="CouponDetail__left">
          {!inCart ? <div className="CouponDetail__left__icon" /> : null}
          {benefitImages.length > 0 ? (
            <div className="CouponDetail__left__benefit-image">
              <Image
                alt={title}
                image={benefitImages[0].url}
                width={80}
                height={80}
                placeholder="skeleton"
              />
            </div>
          ) : null}
          <div className="CouponDetail__left__label">
            <p className="CouponDetail__left__label__title">{title}</p>
            <p className="CouponDetail__left__label__code">Código: {code}</p>
            <p className="CouponDetail__left__label__expiration-date">{`Válido hasta: ${expired}`}</p>
          </div>
        </div>
        {inCart && showRemove ? (
          <div className="CouponDetail__right">
            <Clickable
              className="CouponDetail__remove"
              onClick={() => onRemove?.()}
            >
              <CloseSVG />
            </Clickable>
          </div>
        ) : null}
      </div>
      {removeError ? (
        <p className="CouponDetail__remove-error">{removeError}</p>
      ) : null}
    </Styles>
  );
};

CouponDetail.defaultProps = {
  className: ""
};

export default CouponDetail;
