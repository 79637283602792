import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";
import usePayments from "contexts/payments/payments.context.hooks";
import { useCallback, useMemo } from "react";

const usePagoPlux3ds = () => {
  const { setStep } = useCheckout();
  const { setShowModal3dsPagoPlux, setNew3dsTokens } = usePayments();

  const verifyPayment = useCallback(
    (url: string) => {
      const arr = url.split("?");
      const queryParams = arr[arr.length - 1].split("&");
      const params = queryParams.reduce(
        (acc: Record<string, string>, param) => {
          const [key, value] = param.split("=");
          acc[key] = value;
          return acc;
        },
        {}
      );
      const { pti, pcc, ptk, id, resourcePath } = params;
      if (!pti || !pcc || !ptk || !id || !resourcePath) return;
      setNew3dsTokens({ pti, ptk, pcc });
      setStep("PAY_ORDER");
      setShowModal3dsPagoPlux(true);
    },
    [setNew3dsTokens, setStep, setShowModal3dsPagoPlux]
  );

  return useMemo(() => ({ verifyPayment }), [verifyPayment]);
};

export default usePagoPlux3ds;
