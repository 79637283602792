import styled from "styled-components";
import { Drawer } from "artisn-ui-react";

import { ShoppingCartDrawerStyledProps as Props } from "./ShoppingCartDrawer.types";
import { getMaxWidth } from "utils/styling.utils";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const ShoppingCartDrawerStyled = styled(Drawer)<Props>`
  &.ShoppingCartDrawer {
    padding: 2.4rem 1.6rem;
    width: clamp(32rem, ${getMaxWidth()}, 44rem);
    height: 100%;
    margin-top: env(safe-area-inset-top);

    @media (max-width: ${tablet}px) {
      max-height: calc(
        var(--sizes-page-maxHeight) + env(safe-area-inset-bottom)
      );
    }
  }

  &&& .ShoppingCartDrawer {
    &__close {
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
      cursor: pointer;
      z-index: 2;
    }

    &__share {
      margin-left: 2rem;
    }

    &__title {
      font-size: 3.2rem;
      line-height: 2.8rem;
      color: var(--palette-black-s0-l10);
      padding: 2rem 0;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    &__store {
      display: flex;
      align-items: center;
      padding-bottom: 2.4rem;
      font-weight: 600;
      font-size: 1.6rem;
      color: var(--palette-black-s0-l20);

      svg {
        margin-right: 1.2rem;

        path {
          fill: var(--palette-black-s0-l20);
        }
      }
    }

    &__cart {
      overflow-y: auto;
      scrollbar-width: none;
      display: flex;
      flex-direction: column;
      height: 100%;

      .CartProducts {
        margin-top: ${props => (props.talkshop ? `0` : `2.4rem`)};
      }
    }

    &__cart::-webkit-scrollbar {
      width: 0;
    }

    &__coupon {
      justify-content: space-between;
      padding-right: 0.8rem;
    }

    &__coupon-anonymous {
      background-color: var(--palette-secondary-s35-l95);
      padding: 2.2rem 1.6rem;
      border-radius: 0.8rem;
      position: relative;
      display: grid;
      align-items: center;
      gap: 0.8rem;

      &__title {
        color: var(--palette-black-s0-l20);
        font-weight: 600;
        font-size: 1.6rem;
        grid-column: 2/3;
      }

      &__description {
        font-size: 1.4rem;
        color: var(--palette-gray-s0-l50);
        grid-column: 2/3;
      }

      &__close {
        position: absolute;
        top: 0;
        right: 0;
      }

      &__svg {
        grid-column: 1/2;

        path {
          fill: var(--palette-black-s0-l20);
        }
      }
    }

    &__empty-button {
      font-size: 1.6rem;
      font-weight: 800;
      line-height: 1.6rem;
      margin: 2.4rem 0;
      padding: 1.2rem 2.4rem;
      color: var(--palette-gray-s0-l20);
      border: 0.1rem solid var(--palette-secondary-s35-l95);
      border-radius: 10rem;
      margin-right: auto;
    }

    &__summary {
      margin-bottom: 8rem;
      margin-top: auto;
    }

    &__pay-button {
      background-color: var(--palette-white);
      width: 100%;
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      justify-content: center;
      padding: 1.6rem;
      box-shadow: 0 -0.1rem 0.8rem 0.1rem var(--palette-gray-s0-l20-a08);
      z-index: 1;
      padding-bottom: calc(1.6rem + env(safe-area-inset-bottom));

      .CartPayButton,
      .Cart__summary__button {
        width: 100%;
      }

      .Cart__summary__button {
        border-radius: 10rem;
        font-size: 1.6rem;
        font-weight: 800;
      }
    }
  }

  .EmptyCart {
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &&& .RedeemCouponInput__text-input {
    & .TextInput__label {
      color: var(--palette-black-s0-l20);
    }
  }

  .ShoppingCartDrawer__summary {
    background-color: unset;
  }
`;

export default ShoppingCartDrawerStyled;
