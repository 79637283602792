import styled from "styled-components";

import { ModifiersGroupStyledProps as Props } from "./ModifiersGroup.types";

const ModifiersGroupStyled = styled.div<Props>`
  color: var(--palette-text-white);
  background-color: var(--palette-white);

  && .Pocket {
    &__header {
      padding: 1.6rem;
      border-top: 0.1rem solid var(--palette-gray-s0-l98);
      border-bottom: 0.1rem solid var(--palette-gray-s0-l98);
      cursor: auto;

      &__title {
        flex: 1;
        justify-content: space-between;
      }

      > svg {
        display: none;
      }
    }

    &__content > *:not(.ModifiersGroup__divider) {
      padding: 1.6rem;
      border-bottom: 0.1rem solid var(--palette-gray-s0-l98);
    }

    &__content {
      .CheckboxModifier:nth-last-child(2),
      .RadioButtonModifier:nth-last-child(2),
      .CounterModifier:nth-last-child(2) {
        border-bottom: 0;
      }
    }
  }

  &:last-of-type {
    .ModifiersGroup__divider {
      display: none;
    }
  }

  .ModifiersGroup {
    &__pocket-title {
      display: flex;
      flex: 1;
      justify-content: space-between;
      white-space: normal;
    }

    &__title {
      font-size: 0.8rem;
      margin-right: 1.6rem;
    }

    &__name {
      font-size: 1.8rem;
      font-weight: 800;
      color: var(--palette-black-s0-l10);
      line-height: 2.6rem;
    }

    &__description {
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l50);
      line-height: 1.6rem;
      font-weight: 500;
    }

    &__error {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--palette-red-s60-l60);
    }

    &__options-selected {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      height: min-content;
      align-self: center;
      padding: 0.4rem 0.8rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--palette-white);
      background-color: var(--palette-black-s0-l20);
      border-radius: 0.8rem;
    }
  }
`;

export default ModifiersGroupStyled;
