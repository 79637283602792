import { useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import { PushNotifications, Token } from "@capacitor/push-notifications";

import useAuth from "contexts/auth/auth.context.hooks";
import { useFetchUser } from "services/user/user.service.hooks";
import { auth } from "config/artisn.config";
import { FcmTokenBody } from "services/settings/settings.service.types";
import { postUserSettings } from "services/settings/settings.service";
import { useFetchUserSettings } from "services/settings/settings.service.hooks";
import { Capacitor } from "@capacitor/core";
import useCountries from "contexts/countries/countries.hooks";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import useBillingData from "contexts/billingData/billingData.context.hooks";
import useSignUpUser from "contexts/signUpUser/signUpUser.hooks";

const useListenUser = () => {
  const { push, asPath, pathname, events } = useRouter();
  const { isAnonymous, setProviderData, setTermsAndPrivacyModal } = useAuth();
  const { currentUser } = auth ?? {};
  const { data: user, error, isSuccess: isSuccessUser } = useFetchUser();
  const { privacyAcceptDate } = user ?? {};
  const { selectedCountryId } = useCountries();
  const { message } = error ?? {};
  const { setNotificationRegister, notificationRegister } = useAuth();
  const { refetch } = useFetchUserSettings();
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;
  const { setSelectedShippingAddress } = useShippingAddress();
  const { setSelectedBillingData } = useBillingData();
  const { signUpUserFormContext } = useSignUpUser();

  const getDeviceTokenHandler = useCallback(
    async (body: FcmTokenBody) => {
      await PushNotifications.addListener(
        "registration",
        async (token: Token) => {
          // TODO: DO NOT REMOVE THIS log, if the log is removed listeners does not work
          try {
            const payload: FcmTokenBody = {
              ...body,
              device_token: token.value
            };
            await postUserSettings(payload);
            setNotificationRegister(false);
            refetch();
          } catch (e) {
            console.error(e);
          }
        }
      );
    },
    [refetch, setNotificationRegister]
  );

  const verifyPermissions = useCallback(
    async (body: FcmTokenBody) => {
      try {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === "prompt") {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== "granted") {
          throw new Error("User denied permissions!");
        }
        await getDeviceTokenHandler(body);
        await PushNotifications.register();
      } catch (e) {
        console.error(e.message);
      }
    },
    [getDeviceTokenHandler]
  );

  useEffect(() => {
    if (!isAnonymous) {
      setSelectedShippingAddress(undefined);
      setSelectedBillingData(undefined);
    }
  }, [isAnonymous, setSelectedBillingData, setSelectedShippingAddress]);

  // Redirect user depending on how complete is its registered process
  useEffect(() => {
    (async () => {
      if (!isAnonymous && user) {
        const { acceptEmails, acceptPushNotifications } = signUpUserFormContext;

        const body: FcmTokenBody = {
          uid: user.uid,
          country_id: selectedCountryId,
          settings: {
            emailNotifications: acceptEmails,
            pushNotifications: acceptPushNotifications
          },
          device_token: ""
        };
        if (isMobile && notificationRegister) {
          await verifyPermissions(body);
        }
        return;
      }
      if (asPath === "/signup-user" || asPath.includes("redirect")) return;
      if (
        message === "No user for uid" &&
        pathname !== "/terms" &&
        pathname !== "/privacy"
      ) {
        push(`/signup-user/?redirect=${asPath ?? "/"}`);
        return;
      }

      return () => {
        PushNotifications.removeAllListeners();
      };
    })();
  }, [
    asPath,
    isAnonymous,
    isMobile,
    message,
    notificationRegister,
    pathname,
    push,
    selectedCountryId,
    signUpUserFormContext,
    user,
    verifyPermissions
  ]);

  useEffect(() => {
    if (!currentUser) return;
    setProviderData(currentUser.providerData);
  }, [currentUser, setProviderData]);

  // Redirect user to transparent terms and conditions modal
  useEffect(() => {
    if (isAnonymous || !isSuccessUser || privacyAcceptDate) return;

    if (pathname === "/terms" || pathname === "/privacy") {
      setTermsAndPrivacyModal(false);
      return;
    }

    setTermsAndPrivacyModal(true);

    return () => {
      setTermsAndPrivacyModal(false);
    };
  }, [
    events,
    isAnonymous,
    isSuccessUser,
    pathname,
    setTermsAndPrivacyModal,
    privacyAcceptDate
  ]);
};

export default useListenUser;
