import React from "react";
import { Store } from "artisn/types";

import Styles from "./ChooseStore.styles";
import StoreList from "../StoreList/StoreList";
import InfoCard from "components/global/InfoCard/InfoCard";
import { ChooseStoreProps as Props } from "./ChooseStore.types";
import { useFetchNearbyStores } from "services/stores/stores.service.hooks";
import useGeo from "contexts/geo/geo.hooks";
import Button from "components/global/Button/Button";
import { getCurrentPosition } from "utils/geo.utils";

import LocationSVG from "../../../../public/assets/images/location.svg";
import MapSVG from "../../../../public/assets/images/map.svg";

const ChooseStore: React.FC<Props> = props => {
  const { mapStore, onClose } = props;
  const { setPreviousStep, onSelectedStore, setStep, setMapStore } = props;
  const { setSelectedCoordinates } = useGeo();
  const nearbyStoresResponse = useFetchNearbyStores();
  const { data: stores, isLoading, isError } = nearbyStoresResponse;
  const { refetch: refetchNearbyStores } = nearbyStoresResponse;

  const currentPositionHandler = async () => {
    await getCurrentPosition(async position => {
      const { latitude, longitude } = position.coords;
      setSelectedCoordinates({ lat: latitude, lng: longitude });
      refetchNearbyStores();
      onClose?.();
    });
  };

  const onSelectedStoreInformation = (store: Store) => {
    setMapStore(store);
    setPreviousStep(1);
    setStep(3);
  };

  return (
    <Styles className="ChooseStore">
      <div className="ChooseStore__options">
        <InfoCard
          Icon={LocationSVG}
          className="ChooseStore__current-position"
          value="Seleccionar el más cercano a mi"
          onContentClick={currentPositionHandler}
        />
        <Button
          className="ChooseStore__map-search"
          onClick={() => setStep(2)}
          type="LINK"
        >
          <MapSVG />
          <span>Buscar en el mapa</span>
        </Button>
      </div>
      <StoreList
        stores={stores}
        isLoading={isLoading}
        isError={isError}
        selectedStore={mapStore}
        onStoreClick={onSelectedStore}
        onStoreInfoClick={onSelectedStoreInformation}
      />
    </Styles>
  );
};

ChooseStore.defaultProps = {};

export default ChooseStore;
