import React from "react";
import { useProductCard } from "artisn-ui-react";

import Styles from "./ProductBaseInfo.styles";
import { ProductBaseInfoProps as Props } from "./ProductBaseInfo.types";
import ProductBasePriceInfo from "../ProductBasePriceInfo/ProductBasePriceInfo";
import useCountries from "contexts/countries/countries.hooks";

const ProductBaseInfo: React.FC<Props> = props => {
  const { product, className } = props;
  const { decimals } = useCountries().selectedCountry;

  const { totals } = useProductCard(product, { decimals });
  const { name, description } = product;

  return (
    <Styles className={`ProductBaseInfo ${className}`}>
      <h1 className="ProductBaseInfo__name">{name}</h1>
      <p className="ProductBaseInfo__description">{description}</p>
      <ProductBasePriceInfo
        className="ProductBaseInfo__prices"
        productTotals={totals}
      />
    </Styles>
  );
};

ProductBaseInfo.defaultProps = {
  className: ""
};

export default ProductBaseInfo;
