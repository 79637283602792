import { useEffect } from "react";
import { Storage } from "@capacitor/storage";

import CONSTANTS from "config/constants";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import { useFetchShippingAddresses } from "services/shippingAddress/shippingAddress.service.hooks";
import useAuth from "contexts/auth/auth.context.hooks";

const { SHIPPING_ADDRESS_TOKEN } = CONSTANTS.STORAGE;

const useListenAddresses = () => {
  const { selectedShippingAddress } = useShippingAddress();
  const { setSelectedShippingAddress } = useShippingAddress();
  const { data: addressList } = useFetchShippingAddresses();
  const { isAnonymous } = useAuth();

  /** Get shipping address from local storage and set it on context. */
  useEffect(() => {
    (async () => {
      const { value } = await Storage.get({
        key: SHIPPING_ADDRESS_TOKEN
      });
      const lastSelectedShippingAddress = value ? JSON.parse(value) : undefined;

      setSelectedShippingAddress(lastSelectedShippingAddress);
    })();
  }, [setSelectedShippingAddress]);

  /**
   * Every time the selected shipping address changes,
   * save it on local storage.
   */
  useEffect(() => {
    if (!selectedShippingAddress || isAnonymous) return;
    Storage.set({
      key: SHIPPING_ADDRESS_TOKEN,
      value: JSON.stringify(selectedShippingAddress)
    });
  }, [isAnonymous, selectedShippingAddress]);

  useEffect(() => {
    if (!addressList) return;
    const defaultAddress = addressList.find(address => address.default);
    if (!defaultAddress) return;
    setSelectedShippingAddress(prev => {
      if (prev) return prev;
      return defaultAddress;
    });
  }, [addressList, setSelectedShippingAddress]);
};

export default useListenAddresses;
