import styled from "styled-components";

import { TermsAndPrivacyModalStyledProps as Props } from "./TermsAndPrivacyModal.types";
import Modal from "components/global/Modal/Modal";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const TermsAndPrivacyModalStyled = styled(Modal)<Props>`
  width: min(${getMaxWidth()}, 50rem);
  height: min(${getMaxHeight()}, 52rem);
  border-radius: 1.6rem;
  overflow: hidden;
  padding: 4rem;

  @media (max-width: ${tablet}px) {
    width: min(${getMaxWidth()}, 96rem);
    height: 100%;
    transform: none;
    left: 0;
    top: 0;
    margin-top: env(safe-area-inset-top);
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .TermsAndPrivacyModal {
    &__title {
      font-size: 2rem;
      color: var(--palette-primary);
      font-weight: 600;
    }

    &__description {
      font-size: 1.6rem;
      color: var(--palette-gray-s0-l35);
      padding-top: 2rem;
      line-height: 2rem;
    }

    &__checkbox {
      padding-top: 2rem;
      font-size: 1.4rem;
      line-height: 2rem;
      color: var(--palette-gray-s0-l35);
    }

    &__checkboxTextOne {
    }

    &__checkboxTextTwo {
      color: var(--palette-gray-s0-l35);
      text-decoration: underline;
    }

    &__button {
      background-color: var(--palette-primary);
      width: 100%;
      margin-top: 2.6rem;
      padding: 1.4rem 0rem;
      border-radius: 10rem;
      font-size: 1.6rem;
      line-height: 2rem;

      :hover {
        transform: scale(1) !important;
      }
    }
  }
`;

export default TermsAndPrivacyModalStyled;
