import { useContext } from "react";

import { CataloguesContext } from "./catalogues.context";
import { CataloguesProviderValue } from "./catalogues.context.types";

const useCatalogues = () => {
  const context = useContext<CataloguesProviderValue>(CataloguesContext);
  if (typeof context === "undefined") {
    throw new Error("useCatalogues must be used within a CataloguesProvider");
  }
  return context;
};

export default useCatalogues;
