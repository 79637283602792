import styled from "styled-components";

import { AddressListStyledProps as Props } from "./AddressList.types";

const AddressListStyled = styled.div<Props>`
  flex: 1;

  .AddressList {
    &__item {
      &:not(:last-child) {
        margin-bottom: 3.2rem;
      }
    }

    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;

      &__title {
        max-width: 29.6rem;
        color: var(--palette-black-s0-l20);
        font-size: 2.4rem;
        line-height: 2.4rem;
        font-weight: 800;
        margin-top: 1.6rem;
        margin-bottom: 3.2rem;
      }

      &__button {
        padding: 1.6rem 2.4rem;
        border-radius: 12rem;
        font-size: 1.6rem;
        font-weight: 800;
      }

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export default AddressListStyled;
