// Settings service hooks
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Country, UserSettings } from "artisn/types";

import useAuth from "contexts/auth/auth.context.hooks";
import { fetchUserSettings, putUserSettings } from "./settings.service";
import { postUserSettings } from "./settings.service";
import { PutUserSettingsPayload } from "./settings.service.types";
import useCountries from "contexts/countries/countries.hooks";
import { defaultSettings } from "./settings.service.helpers";
import { notify } from "utils/common.utils";

export const useFetchUserSettings = () => {
  const { uid, isAnonymous } = useAuth();

  return useQuery([uid, "userSettings"], () => fetchUserSettings(), {
    enabled: !isAnonymous && !!uid,
    staleTime: 60 * 1000 * 60,
    onError: (e: Error) => {
      notify(e, "Get user settings request");
    }
  });
};

export const usePostUserSettings = (countryId?: Country["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { id } = useCountries().selectedCountry;
  const selectedCountryId = countryId ?? id;
  const body = {
    country_id: selectedCountryId,
    device_token: null,
    settings: defaultSettings
  };

  return useMutation<UserSettings | boolean, Error, string>(
    uid => postUserSettings({ ...body, uid }),
    {
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([uid, "userSettings"]);
      }
    }
  );
};

export const usePutUserSettings = (countryId?: Country["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  // const { id } = useCountries().selectedCountry;
  // const selectedCountryId = countryId ?? id;

  return useMutation<UserSettings, Error, PutUserSettingsPayload>(
    config => putUserSettings(config),
    {
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([uid, "userSettings"]);
      }
      // TODO: Update manually, and do not refetch query
      // onSuccess: response => {
      //   console.log({ response });
      //   queryClient.setQueryData([uid, "userSettings"], {});
      // }
    }
  );
};
