// StorePreview helper functions and data
import dayjs from "dayjs";
import { Schedule } from "artisn/types";

import { sortSchedule } from "utils/date.utils";

export const nextOpeningHourHelper = (
  schedules: Schedule[],
  catalogueId: number
) => {
  if (!schedules) return "";

  const now = dayjs();
  const today = dayjs().get("day");

  const sortedByDay = sortSchedule(schedules, catalogueId.toString());

  const scheduleStart = sortedByDay.slice(today);
  const scheduleEnd = sortedByDay.slice(0, today);
  const sortedSchedules = [...scheduleStart, ...scheduleEnd];

  const selectedSchedule = sortedSchedules.find(schedule => {
    return now.isBefore(dayjs().add(schedule.from, "seconds"));
  });

  if (!selectedSchedule) return "";

  const timeString = dayjs()
    .startOf("day")
    .add(selectedSchedule.from, "seconds")
    .format("HH:mm a");

  return `${timeString}`;
};
