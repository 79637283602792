import styled from "styled-components";

import { EmptyCartStyledProps as Props } from "./EmptyCart.types";

const EmptyCartStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 35.6rem;
  padding: 6.4rem 0;

  .EmptyCart {
    &__icon {
    }

    &__title {
      padding-top: 1.2rem;
      padding-bottom: 2.4rem;
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
    }

    &__description {
      padding-bottom: 4rem;
      font-size: 1.6rem;
      color: var(--palette-gray-s0-l40);
      text-align: center;
    }

    &__button {
      border-radius: 10rem;
      padding: 1.6rem 6.4rem;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
`;

export default EmptyCartStyled;
