import React from "react";

import { ShoppingCartProductPlaceholderStyled as Styles } from "./ShoppingCartProduct.styles";
import { ShoppingCartProductPlaceholderProps as Props } from "./ShoppingCartProduct.types";

const ShoppingCartProductPlaceholder: React.FC<Props> = props => {
  return (
    <Styles className="ShoppingCartProductPlaceholder">
      <div className="ShoppingCartProductPlaceholder__image">
        <div className="ShoppingCartProductPlaceholder__shine" />
      </div>
      <div className="ShoppingCartProductPlaceholder__details">
        <div className="ShoppingCartProductPlaceholder__name">
          <div className="ShoppingCartProductPlaceholder__shine" />
        </div>
        <div className="ShoppingCartProductPlaceholder__description">
          <div className="ShoppingCartProductPlaceholder__shine" />
        </div>
        <div className="ShoppingCartProductPlaceholder__price">
          <div className="ShoppingCartProductPlaceholder__shine" />
        </div>
      </div>
    </Styles>
  );
};

ShoppingCartProductPlaceholder.defaultProps = {};

export default ShoppingCartProductPlaceholder;
