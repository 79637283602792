import React, { useState } from "react";
import { formatByCurrency, useProductCard } from "artisn-ui-react";

import Styles from "./ShoppingCartProduct.styles";
import { ShoppingCartProductProps as Props } from "./ShoppingCartProduct.types";
import Counter from "components/global/Counter/Counter";
import useCountries from "contexts/countries/countries.hooks";
import { getMissingProduct } from "./ShoppingCartProduct.helpers";

import CloseSVG from "../../../../public/assets/images/close.svg";
import InfoCircleSVG from "../../../../public/assets/images/info.svg";

const ShoppingCartProduct: React.FC<Props> = props => {
  const {
    product,
    initialQuantity = 1,
    onChangeQuantity,
    onDelete,
    disabled,
    onClick,
    modifiers,
    onAdd,
    onSubtract,
    showCounter = true,
    ...rest
  } = props;
  const { outOfStock, name: productName, description, alerts } = product ?? {};
  const { selectedCountry } = useCountries();
  const { decimals } = selectedCountry;
  const { totals: productTotals } = useProductCard(product, { decimals });
  const { unitNetPrice } = productTotals ?? {};
  const [quantity, setQuantity] = useState(initialQuantity);

  const formatByCurrencyOptions = {
    ...selectedCountry,
    /* This is because Intl.NumberFormat only shows you the "$" symbol when 
    you pass it the value of "en-US" in the "locale" property. */
    locale: "en-US"
  };

  const normalPriceFormatted = formatByCurrency(
    Number(unitNetPrice || 0),
    formatByCurrencyOptions
  );

  const totalPriceFormatted = formatByCurrency(
    Number(unitNetPrice ? unitNetPrice * quantity : 0),
    formatByCurrencyOptions
  );

  const quantityChangeHandler = (value: number) => {
    setQuantity(value);
    onChangeQuantity(value);
  };

  return (
    <Styles className="ShoppingCartProduct" {...rest}>
      <div className="ShoppingCartProduct__product-details">
        <p className="ShoppingCartProduct__name" onClick={onClick}>
          {productName}
        </p>
        <p className="ShoppingCartProduct__description">{description}</p>
        <p className="ShoppingCartProduct__total-price">
          {totalPriceFormatted}
        </p>
        <p className="ShoppingCartProduct__unit-price">
          {quantity} x {normalPriceFormatted}
        </p>
        {onDelete ? (
          <button
            className="ShoppingCartProduct__remove-button"
            onClick={onDelete}
          >
            <CloseSVG viewBox="0 0 16 16" />
          </button>
        ) : null}
      </div>
      {showCounter ? (
        <Counter
          className="ShoppingCartProduct__counter"
          initialValue={initialQuantity}
          onChange={quantityChangeHandler}
          disabled={disabled || outOfStock}
          min={1}
        />
      ) : null}
      {outOfStock || getMissingProduct(alerts) ? (
        <p className="ShoppingCartProduct__error">
          <InfoCircleSVG viewBox="0 0 19 20" />
          Este producto no está disponible
        </p>
      ) : null}
    </Styles>
  );
};

ShoppingCartProduct.defaultProps = {};

export default ShoppingCartProduct;
