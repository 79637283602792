import styled from "styled-components";

import { MergeCartModalStyledProps as Props } from "./MergeCartModal.types";
import InfoActionModal from "../InfoActionModal/InfoActionModal";

const MergeCartModalStyled = styled(InfoActionModal)<Props>`
  &&&.MergeCartModal {
    .InfoActionModal {
      &__title {
        padding-top: 4.2rem;
        font-size: 2.4rem;
        line-height: 2.4rem;
        font-weight: 800;
        color: var(--palette-black-s0-l20);
      }

      &__description {
        padding-top: 2rem;
      }

      &__buttons {
        padding-top: 3.2rem;
      }
    }
  }
`;

export default MergeCartModalStyled;
