import React, { createContext, useMemo, useReducer } from "react";
import { useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import { BaseBillingData, OrderDetails } from "artisn/types";

import { PickUpOption } from "types/common.types";
import { PICK_UP_OPTIONS } from "config/constants";
import { userInitialState } from "./checkout.context.helpers";
import { userReducer } from "./checkout.context.helpers";
import { CheckoutProviderProps as Props } from "./checkout.context.types";
import { PlaceOrderStep } from "./checkout.context.types";
import { CheckoutProviderValue } from "./checkout.context.types";
import { StatusText } from "types/payment.types";

// @ts-ignore
export const CheckoutContext = createContext<CheckoutProviderValue>();

const CheckoutProvider: React.FC<Props> = props => {
  const [userError, setUserError] = useState<string>();
  const [transferAnonymousId, setTransferAnonymousId] = useState<string>();
  const [step, setStep] = useState<PlaceOrderStep>();
  const [status, setStatus] = useState<StatusText>();
  const [password, setPassword] = useState<string>();
  const [anonymousUserValues, setAnonymousUserValue] = useReducer(
    userReducer,
    userInitialState
  );
  const [selectedPickUpOption, setSelectedPickUpOption] =
    useState<PickUpOption>(PICK_UP_OPTIONS[0]);
  const [anonymousBillingData, setAnonymousBillingData] =
    useState<BaseBillingData>();
  const [placedOrder, setPlacedOrder] = useState<OrderDetails | undefined>();

  const setSelectedPickUpOptionHandler = (value: string) => {
    const selectedOption = PICK_UP_OPTIONS?.find(item => item.id === +value);

    if (!selectedOption) {
      throw new Error(
        `"${selectedOption}" does not correspond to a valid pick up option ID`
      );
    }

    setSelectedPickUpOption(selectedOption);
  };

  const value: CheckoutProviderValue = useMemo(() => {
    return {
      userError,
      setUserError,
      transferAnonymousId,
      setTransferAnonymousId,
      step,
      setStep,
      status,
      setStatus,
      password,
      setPassword,
      anonymousUserValues,
      setAnonymousUserValue,
      selectedPickUpOption,
      setSelectedPickUpOption: setSelectedPickUpOptionHandler,
      anonymousBillingData,
      setAnonymousBillingData,
      placedOrder,
      setPlacedOrder
    };
  }, [
    userError,
    transferAnonymousId,
    step,
    status,
    password,
    anonymousUserValues,
    selectedPickUpOption,
    anonymousBillingData,
    placedOrder
  ]);

  return (
    <CheckoutContext.Provider value={value}>
      <ContextDevTool
        context={CheckoutContext}
        id="checkout"
        displayName="Checkout"
      />
      {props.children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
