import React from "react";
import { useRouter } from "next/router";

import Button from "../Button/Button";
import Styles from "./SignOptions.styles";
import { SignOptionsProps as Props } from "./SignOptions.types";

const SignOptions: React.FC<Props> = props => {
  const { route = "signup", buttonTitle = "Crea tu cuenta" } = props;
  const { title = "¿Ya tienes una cuenta?", className } = props;
  const { onChange, isModal = false } = props;
  const { push } = useRouter();

  const clickHandler = () => {
    if (isModal) {
      onChange?.(route);
      return;
    }
    push(route);
  };

  return (
    <Styles className={`SignOptions ${className}`}>
      <p className="SignOptions__message">{title}</p>
      <Button
        type="LINK"
        className="SignOptions__button"
        onClick={clickHandler}
      >
        {buttonTitle}
      </Button>
    </Styles>
  );
};

SignOptions.defaultProps = {
  className: ""
};

export default SignOptions;
