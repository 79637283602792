import React from "react";

import Styles from "./ListItem.styles";
import { ListItemProps as Props } from "./ListItem.types";
import DropdownOptions from "../DropdownOptions/DropdownOptions";

import LoadingSVG from "../../../../public/assets/images/loading.svg";

const ListItem: React.FC<Props> = props => {
  const { className, isLoading, title, description } = props;
  const { isDefault, icon, isActive = false, actions } = props;
  const { isFixed, onEdit, onDelete, onDefault } = props;

  const renderIcon = () => {
    if (isLoading) {
      return (
        <div className="ListItem__loading">
          <LoadingSVG viewBox="0 0 40 40" />
        </div>
      );
    }
    return icon;
  };

  return (
    <Styles
      className={`ListItem ${className}`}
      isActive={isActive}
      hasIcon={!!icon}
      isLoading={isLoading}
    >
      {renderIcon()}
      <div className="ListItem__content">
        <p className="ListItem__title">
          <p className="ListItem__value">{title}</p>
          <span className="ListItem__default">
            {isDefault ? " (Predeterminado)" : ""}
          </span>
        </p>
        <div className="ListItem__description">{description}</div>
      </div>
      {actions?.length ? (
        <DropdownOptions
          className="ListItem__dropdown-options"
          onEdit={actions?.includes("edit") ? onEdit : undefined}
          onDelete={actions?.includes("delete") ? onDelete : undefined}
          onDefault={
            actions?.includes("default") && !isDefault ? onDefault : undefined
          }
          isFixed={isFixed}
        />
      ) : null}
    </Styles>
  );
};

ListItem.defaultProps = {
  className: ""
};

export default ListItem;
