import styled from "styled-components";

import { SearchBarProductsStyledProps as Props } from "./SearchBarProducts.types";
import CONSTANTS from "config/constants";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;

const SearchBarProductsStyled = styled.div<Props>`
  display: flex;
  background-color: var(--palette-white);
  position: relative;

  @media (max-width: ${tablet}px) {
    display: grid;
    grid-template-areas:
      "search cancel"
      "content content";
    grid-template-columns: 2fr 1fr;
    padding-top: env(safe-area-inset-top);
  }

  .SearchBarProducts {
    &__search-bar {
      width: 100%;
      max-width: 28rem;
      grid-area: search;

      & input {
        background-color: transparent;
      }

      .SearchBar {
        &__wrapper {
          height: 4.8rem;
          border-radius: 10rem;
          background-color: var(--palette-lightgrey-s5-l90);

          @media (max-width: ${tablet}px) {
            height: 4rem;
            width: 4.8rem;
          }
        }

        &__btn-search {
          background-color: transparent;
        }

        &__btn-container {
          & > * {
            background-color: transparent;
          }
        }
      }
    }

    &__btn-cancel {
      position: absolute;
      right: 0;
      top: 50%;
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--palette-primary-dark);
      background-color: transparent;
      transform: translateY(-50%);
      z-index: 3;
    }

    &__cancel {
      padding: 0.8rem 1.6rem;
      grid-area: cancel;

      .Button__wrapper {
        font-weight: 600;
        font-size: 1.6rem;
      }
    }
  }

  &&& .SearchBar__input {
    padding: 1rem 1.2rem;
    border: none;
    line-height: 2.4rem;
    width: 100%;
    font-size: 1.5rem;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-size: 1.6rem;
    }
  }
`;

const SearchBarContentStyled = styled.div<Props>`
  position: fixed;
  width: 37.6rem;
  max-height: calc(100vh - 15.6rem);
  overflow: auto;
  margin-top: 0.8rem;
  border-radius: 1rem;
  box-shadow: 0px 2px 14px var(--palette-black-s0-l0-a15);
  filter: drop-shadow(0rem 0.4rem 0.4rem var(--palette-gray-s0-l20-a16))
    drop-shadow(0rem 0.4rem 5.6rem var(--palette-gray-s0-l20-a16));
  z-index: 2;
  background-color: var(--palette-gray-s0-l98);
  grid-area: content;

  @media (max-width: ${tablet}px) {
    width: 100%;
    box-shadow: none;
    background-color: unset;
    filter: none;
    margin-top: unset;
    position: unset;
    height: 100%;
  }

  .SearchBarContent {
    &__container {
      background-color: var(--palette-white);
    }

    &__clean-button {
      padding: 0.2rem 1.2rem;
      border-radius: 4.8rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem 1.2rem;
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l40);
      text-align: center;
      border-radius: 4.8rem;
      background-color: var(--palette-gray-s0-l95);
      cursor: pointer;
    }

    &__recent-searches-container {
      padding: 2.4rem 1.6rem;
      background-color: var(--palette-white);
      max-width: 100%;
      margin-bottom: 0.8rem;

      @media (max-width: ${mobile}px) {
        padding: 1.2rem;
      }
    }

    &__section-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.2rem;
    }

    &__recent-searches-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.2rem 0;
      cursor: pointer;
    }

    &__recent-searches-query {
      max-width: 90%;
      font-size: 1.6rem;
      color: var(--palette-black-1);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__search-query-container {
      margin-bottom: 0.8rem;
      padding: 1.2rem 2rem;
      background: var(--palette-white);
      cursor: pointer;
      visibility: ${props => (props.queryString ? "unset" : "hidden")};
    }

    &__search-query-text {
      font-size: 1.6rem;
      font-weight: 800;
      color: var(--palette-black-s0-l20);
    }

    &__content-container {
      padding: 3.2rem 1.6rem;
      background-color: var(--palette-white);
      visibility: ${props => (props.queryString ? "unset" : "hidden")};
    }

    &__section-title {
      font-size: 1.8rem;
      font-weight: 800;
      color: var(--palette-black-s0-l20);
    }

    &__dropdown-items {
      display: grid;
      row-gap: 5.6rem;
    }

    &__dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .Image {
        min-width: 10rem;
        border-radius: 1.6rem;

        > img {
          object-fit: cover;
        }
      }
    }

    &__item-info {
      margin-right: 1.6rem;
    }

    &__item-name {
      display: -webkit-box;
      max-width: 100%;
      font-size: 1.8rem;
      font-weight: 800;
      color: var(--palette-black-s0-l20);
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__item-description {
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--palette-gray-s0-l50);
    }

    &__item-prices {
      font-size: 1.6rem;
      font-weight: 800;
      color: var(--palette-primary);
    }

    &__item-points-price {
      margin-left: 0.8rem;
      font-size: 1.6rem;
      color: var(--palette-gray-s0-l20);
    }
  }
`;

export { SearchBarContentStyled };
export default SearchBarProductsStyled;
