export const accountBuilders = {
  buildAccount: () => {},
  genAccounts: () => {}
};

export const bannerBuilders = {
  buildBanner: () => {},
  buildBannerCoupon: () => {},
  buildBannerBenefits: () => {},
  genBannerBenefits: () => {},
  genBanners: () => {},
  buildBannerImage: () => {},
  genBannerImages: () => {}
};

export const billingDataBuilders = {
  buildBillingData: () => {},
  genBillingData: () => {}
};

export const catalogueBuilders = {
  buildCatalogue: () => {},
  genCatalogues: () => {}
};

export const categoryBuilders = {
  buildCategory: () => {},
  genCategories: () => {},
  buildCategoryWithProducts: () => {},
  genCategoryWithProducts: () => {}
};

export const utils = {
  commonDocumentTypes: [],
  ARDocumentTypes: [],
  BODocumentTypes: [],
  BRDocumentTypes: [],
  CLDocumentTypes: [],
  CODocumentTypes: [],
  ECDocumentTypes: [],
  PYDocumentTypes: [],
  PEDocumentTypes: [],
  UYDocumentTypes: [],
  VEDocumentTypes: [],
  USDocumentTypes: [],
  getPassword: () => {},
  getUsername: () => {},
  genId: () => {},
  genNumericId: () => {},
  genWord: () => {},
  genAddress: () => {},
  genName: () => {},
  genEmail: () => {},
  genCompanyName: () => {},
  genParagraph: () => {},
  genTitle: () => {},
  genNumber: () => {},
  getBoolean: () => {},
  genCountryName: () => {},
  genRandomImage: () => {},
  genWords: () => {},
  genURL: () => {},
  genDate: () => {},
  genBase64: () => {},
  genBiasBoolean: () => {},
  genDocument: () => {},
  genDocumentType: () => {},
  genMobilPhone: () => {},
  genCountry: () => {},
  fillNumber: () => {},
  chooseRandom: () => {},
  chooseRandomEnum: () => {},
  genSymbol: () => {},
  genIP: () => {}
};

export const countryBuilders = {
  countriesCodes: [],
  buildCountryCurrency: () => {},
  genCountryCurrencies: () => {},
  buildCountry: () => {},
  genCountries: () => {}
};

export const couponBuilders = {
  buildBenefit: () => {},
  genBenefits: () => {},
  buildAward: () => {},
  genAwards: () => {},
  buildWallet: () => {},
  genWallets: () => {},
  buildStoreCoupon: () => {},
  genStoreCoupons: () => {},
  buildProductCoupon: () => {},
  genProductCoupons: () => {},
  buildBenefitData: () => {},
  genBenefitData: () => {},
  buildCoupon: () => {},
  genCoupons: () => {},
  buildCouponCategory: () => {},
  genCouponCategories: () => {},
  buildCode: () => {},
  genCodes: () => {},
  buildToken: () => {},
  genTokens: () => {},
  buildStoreCouponDetail: () => {},
  genStoreCouponDetails: () => {}
};

export const fulfillmentBuilders = {
  stepCategory: [],
  stepCode: [],
  buildIssue: () => {},
  genIssues: () => {},
  buildIssueAdditionalInfo: () => {},
  genIssueAdditionalInfo: () => {},
  buildIssueHistory: () => {},
  genIssueHistory: () => {},
  buildWorkflow: () => {},
  genWorkflows: () => {},
  buildStepNotification: () => {},
  genStepNotifications: () => {},
  buildStepWorkflow: () => {},
  genStepWorkflows: () => {},
  buildWebhookWorkflow: () => {},
  genWebhookWorkflows: () => {},
  buildStepWorkflowAdditionalInfo: () => {},
  genStepWorkflowAdditionalInfos: () => {},
  buildStepWorkflowGroup: () => {},
  genStepWorkflowGroups: () => {},
  buildTransitionWorkflow: () => {},
  genTransitionWorkflows: () => {}
};

export const imageBuilders = {
  buildURLImage: () => {},
  genURLImages: () => {},
  buildCDNImage: () => {},
  genCDNImages: () => {}
};

export const notificationBuilders = {
  buildNotification: () => {},
  genNotifications: () => {},
  buildNotificationUser: () => {},
  genNotificationUsers: () => {},
  buildNotificationStore: () => {},
  genNotificationStores: () => {},
  buildNotificationStep: () => {},
  genNotificationSteps: () => {},
  buildNotificationAdditionalInfo: () => {},
  genNotificationAdditionalInfos: () => {},
  buildNotificationExtraData: () => {},
  genNotificationExtraDatas: () => {},
  buildNotificationListItem: () => {},
  genNotificationListItems: () => {}
};

export const orderBuilders = {
  orderStatusOptions: [],
  paymentStatus: [],
  allocationOptions: [],
  genSeqId: () => {},
  genReference: () => {},
  buildBaseOrder: () => {},
  genBaseOrders: () => {},
  buildOrderDetails: () => {},
  genOrderDetails: () => {},
  buildOrderStep: () => {},
  buildOrderStepAdditionalInfo: () => {},
  genOrderStepAdditionalInfos: () => {},
  buildOrderStepAdditionalInfoGroup: () => {},
  genOrderSteps: () => {},
  buildOrderDetailShippingCost: () => {},
  genOrderDetailShippingCosts: () => {},
  buildOrderRetry: () => {},
  buildStatus: () => {},
  genStatus: () => {},
  genOrderRetries: () => {},
  buildOrderPaymentMethod: () => {},
  genOrderPaymentMethods: () => {},
  buildOrderShoppingCart: () => {},
  buildOrderStoreAdditionalInfo: () => {},
  genOrderStoreAdditionalInfos: () => {},
  buildOrderStoreDelivery: () => {},
  genOrderStoreDeliveries: () => {},
  buildOrderStore: () => {},
  genOrderStores: () => {},
  buildOrderProductAttributes: () => {},
  genOrderProductAttributes: () => {},
  buildOrderProductAdditionalInfo: () => {},
  genOrderProductAdditionalInfos: () => {},
  buildOrderProductImage: () => {},
  genOrderProductImages: () => {},
  buildOrderBillProductAdditionalInfo: () => {},
  buildTaxCalculation: () => {},
  genTaxCalculations: () => {},
  buildTaxCalculations: () => {},
  buildOrderProductPriceCategory: () => {},
  genOrderProductPriceCategories: () => {},
  buildOrderProduct: () => {},
  genOrderProducts: () => {},
  buildOrderBillProduct: () => {},
  buildOrderProductBillTotal: () => {},
  buildOrderBillTotal: () => {},
  genOrderShoppingCarts: () => {},
  buildMetadataShipping: () => {},
  genMetadataShippings: () => {},
  buildBaseField: () => {},
  genBaseFields: () => {},
  buildZipCodeField: () => {},
  genZipCodeFields: () => {},
  buildIntegrationMessage: () => {},
  genIntegrationMessages: () => {},
  buildInjectionDetail: () => {},
  genInjectionDetails: () => {},
  buildOrderUser: () => {},
  genOrderUsers: () => {},
  buildOperatorInformation: () => {},
  genOperatorInformation: () => {},
  buildSuborder: () => {},
  genSuborders: () => {},
  buildPaymentMethodByOrder: () => {},
  genPaymentMethodByOrders: () => {},
  buildOrderRetryPaymentMethod: () => {},
  genOrderRetryPaymentMethods: () => {}
};

export const paymentBuilders = {
  buildBasePaymentMethod: () => {},
  genBasePaymentMethods: () => {},
  buildPaymentMethod: () => {},
  genPaymentMethods: () => {},
  buildPaymentProvider: () => {},
  genPaymentProviders: () => {},
  buildCreditCardMapping: () => {},
  genCreditCardMappings: () => {},
  buildCardInscription: () => {},
  genCardInscriptions: () => {}
};

export const productBuilders = {
  buildPriceCategoryTax: () => {},
  genPriceCategoryTaxes: () => {},
  buildPriceCategory: () => {},
  buildBaseProduct: () => {},
  genBaseProducts: () => {},
  MODIFIER_TYPES: () => {},
  buildProductAnswer: () => {},
  genProductAnswers: () => {},
  buildProductQuestion: () => {},
  genProductQuestions: () => {},
  buildProductDetails: () => {},
  genProductDetails: () => {},
  buildCartProductAnswer: () => {},
  genCartProductAnswers: () => {},
  buildCartProductQuestion: () => {},
  genCartProductQuestions: () => {},
  buildCartProduct: () => {},
  genCartProducts: () => {}
};

export const settingsBuilders = {
  buildSettings: () => {},
  genSettings: () => {},
  buildUserSettings: () => {},
  genUserSettings: () => {}
};

export const shippingAddressBuilders = {
  genStreetName: () => {},
  genStreetNumber: () => {},
  genLatitude: () => {},
  genLongitude: () => {},
  buildField: () => {},
  genFields: () => {},
  buildRule: () => {},
  buildMessage: () => {},
  genRules: () => {},
  genMessages: () => {},
  buildLivingPlace: () => {},
  genLivingPlaces: () => {},
  buildShippingAddress: () => {},
  genShippingAddress: () => {}
};

export const shoppingCartBuilders = {
  buildBillTotalNormalCategory: () => {},
  buildBillTotal: () => {},
  buildCartStore: () => {},
  genCartStores: () => {},
  buildShoppingCart: () => {},
  buildShippingCost: () => {},
  buildShippingCostTax: () => {},
  genShippingCostTaxes: () => {},
  buildDiscount: () => {},
  genDiscounts: () => {}
};

export const storeBuilders = {
  buildStore: () => {},
  buildDelivery: () => {},
  buildSchedule: () => {},
  buildService: () => {},
  genServices: () => {},
  genSchedules: () => {},
  genStores: () => {},
  buildGenericStoreLocation: () => {},
  genGenericStoreLocation: () => {},
  buildStoreLocation: () => {},
  genStoreLocation: () => {}
};

export const userBuilders = {
  buildUser: () => {},
  genUsers: () => {},
  buildPhone: () => {},
  genPhones: () => {}
};

export const vendorBuilders = {
  buildVendor: () => {},
  genVendors: () => {}
};
