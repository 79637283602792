import React, { useEffect, useRef } from "react";

import Styles from "./Notification.styles";
import { NotificationProps as Props } from "./Notification.types";

import CloseSVG from "../../../../../public/assets/images/close.svg";

const Notification: React.FC<Props> = props => {
  const { className, children, onClose, showCloseIcon = true } = props;
  const { onClick } = props;

  const notificationRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (notificationRef.current && event.target instanceof Node) {
        if (!notificationRef.current.contains(event.target)) {
          onClose?.();
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClose]);

  return (
    <Styles
      className={`Notification ${className}`}
      onClick={onClick}
      ref={notificationRef}
    >
      {showCloseIcon ? (
        <CloseSVG className="Notification__close" onClick={onClose} />
      ) : null}
      {children}
    </Styles>
  );
};

Notification.defaultProps = {
  className: ""
};

export default Notification;
