import React, { createContext, useCallback, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { CountriesProviderProps as Props } from "./countries.context.types";
import { CountriesProviderValue } from "./countries.context.types";
import CONSTANTS from "config/constants";
import { Country } from "types/country.types";

const { DEFAULT_COUNTRY, COUNTRIES } = CONSTANTS.ARTISN;

// @ts-ignore
export const CountriesContext = createContext<CountriesProviderValue>();

const CountriesProvider: React.FC<Props> = props => {
  const [selectedCountry, setSelectedCountry] =
    useState<Country>(DEFAULT_COUNTRY);
  const [selectedCountryId, setSelectedCountryId] = useState(
    selectedCountry.id
  );

  const resetCountriesContext = useCallback(() => {
    setSelectedCountry(DEFAULT_COUNTRY);
    setSelectedCountryId(DEFAULT_COUNTRY.id);
  }, []);

  const setSelectedCountryHandler = (country: Country) => {
    setSelectedCountry(country);
    setSelectedCountryId(country.id);
  };

  const setCountryIdHandler = (countryId: Country["id"]) => {
    const country = COUNTRIES.find(country => country.id === countryId);

    if (!country) {
      throw new Error(
        `"${countryId}" does not correspond to a valid country ID`
      );
    }
    setSelectedCountry(country);
    setSelectedCountryId(countryId);
  };

  const value: CountriesProviderValue = useMemo(() => {
    return {
      selectedCountry,
      setSelectedCountry: setSelectedCountryHandler,
      selectedCountryId,
      setSelectedCountryId: setCountryIdHandler,
      countryISOCodes: COUNTRIES.map(country => country.isoCode),
      resetCountriesContext
    };
  }, [selectedCountryId, resetCountriesContext, selectedCountry]);

  return (
    <CountriesContext.Provider value={value}>
      <ContextDevTool
        context={CountriesContext}
        id="countries"
        displayName="Countries"
      />
      {props.children}
    </CountriesContext.Provider>
  );
};

export default CountriesProvider;
