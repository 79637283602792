import React from "react";
import { Counter as CounterUI } from "artisn-ui-react";

import Styles from "./Counter.styles";
import { CounterProps as Props } from "./Counter.types";

const Counter: React.FC<Props> = props => {
  const { className, disabled = false, ...rest } = props;

  return (
    <Styles className={`CounterUI ${className}`} disabled={disabled}>
      <CounterUI {...rest} disabled={disabled} />
    </Styles>
  );
};

Counter.defaultProps = {
  disabled: false
};

export default Counter;
