import styled from "styled-components";

import { ProductErrorMessageStyledProps as Props } from "./ProductErrorMessage.types";

const ProductErrorMessageStyled = styled.div<Props>`
  .ProductErrorMessage {
    &__error {
      display: grid;
      grid-template-columns: 1.4rem auto;
      padding: 0.8rem 1.2rem;
      border: 0.1rem solid var(--palette-red-s60-l60);
      border-radius: 0.4rem;
      align-items: center;
      column-gap: 0.8rem;
      margin-top: 2.4rem;
      margin-left: 1.6rem;
      margin-bottom: 1.2rem;
      max-width: 28rem;

      &__title {
        line-height: 1.8rem;
        font-weight: 600;
      }

      &__description {
        grid-column: 2/3;
        padding-top: 0.2rem;
        line-height: 1.6rem;
      }

      &__title,
      &__description,
      &__link {
        color: var(--palette-red-s60-l60);
        font-size: 1.2rem;
      }

      &__link {
        margin: 0;
        padding: 0;
        display: inline;
        text-decoration: underline;

        &:hover:not([disabled]) {
          color: var(--palette-red-s60-l60);
        }
      }
    }
  }
`;

export default ProductErrorMessageStyled;
