// User service hooks
import { useMutation, useQuery, useQueryClient } from "react-query";
import { User, BaseUser } from "@artisan-commerce/types";
import { AxiosError } from "axios";

import useAuth from "contexts/auth/auth.context.hooks";
import { postUser, fetchUser, putUser } from "./user.service";
import { deleteUserAccount } from "./user.service";
import { ExtendedUser } from "./user.service.types";
import { PutUserPasswordPayload } from "./user.service.types";
import { putUserPassword } from "./user.service";
import { ApiError } from "types/api.types";

/** Hook to get user's information.
 *
 * @returns {UseQueryResult<User>} Returns a use query result with the user
 */
export const useFetchUser = () => {
  const { uid, isAnonymous } = useAuth();
  return useQuery<ExtendedUser, Error>([uid, "user"], () => fetchUser(), {
    enabled: !isAnonymous && !!uid,
    retry: 1,
    staleTime: 60 * 1000 * 60
  });
};

/** Hook to add user information.
 *
 * @returns Returns a use mutation result to add user information
 */
export const usePostUser = () => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();

  return useMutation<User, AxiosError<ApiError>, BaseUser>(postUser, {
    // When mutate is called:
    onMutate: async newUser => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([uid, "user"]);

      // Snapshot the previous value
      const previousUser = queryClient.getQueryData<User>([uid, "user"]);

      // Optimistically update to the new value
      if (previousUser) {
        queryClient.setQueryData<User>([uid, "user"], {
          ...previousUser,
          ...newUser
        });
      }

      // Return a context object with the snapshotted value
      return previousUser;
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, newUser, previousUser) => {
      if (previousUser) {
        queryClient.setQueryData([uid, "user"], previousUser);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([uid, "user"]);
    }
  });
};

/** Hook to update user information.
 *
 * @returns Returns a use mutation result to update user information
 */
export const usePutUser = () => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();

  return useMutation<User, AxiosError<ApiError>, BaseUser>(putUser, {
    // When mutate is called:
    onMutate: async newUser => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([uid, "user"]);

      // Snapshot the previous value
      const previousUser = queryClient.getQueryData<User>([uid, "user"]);

      // Optimistically update to the new value
      if (previousUser) {
        queryClient.setQueryData<User>([uid, "user"], {
          ...previousUser,
          ...newUser
        });
      }

      // Return a context object with the snapshotted value
      return previousUser;
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, newUser, previousUser) => {
      if (previousUser) {
        queryClient.setQueryData([uid, "user"], previousUser);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([uid, "user"]);
    }
  });
};

/** Hook to update user password.
 *
 * @returns Returns a use mutation result to add user information
 */
export const usePutUserPassword = () => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();

  return useMutation<void, Error, PutUserPasswordPayload>(putUserPassword, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([uid, "user"]);
    }
  });
};

/** Hook to delete user account.
 *
 * @returns Returns a use mutation result to delete user account
 */
export const useDeleteUserAccount = () => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();

  return useMutation<void, Error>(deleteUserAccount, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([uid, "user"]);
    }
  });
};
