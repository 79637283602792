// Schedule helper functions and data
import dayjs from "dayjs";

import CONSTANTS from "config/constants";
import { getHours, getMinutes } from "utils/date.utils";

const { HOUR_MINUTE } = CONSTANTS.DATES_FORMAT;

export const formattingHours = (timeInSeconds: number, timeFormat?: string) => {
  return dayjs()
    .hour(getHours(timeInSeconds))
    .minute(getMinutes(timeInSeconds))
    .format(timeFormat ?? HOUR_MINUTE);
};

export const getDaySchedule = (day: string) => {
  const localDay = new Date().getDay();
  const days = [
    "DOMINGO",
    "LUNES",
    "MARTES",
    "MIÉRCOLES",
    "JUEVES",
    "VIERNES",
    "SÁBADO"
  ];
  return day.toUpperCase() === days[localDay];
};
